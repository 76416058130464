import { useEffect } from "react";
import { Link } from "react-router-dom";

const GuidelineESG = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="max-w-[1280px] mx-auto w-full min-h-screen ">
      <div className="  mt-20 md:mt-40 p-5 md:p-0">
        <h1 className="font-bold text-textColor font-sherif text-[40px]">
          GUIDELINES
        </h1>
      </div>

      {/* GUIDELINES OF THE APPLICATION FOR KMA – ESG AWARDS 2024 */}
      <div className="md:mt-16 p-5 md:p-0">
        <h1 className="mb-3 font-semibold text-lg">
          GUIDELINES OF THE APPLICATION FOR KMA – ESG AWARDS 2024
        </h1>
        <h1 className="mb-3 font-semibold">
          For the Special Attention of Applicants:
        </h1>

        <span className="border-b border-black pb-1 font-semibold">
          Instructions:{" "}
        </span>

        <ol style={{ listStyleType: "number" }} className="px-4 my-5">
          <li>
            If any particular question is not applicable or relevant to your
            organization, please answer it as “Not Applicable”(NA). If answer to
            any particular question cannot be furnished due to confidentiality
            or other reasons, please answer it as “Confidential/ No Comments”.
          </li>
          <li>
            In the context of ESG reporting, Sustainability has many facets such
            as inclusiveness in employment, grievance redressal mechanism for
            stakeholders, identification of risks and opportunities in the
            conduct of business, disclosures on management and processes,
            Performance and follow-up actions, Compliance, Independent
            assessment of the Company’s policies
          </li>
          <li>
            ESG Reporting involves adoption of the principles laid down under
            the National Guidelines on Responsible Business Conduct (NGRBC),
            <ol className="mt-3 ms-3" style={{ listStyleType: "lower-roman" }}>
              <li>
                PRINCIPLE 1: Businesses should conduct and govern themselves
                with integrity, and in a manner that is Ethical, Transparent and
                Accountable.
              </li>
              <li>
                PRINCIPLE 2: Businesses should provide goods and services in a
                manner that is sustainable and safe
              </li>
              <li>
                PRINCIPLE 3: Businesses should respect and promote the
                well-being of all employees, including those in their value
                chains
              </li>
              <li>
                PRINCIPLE 4: Businesses should respect the interests of and be
                responsive to all its stakeholders
              </li>
              <li>
                PRINCIPLE 5: Businesses should respect and promote human rights
              </li>
              <li>
                PRINCIPLE 6: Businesses should respect and make efforts to
                protect and restore the environment
              </li>
              <li>
                PRINCIPLE 7: Businesses, when engaging in influencing public and
                regulatory policy, should do so in a manner that is responsible
                and transparent
              </li>
              <li>
                PRINCIPLE 8: Businesses should promote inclusive growth and
                equitable development
              </li>
              <li>
                PRINCIPLE 9: Businesses should engage with and provide value to
                their consumers in a responsible manner
              </li>
            </ol>
          </li>
        </ol>

        <span className="border-b border-black pb-1 font-semibold">
          I. Profile of the Organization:
        </span>

        <ol style={{ listStyleType: "number" }} className="px-4 my-5">
          <li>Full Name of the Applicant Organization:</li>
          <li>Corporate Office Location:</li>
          <li>
            Brief Description of the Applicant Organization (in about 50 words):
          </li>
          <li>Ownership:</li>
          <li>Year of Establishment of the Applicant Organization: </li>
          <li>Annual Turnover of FY 2022-23 </li>
          <li>Total Number of employees of the Applicant Organization: </li>
          <li>
            {" "}
            Has the Applicant Organization adopted any International Integrated
            Reporting System (IIRS)? Attach a copy of your latest report. In
            case IIRS is not being followed, then specify the reporting system
            you are following (Attach a copy).
          </li>
          <li>
            Indicate if the applicant is certified to ISO 9001 / ISO 14001 / ISO
            45001 / OHSAS 18001 / SA 8000 / others. Attach a copy of the
            certificate(s).{" "}
          </li>
        </ol>

        <span className="border-b border-black pb-1 font-semibold">
          II. ESG Details
        </span>

        <ol style={{ listStyleType: "number" }} className="px-4 my-5">
          <li>
            Does the organization have an ‘ESG Policy’? If yes, attach a copy.
            If no, please explain the reason.
          </li>
          <li>
            Describe the systems and processes by which the organization
            measures and deploys the leading indicators of its sustainability
            about environmental, social and governance (ESG) viability?
          </li>
          <li>
            Does the organization publish an Annual Report on ‘ESG’? If yes,
            attach a copy.
          </li>
          <li>
            <span className="font-semibold">
              Environmental Dimensions of Sustainability Concerns:
            </span>{" "}
            How does the organization ensure compliance to environment related
            legal, regulatory and ethical requirements?
          </li>
          <li>
            <span className="font-semibold">Energy:</span> What were the results
            of energy saving measures taken during the last two financial years?
          </li>
          <li>
            <span className="font-semibold">
              Emissions, Effluents, and Waste:
            </span>{" "}
            Initiatives taken to reduce greenhouse gas emissions and reductions
            achieved.
          </li>
          <li>
            <span className="font-semibold">
              Human Rights and Social Responsibility:
            </span>{" "}
            Please provide details of CSR projects and amount spent by the
            organization on community/environmental projects for the society,
            during the last two financial years.
          </li>
          <li>
            Has any stakeholder consultation been carried out to identify their
            CSR needs, in and around its areas of business operations? Provide
            details.
          </li>
          <li>
            What visible impact on the local community has been created on
            account of community development projects initiated by the
            organization?
          </li>
          <li>
            <span className="font-semibold">Role of Corporate Board:</span> How
            does the Board set direction for the organization and ensure
            strategy and system policies are compatible with its long-term
            sustainability?
          </li>
          <li>
            <span className="font-semibold">Senior Executive Leadership:</span>{" "}
            How do Senior Executives ensure that ‘sustainability needs’ and
            ‘risk mitigation’ measures, are integrated with organization’s
            business processes?
          </li>
          <li>
            <span className="font-semibold">Strategic Planning:</span> How has
            the organization integrated ESG with its strategic planning process?
          </li>
          <li>
            <span className="font-semibold">Creativity & Innovation:</span> How
            does the organization encourage and reward people for innovative
            ideas? Provide details of a few innovative ideas
          </li>
          <li>
            <span className="font-semibold">Supply Chain Management:</span> How
            are outsourced processes controlled for sustainable mutual benefits?
            Give examples.
          </li>
          <li>
            <span className="font-semibold">Internal Audit:</span> Please
            provide evidence to justify that the ‘Internal Audit Reports’ are
            seriously followed up. Give examples
          </li>
          <li>
            <span className="font-semibold">Risk Management:</span> Provide
            details of the significant direct and indirect risks identified,
            emerging out of the operations of the organization, and the measures
            taken by it to mitigate the adverse risks, based on the
            organization’s described risk appetite.
          </li>
          <li>
            <span className="font-semibold">
              Environmental Dimensions of Sustainability Concerns:
            </span>{" "}
            How does the organization ensure compliance to environment related
            legal, regulatory and ethical requirements?
          </li>
          <li>
            <span className="font-semibold">
              Business Excellence through Corporate Governance:
            </span>{" "}
            Describe the organization’s Governance System. How does the
            organization address its responsibilities to the public, ensure
            ethical behaviour, and practice good corporate citizenship? How is
            Business Excellence measured in terms of good governance? Provide
            details
          </li>
          <li>
            <span className="font-semibold"> Products and Services:</span>{" "}
            Initiatives taken to mitigate environmental impacts of major
            products and services, and the extent of mitigation achieved.
          </li>
          <li>
            <span className="font-semibold"> HR Policies & Strategies: </span>{" "}
            How does the organization manage, develop and realize the full
            potential of its people at individual and organization-wide levels,
            to support its policy and strategy, and effective operation of its
            processes?
          </li>
          <li>
            <span className="font-semibold"> Customer Focus:</span> How does the
            organization periodically review customer perception through various
            surveys, feedback, market share analysis, and use customer
            complaints as opportunities to enhance customer relationship?
          </li>
        </ol>

        <span className="border-b border-black pb-1 font-semibold">
          III. Financial Results
        </span>

        <ol style={{ listStyleType: "number" }} className="px-4 my-5">
          <li>
            What has been the organization’s revenue and profit before tax (PBT)
            and growth trend, for the last two financial years?{" "}
          </li>
          <li>
            How has the organization’s market share grown over the same last two
            financial years?{" "}
          </li>
          <li>
            Annual Report: Enclose a copy of the organization’s latest ‘Annual
            Report’.
          </li>
        </ol>
      </div>

      <div className="w-full flex justify-start mt-10 p-5 md:p-0">
        <Link
          to={"/registration"}
          className="flex items-center justify-start border p-2 px-5 rounded bg-red-500 text-white"
        >
          Apply Now
        </Link>
      </div>
    </div>
  );
};

export default GuidelineESG;
