import { useEffect } from "react";
import image from "../../assets/1.png";

const Se = (props) => {
  const formData = props?.data;

  let attachedData = [];
  if (
    typeof formData?.attachments === "object" &&
    formData.attachments !== null
  ) {
    const attachmentKeys = Object.keys(formData.attachments);

    attachmentKeys.forEach((key) => {
      const attachment = formData.attachments[key];
      if (Array.isArray(attachment)) {
        attachedData.push(...attachment);
      } else {
        attachedData.push(attachment);
      }
    });
  }

  if (formData?.paymentDetails?.receipt) {
    attachedData.push(formData?.paymentDetails?.receipt)
  }

  return (
    <div className=" border rounded p-5">
      <h1 className="font-semibold text-center mt-5">
        <span className="font-sherif tracking-wide text-xl text-textColor">
          Application Name: Application for KMA Sustainable Enterprise Awards 2024
        </span>
      </h1>

      <div className="flex items-start justify-center border rounded-xl shadow-xl w-full max-w-[1280px] mx-auto">
        <form
          className="w-full flex flex-col items-center justify-center md:px-20 p-5 md:p-10"
          action=""
        >

          {/* Division heading */}
          <div className="text-start w-full font-semibold mb-5">
            <h2>Organization Profile</h2>
          </div>
          {/* Organization /adress */}
          <div className="w-full md:mt-3  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Registered Name of the Applicant Organization * :
              </label>
              <input
                type="text"
                name="name"
                required
                value={formData?.organizationProfile?.name}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>

            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Corporate Office Location* :
              </label>
              <input
                type="text"
                name="corporateOfficeLocation"
                value={formData?.organizationProfile?.corporateOfficeLocation}
                placeholder=""
                readOnly
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />


            </div>
          </div>

          {/* contact & Email*/}
          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">


            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Total Number of Employees (Full-Time and Contract)  :
              </label>
              <input
                type="text"
                name="totalEmployees"
                value={formData?.organizationProfile?.totalEmployees}
                readOnly
                placeholder="Total Employees"
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />

            </div>

            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Year of Establishment* :
              </label>
              <input
                type="text"
                name="yearOfEstablishment"
                placeholder=""
                value={formData?.organizationProfile?.yearOfEstablishment}
                readOnly
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          {/*Governing Body Details*/}
          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Annual Turnover for FY 2023-24  :
              </label>
              <input
                type="text"
                name="annualTurnoverFY2023_24"
                value={formData?.organizationProfile?.annualTurnoverFY2023_24}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Market Capitalization (as of March 31, 2024 – for listed entities only) :
              </label>
              <input
                type="text"
                name="marketCapitalization"
                value={formData?.organizationProfile?.marketCapitalization}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Ownership Details * :
              </label>
              <input
                type="text"
                name="ownershipDetails"
                value={formData?.organizationProfile?.ownershipDetails}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>

          </div>

          {/* Brief Description of the Organization */}
          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Brief Description of the Organization * :
              </label>
              <textarea
                id=""
                name="briefDescription"
                value={formData?.organizationProfile?.briefDescription}
                readOnly
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none">
              </textarea>
            </div>
          </div>

          {/* sustainabilityGovernance / */}
          {/* Division heading */}
          <div className="text-start w-full font-semibold my-5">
            <h2>Sustainability Governance and Management Approach  </h2>
          </div>

          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                How has the organization integrated principles of responsible business into its strategy,
                policies, and governance in line with NGRBC or any other guidelines
              </label>
              <input
                type="text"
                name="integrationOfResponsibleBusiness"
                value={formData?.sustainabilityGovernance?.integrationOfResponsibleBusiness}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />

            </div>

            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Describe the process adopted by the organisation to identify, prioritise, strategize on the most relevant environmental, social and governance topics, measurement systems, targets, and investments for the entity.

              </label>
              <input
                type="text"
                name="esgTopics"
                readOnly
                value={formData?.sustainabilityGovernance?.esgTopics}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Could you outline the methodology employed by the organization to identify, prioritize, and engage with stakeholders, gather their expectations, obtain their feedback, and provide feedback to them?
              </label>
              <input
                type="text"
                name="stakeholderEngagement"
                value={formData?.sustainabilityGovernance?.stakeholderEngagement}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />

            </div>

            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Specify the level and title of the management personnel responsible for achieving sustainability targets, realizing investments, and handling disclosures and assurance activities.
              </label>
              <input
                type="text"
                name="seniorManagementResponsibilities"
                readOnly
                value={formData?.sustainabilityGovernance?.seniorManagementResponsibilities}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Outline the process for ensuring the accuracy, transparency, and integrity of sustainability measurements.
              </label>
              <input
                type="text"
                name="sustainabilityMeasurements"
                value={formData?.sustainabilityGovernance?.sustainabilityMeasurements}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />

            </div>

            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                How does the Board of Directors ensure compliance and risk mitigation in sustainability governance?
              </label>
              <input
                type="text"
                name="boardResponsibilities"
                readOnly
                value={formData?.sustainabilityGovernance?.boardResponsibilities}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Describe the process by which the organisation identifies compliance
                requirements and other emerging risks related to sustainability topics and
                ensure compliance and mitigation.
              </label>
              <input
                type="text"
                name="complianceProcess"
                value={formData?.sustainabilityGovernance?.complianceProcess}
                placeholder=""
                readOnly
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />

            </div>


          </div>

          {/* sustainabilityIndicators / */}
          <div className="text-start w-full font-semibold my-5">
            <h2>Sustainability Indicators Measurement (Indicate the current status: Measured, Planned to Measure, or Not Applicable with Explanation)
            </h2>
          </div>
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                GHG Footprint:
              </label>
              <input
                type="text"
                name="ghgFootprint"
                value={formData?.sustainabilityIndicators?.ghgFootprint}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />

            </div>

            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Water Footprint:

              </label>
              <input
                type="text"
                name="waterFootprint"
                readOnly
                value={formData?.sustainabilityIndicators?.waterFootprint}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Energy Footprint:

              </label>
              <input
                type="text"
                name="energyFootprint"
                value={formData?.sustainabilityIndicators?.energyFootprint}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Waste Management and Circularity:
              </label>
              <input
                type="text"
                name="wasteManagement"
                value={formData?.sustainabilityIndicators?.wasteManagement}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Employee Well-Being and Safety:

              </label>
              <input
                type="text"
                name="employeeWellBeing"
                value={formData?.sustainabilityIndicators?.employeeWellBeing}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Gender Diversity:
              </label>
              <input
                type="text"
                name="genderDiversity"
                value={formData?.sustainabilityIndicators?.genderDiversity}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Inclusive Development:

              </label>
              <input
                type="text"
                name="inclusiveDevelopment"
                value={formData?.sustainabilityIndicators?.inclusiveDevelopment}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Fairness in Customer Engagement:
              </label>
              <input
                type="text"
                name="customerEngagement"
                value={formData?.sustainabilityIndicators?.customerEngagement}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Openness of the business :
              </label>
              <input
                type="text"
                name="openness"
                value={formData?.sustainabilityIndicators?.openness}
                placeholder=""
                readOnly
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>

          </div>

          {/* sustainabilityFrameworks */}

          <div className="text-start w-full font-semibold my-5">
            <h2>Frameworks, Initiatives, and Achievements  </h2>
          </div>

          {/* Name of the Project / */}
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Has the organization adopted sustainability frameworks like SDG, GRI, BRSR, SASB, or others? Provide details on your existing ESG policy.
              </label>
              <input
                type="text"
                name="adoptedFrameworks"
                readOnly
                value={formData?.sustainabilityFrameworks?.adoptedFrameworks}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />

            </div>

            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Indicate if the applicant has adopted / certified on ISO 9001, ISO 14001,
                ISO 45001, OHSAS 18001, SA 8000, ISO 50001, ISO 26000, ISO 20400
                or any other relevant sustainability related management systems.

              </label>
              <input
                type="text"
                name="isoStandards"
                value={formData?.sustainabilityFrameworks?.isoStandards}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Mention if the applicant has adopted IGBC, USGBC (LEED), GRIHA,
                ECBC, WELL Being, EDGE (IFC), BEE star ratings GEM (ASSOCHAM),
                NGT Guidelines standards for their offices or other buildings or any other
                relevant standards related to built environment and office space.
              </label>
              <input
                type="text"
                name="officeStandards"
                value={formData?.sustainabilityFrameworks?.officeStandards}
                placeholder=""
                readOnly
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />

            </div>

            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Describe any collaborative initiatives the organisation has taken to improve
                the sustainability performance with its peers, suppliers, customers or other
                entities such as NGOs for example sector focused collaborative initiatives.
              </label>
              <input
                type="text"
                name="collaborativeInitiatives"
                value={formData?.sustainabilityFrameworks?.collaborativeInitiatives}
                placeholder=""
                readOnly
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />

            </div>

          </div>

          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Indicate whether the applicant is seeking third party assurance of their
                sustainability disclosures using AA 1000, ISAE 3000 or ISSA 5000 or are
                planning to do it in the next two years.

              </label>
              <input
                type="text"
                name="thirdPartyAssurance"
                value={formData?.sustainabilityFrameworks?.thirdPartyAssurance}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Describe how the organisation is adopting and implementing technology
                and innovative practices to improve sustainability of the organisation. :

              </label>
              <input
                type="text"
                name="innovativePractices"
                value={formData?.sustainabilityFrameworks?.innovativePractices}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Outline initiatives taken to build awareness and competence on sustainability topics among employees and stakeholders.

              </label>
              <input
                type="text"
                name="awarenessInitiatives"
                value={formData?.sustainabilityFrameworks?.awarenessInitiatives}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Describe briefly, three most important sustainability achievements during
                FY 2022 to 2024 in quantifiable terms. Applicant may choose from any of
                the nine indicators mentioned in section 3 or choose any other quantifiable
                indicator that deemed relevant for the organisation. :
              </label>
              <input
                type="text"
                name="significantAchievements"
                value={formData?.sustainabilityFrameworks?.significantAchievements}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Mention any public commitments made in areas like decarbonization, Net Zero, Diversity, or Waste Management.
              </label>
              <input
                type="text"
                name="publicCommitments"
                value={formData?.sustainabilityFrameworks?.publicCommitments}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Can you describe the measures your company takes to ensure pay parity among employees with similar roles and responsibilities? Please include any policies, practices, or initiatives that contribute to equitable compensation.
              </label>
              <input
                type="text"
                name="parityMeasures"
                value={formData?.parityMeasures}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>

          </div>

          {/* Contact details */}
          <div className="text-start w-full font-semibold my-5">
            <h2>Contact Details</h2>
          </div>

          {/* Name of the Project / */}
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Name of the Organization:
              </label>
              <input
                type="text"
                name="organizationName"
                value={formData?.contactDetails?.organizationName}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />

            </div>

            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Address:

              </label>
              <input
                type="text"
                name="address"
                readOnly
                value={formData?.contactDetails?.address}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          {/*Source of Funding: */}
          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Contact Person:

              </label>
              <input
                type="text"
                name="contactPerson"
                value={formData?.contactDetails?.contactPerson}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Mobile Number:

              </label>
              <input
                type="text"
                pattern="[0-9]*"
                inputMode="numeric"
                placeholder=""
                name="mobileNumber"
                value={formData?.contactDetails?.mobileNumber}
                readOnly
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Email Address:

              </label>
              <input
                type="text"
                name="emailAddress"
                value={formData?.contactDetails?.emailAddress}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Website :
              </label>
              <input
                type="text"
                name="website"
                value={formData?.contactDetails?.website}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          {/*payment option */}
          {/* Division heading */}
          <div className="text-start w-full font-semibold my-5">
            <h2>Financial and Payment Details</h2>
          </div>

          {/*payment option */}
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                is KMA Member?*
              </label>
              <select
                id=""
                name="isKmaMember"
                required
                value={formData?.isKmaMember}
                readOnly
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none
                   focus:outline-none focus:shadow-outline font-medium"
              >
                <option
                  value=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Select a category
                </option>
                <option
                  value={true}
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  KMA Member
                </option>
                <option
                  value={false}
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Non-KMA Member
                </option>
              </select>
            </div>
            <div
              className={`w-full space-y-2 ${formData?.isKmaMember ? "block" : "hidden"
                }`}
            >
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Membership Id*
              </label>
              <input
                type="text"
                name="membershipId"
                readOnly
                placeholder=""
                value={formData?.membershipId}
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Application Amount (with GST) :
              </label>
              <input
                type="text"
                name="amountWithGst"
                readOnly
                value={formData?.paymentDetails?.amountWithGst}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Payment Details :*
              </label>
              <select
                id=""
                name="mode"
                required
                value={formData?.paymentDetails?.mode}
                readOnly
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none
                   focus:outline-none focus:shadow-outline font-medium"
              >
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Select a category
                </option>
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Cheque
                </option>
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  DD No.
                </option>
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Online
                </option>
              </select>
            </div>


          </div>


        </form>
      </div>

      <div className="flex w-full">
        <div className="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 mt-10 gap-10 md:px-10">
          {attachedData?.map((attachment, index) => (
            <div className="w-full" key={index}>
              <h1 className="mb-3 text-lg text-center font-semibold font-sherif tracking-wider">
                Attachment {index + 1}
              </h1>
              <div className="overflow-hidden w-full">
                <img
                  src={
                    attachment.key.endsWith(".jpg") ||
                      attachment.key.endsWith(".JPG") ||
                      attachment.key.endsWith(".jpeg") ||
                      attachment.key.endsWith(".JPEG") ||
                      attachment.key.endsWith(".avif") ||
                      attachment.key.endsWith(".png")
                      ? attachment.location
                      : image
                  }
                  alt={attachment?.key}
                  className="object-contain md:h-[250px]"
                />
              </div>
              <a
                href={attachment.location}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="border w-full bg-red-600 text-white text-sm p-1">
                  Download
                </button>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Se;
