import { useEffect } from "react";
import image from "../../assets/1.png";

const Ss = (props) => {
  const formData = props?.data;
  
  console.log({formData})
  
  let attachedData = [];
  if (
    typeof formData?.attachments === "object" &&
    formData.attachments !== null
  ) {
    const attachmentKeys = Object.keys(formData.attachments);

    attachmentKeys.forEach((key) => {
      const attachment = formData.attachments[key];
      if (Array.isArray(attachment)) {
        attachedData.push(...attachment);
      } else {
        attachedData.push(attachment);
      }
    });
  }


  if (formData?.paymentDetails?.receipt) {
    attachedData.push(formData?.paymentDetails?.receipt)
  }

  return (
    <div className=" border rounded p-5">
      <h1 className="font-semibold text-center mt-5">
        <span className="font-sherif tracking-wide text-xl text-textColor">
          Application Name: Application for KMA Sustainability Startup Awards 2024
        </span>
      </h1>

       <div className="flex items-start justify-center border rounded-xl shadow-xl w-full max-w-[1280px] mx-auto">
        <form
          className="w-full flex flex-col items-center justify-center md:px-20 p-5 md:p-10"
          action=""
        >

          {/* Division heading */}
          <div className="text-start w-full font-semibold mb-2">
            <h2>Basic Information</h2>
          </div>
          {/* Organization /adress */}
          <div className="w-full md:mt-3  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                What is the name of your startup?
              </label>
              <input
                type="text"
                required
                name="name"
                value={formData?.organizationProfile?.name}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                When was your startup established?
              </label>
              <input
                type="text"
                name="establishedDate"
                value={formData?.organizationProfile?.establishedDate}
                placeholder=""
                readOnly
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />

            </div>
          </div>

          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Provide details about the founders and the team.
              </label>
              <input
                type="text"
                name="foundersAndTeam"
                value={formData?.organizationProfile?.foundersAndTeam}
                required
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Where is your startup located or headquartered?
              </label>
              <input
                type="text"
                placeholder=""
                name="location"
                value={formData?.organizationProfile?.location}
                readOnly
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          {/*Governing Body Details*/}
          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Contact Person Name
              </label>
              <input
                type="text"
                name="contactPerson"
                value={formData?.organizationProfile?.contactPerson}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Mobile Number
              </label>
              <input
                type="text"
                name="mobile"
                value={formData?.organizationProfile?.mobile}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>
          {/*Governing Body Details*/}
          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Email Address
              </label>
              <input
                type="email"
                name="email"
                value={formData?.organizationProfile?.email}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Do you have a website or social media profiles? If yes, provide the links.
              </label>
              <input
                type="text"
                name="website"
                value={formData?.organizationProfile?.website}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          {/* Division heading */}
          <div className="text-start w-full font-semibold my-5">
            <h2>General Overview</h2>
          </div>

          {/* Name of the Project / */}
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Briefly describe your startup and its core mission (max. 300 words).
              </label>
              <textarea
                name="description"
                value={formData?.generalOverview?.description}
                readOnly
                id=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none">
              </textarea>
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Which United Nations Sustainable Development Goals (SDGs) does your startup address? (Select all that apply).
              </label>
             
              <ul className="text-[#777777] text-[12px] md:text-[13px] font-medium">
                {
                  formData?.generalOverview?.sdgsAddressed?.map((item, index)=>(
                    <li>{item}</li>
                  ))
                }
              </ul>
            </div>
          </div>

          {/* Division heading */}
          <div className="text-start w-full font-semibold my-5">
            <h2>Business Model</h2>
          </div>

          {/*Source of Funding: */}
          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                What is your primary product or service?
              </label>
              <input
                type="text"
                name="primaryProductOrService"
                value={formData?.businessModel?.primaryProductOrService}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                What is your startup's unique value proposition (UVP) for promoting sustainability?
              </label>
              <input
                type="text"
                name="uniqueValueProposition"
                value={formData?.businessModel?.uniqueValueProposition}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>
          {/* Name of the Project / */}
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Who are your primary customers or beneficiaries?
              </label>
              <input
                type="text"
                name="primaryCustomersOrBeneficiaries"
                readOnly
                value={formData?.businessModel?.primaryCustomersOrBeneficiaries}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                What is the current stage of your startup (e.g., Idea stage, MVP, Early Growth, Scaling)?
              </label>
              <input
                type="text"
                name="startupStage"
                readOnly
                value={formData?.businessModel?.startupStage}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Describe your revenue model. (e.g., product sales, subscriptions, partnerships,
                etc.)
              </label>
              <input
                type="text"
                name="revenueModel"
                readOnly
                value={formData?.businessModel?.revenueModel}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>

          </div>

          {/* Division heading */}
          <div className="text-start w-full font-semibold my-5">
            <h2>Sustainability Impact</h2>
          </div>

          {/*  */}
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                How does your startup positively impact the environment or society? Provide measurable results, if available.
              </label>
              <input
                type="text"
                name="positiveImpact"
                readOnly
                value={formData?.sustainabilityImpact?.positiveImpact}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                What is the estimated long-term impact of your startup in terms of sustainability (5–10 years)?
              </label>
              <input
                type="text"
                name="longTermImpact"
                readOnly
                value={formData?.sustainabilityImpact?.longTermImpact}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          {/* Division heading */}
          <div className="text-start w-full font-semibold my-5">
            <h2>Innovation</h2>
          </div>

          {/*  */}
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                What innovative technologies or processes does your startup use to achieve sustainability goals?
              </label>
              <input
                type="text"
                name="technologiesOrProcesses"
                readOnly
                value={formData?.innovation?.technologiesOrProcesses}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                How scalable is your solution?
              </label>
              <input
                type="text"
                name="scalability"
                readOnly
                value={formData?.innovation?.scalability}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
               What makes your approach different from existing solutions in the market in terms of sustainability?
              </label>
              <input
                type="text"
                name="approach"
                readOnly
                value={formData?.innovation?.approach}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>

          </div>

          <div className="text-start w-full font-semibold my-5">
            <h2>Market and Financials</h2>
          </div>

          {/*  */}
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                What is the size of your target market? (e.g., Kerala, India, global)
              </label>
              <input
                type="text"
                name="targetMarketSize"
                readOnly
                value={formData?.marketAndFinancials?.targetMarketSize}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                What is your annual revenue or funding raised (if applicable)?
              </label>
              <input
                type="text"
                name="annualRevenueOrFunding"
                readOnly
                value={formData?.marketAndFinancials?.annualRevenueOrFunding}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Do you have partnerships or collaborations with other organisations, NGOs, or
                governmental bodies? If yes, please provide details.
              </label>
              <input
                type="text"
                name="partnerships"
                readOnly
                value={formData?.marketAndFinancials?.partnerships}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Have you received any grants, awards, or recognitions related to sustainability?
              </label>
              <input
                type="text"
                name="grantsAwardsRecognitions"
                readOnly
                value={formData?.marketAndFinancials?.grantsAwardsRecognitions}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          <div className="text-start w-full font-semibold my-5">
            <h2>Challenges and Growth</h2>
          </div>

          {/*  */}
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                What are the major challenges your startup faces in scaling or achieving its
                sustainability goals?
              </label>
              <input
                type="text"
                name="majorChallenges"
                readOnly
                value={formData?.challengesAndGrowth?.majorChallenges}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                What support or resources do you need to accelerate your growth? (e.g.,
                funding, mentorship, partnerships)
              </label>
              <input
                type="text"
                name="supportNeeded"
                readOnly
                value={formData?.challengesAndGrowth?.supportNeeded}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          <div className="text-start w-full font-semibold my-5">
            <h2>Team and Governance</h2>
          </div>

          {/*  */}
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                How diverse and inclusive is your team? (e.g., gender, background, expertise)
              </label>
              <input
                type="text"
                name="teamDiversity"
                readOnly
                value={formData?.teamAndGovernance?.teamDiversity}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                What governance structure does your startup follow to ensure ethical practices?
              </label>
              <input
                type="text"
                name="governanceStructure"
                readOnly
                value={formData?.teamAndGovernance?.governanceStructure}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>


          {/*payment option */}
          {/* Division heading */}
          <div className="text-start w-full font-semibold my-5">
            <h2>Financial and Payment Details</h2>
          </div>

          {/*payment option */}
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                is KMA Member?*
              </label>
              <select
                id=""
                name="isKmaMember"
                required
                value={formData?.isKmaMember}
                readOnly
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none
                   focus:outline-none focus:shadow-outline font-medium"
              >
                <option
                  value=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Select a category
                </option>
                <option
                  value={true}
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  KMA Member
                </option>
                <option
                  value={false}
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Non-KMA Member
                </option>
              </select>
            </div>
            <div
              className={`w-full space-y-2 ${formData?.isKmaMember ? "block" : "hidden"
                }`}
            >
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Membership Id*
              </label>
              <input
                type="text"
                name="membershipId"
                readOnly
                placeholder=""
                value={formData?.membershipId}
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Application Amount (with GST) :
              </label>
              <input
                type="text"
                name="amountWithGst"
                readOnly
                value={formData?.paymentDetails?.amountWithGst}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Payment Details :*
              </label>
              <select
                id=""
                name="mode"
                required
                value={formData?.paymentDetails?.mode}
                readOnly
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none
                   focus:outline-none focus:shadow-outline font-medium"
              >
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Select a category
                </option>
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Cheque
                </option>
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  DD No.
                </option>
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Online
                </option>
              </select>
            </div>
          
          </div>

        </form>
      </div>


      <div className="flex w-full">
        <div className="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 mt-10 gap-10 md:px-10">
          {attachedData?.map((attachment, index) => (
            <div className="w-full" key={index}>
              <h1 className="mb-3 text-lg text-center font-semibold font-sherif tracking-wider">
                Attachment {index + 1}
              </h1>
              <div className="overflow-hidden w-full">
                <img
                  src={
                    attachment.key.endsWith(".jpg") ||
                    attachment.key.endsWith(".JPG") ||
                    attachment.key.endsWith(".jpeg") ||
                    attachment.key.endsWith(".JPEG") ||
                    attachment.key.endsWith(".avif") ||
                    attachment.key.endsWith(".png")
                      ? attachment.location
                      : image
                  }
                  alt={attachment?.key}
                  className="object-contain md:h-[250px]"
                />
              </div>
              <a
                href={attachment.location}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="border w-full bg-red-600 text-white text-sm p-1">
                  Download
                </button>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Ss;
