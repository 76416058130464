import React from 'react'
import { Outlet } from 'react-router-dom'
import Nav from '../components/Nav'

const CsrLayout = () => {
    return (
        <main>
            <nav className='py-5'>
                <Nav />
            </nav>
            <section>
                <Outlet />
            </section>

            <footer className='max-w-screen-xl mx-auto mb-10'>
                <hr class="my-6 border-gray-200 w-full dark:border-gray-700 lg:my-8" />
                <div class="flex flex-col sm:flex sm:items-center sm:justify-between">
                    <span class="text-[10px] sm:text-sm text-gray-500 sm:text-center dark:text-gray-400">
                        © 2025{" "}
                        <a
                            target="blank"
                            href="https://www.kma.org.in"
                            class="hover:underline"
                        >
                            KMA
                        </a>
                        . All Rights Reserved.
                    </span>
                    <div className="flex">
                        <ul class="text-gray-500 flex md:gap-2 dark:text-gray-400 text-[8px]  md:text-xs font-sm">
                            <li class="sm:mb-2">
                                <a
                                    href="/PrivacyAndPolicy"
                                    class="hover:underline hover:text-red-400"
                                >
                                    Privacy Policy
                                </a>
                            </li>
                            <li class="sm:mb-2">
                                <a
                                    href="/TeamsAndConditions"
                                    class="hover:underline hover:text-red-400"
                                >
                                    Terms &amp; Conditions
                                </a>
                            </li>
                            <li class="sm:mb-2">
                                <a
                                    href="/RefundPolicy"
                                    class="hover:underline hover:text-red-400"
                                >
                                    Cancellation &amp; Refund Policy
                                </a>
                            </li>
                            <li class="sm:mb-2">
                                <a
                                    href="/ShopDelivery"
                                    class="hover:underline hover:text-red-400"
                                >
                                    Shipping &amp; Delivery Policy
                                </a>
                            </li>
                            <li class="sm:mb-2">
                                <a href="/Contact" class="hover:underline hover:text-red-400">
                                    Contact Us
                                </a>
                            </li>
                        </ul>
                    </div>
                    <span class="text-[7px] sm:text-[10px] text-gray-500 sm:text-center dark:text-gray-400">
                        Developed by{" "}
                        <a
                            target="blank"
                            href="https://qmarktechnolabs.com/"
                            class="hover:underline"
                        >
                            Qmark Technolabs
                        </a>
                    </span>
                </div>
            </footer>
        </main>
    )
}

export default CsrLayout
