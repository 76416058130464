import logo from "../../assets/kma_logo.png";
import axiosInstance from "../../utils/Axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { awardBaseURL, LoginAPI } from "../../utils/EndPoint";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/slices/AuthSlicer";
import { setAccessToken, setRefreshToken } from "../../redux/slices/TokenReducer";
import axios from "axios";


const Login = () => {
  const navigator = useNavigate();
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.auth?.userInfo);

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (userData) {
      navigator("/admin/application");
    }
  }, [userData, navigator]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${awardBaseURL}${LoginAPI}`, form);
      if (response?.status === 200) {
        toast.success("Successfully Login ")
        dispatch(setUser(response.data.userInfo));
        dispatch(setAccessToken(response.data?.accessToken));
        dispatch(setRefreshToken(response.data?.refreshToken));

      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.msg)
    }
  };

  return (
    <div className="container mx-auto w-full h-screen flex items-center justify-center">
      <div className="flex flex-col items-center border rounded-xl shadow-lg p-10 w-full md:w-2/6">
        <img src={logo} alt="logo" className="w-[100px] object-cover my-5" />
        {/* <span className="font-sherif text-2xl font-bold">Login Page</span> */}
        <form
          action=""
          onSubmit={submitHandler}
          className="flex flex-col gap-5 my-5 w-full"
        >
          <div className="flex  flex-col  gap-2 ">
            <label htmlFor="" className="text-sm font-sherif">
              Email
            </label>
            <input
              type="email"
              required
              name="email"
              onChange={inputChangeHandler}
              className="p-2 border rounded w-full focus:outline-none"
            />
          </div>

          <div className="flex  flex-col  gap-2 ">
            <label htmlFor="" className="text-sm font-sherif">
              Password
            </label>
            <input
              type="password"
              required
              name="password"
              onChange={inputChangeHandler}
              className="p-2 border rounded w-full focus:outline-none"
            />
          </div>

          <div className="mt-5  flex items-center justify-center">
            <button className="border p-2 px-5 rounded shadow-lg bg-red-600 text-white hover:scale-105 ease-in-out duration-200">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
