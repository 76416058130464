import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import axiosInstance from "../../utils/Axios";
import { awardBaseURL, GetOneApplicationAPI, getSingleAppln } from "../../utils/EndPoint";
import Ngo from "../../components/admin/Ngo";
import Csr from "../../components/admin/Csr";
import Esg from "../../components/admin/Esg";
import Se from "../../components/admin/Se";
import Ss from "../../components/admin/Ss";
import axios from "axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const ApplicationSingleView = () => {
  const [data, setData] = useState({});
  const { formName, id } = useParams();
  const axiosPrivateHook = useAxiosPrivate()

  useEffect(() => {
    axiosPrivateHook
      .get(`${awardBaseURL}${getSingleAppln}/${id}?type=${formName}`)
      .then((res) => {
        setData(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [formName, id]);

  return (
    <div className="container mx-auto max-w-[1280px] relative min-h-screen mb-3 w-full p-10">
      <div className="w-full h-full">
        <h1 className="font-sherif text-[30px] text-textColor mb-5">
          Application View
        </h1>
        {formName === "CSR" && <Csr data={data} />}
        {formName === "NGO" && <Ngo data={data} />}
        {formName === "SE" && <Se data={data} />}
        {formName === "SS" && <Ss data={data} />}
        
      </div>
    </div>
  );
};

export default ApplicationSingleView;
