import { useEffect, useState } from "react";
import Form1 from "../../components/client/form/Form1";
import Form2 from "../../components/client/form/Form2";
import Form3 from "../../components/client/form/Form3";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Form4 from "../../components/client/form/Form4";

const Form = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const status = queryParams.get("status");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (status === "success") {
      toast.success("Successfully Complete The Payment");
    } else if (status === "cancel") {
      toast.warn("Cancelled The Payment Do The Process Again");
    } else if (status === "fail") {
      toast.error("Failed Your Payment Please Try Again Later");
    }
  }, [status]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className="container mx-auto w-full min-h-screen lg:mt-10 ">
      <div className="max-w-[1280px] mx-auto p-5 md:p-0">
        <h1 className="font-bold text-textColor font-sherif text-[28px] lg:text-[40px] text-center uppercase">
          Select your form
        </h1>
        <div action="" className="flex flex-col gap-5 mt-5">
          {/* sustainable */}
          <div className="flex gap-5 items-center justify-between border rounded p-5 shadow text-textColor">
            <label
              htmlFor="EnterpriseAward"
              className="cursor-pointer font-semibold text-lg font-sherif"
            >
              Sustainable Enterprise Award - Large and Medium 2025
            </label>
            <input
              id="EnterpriseAward"
              type="radio"
              value="EnterpriseAward"
              checked={selectedOption === "EnterpriseAward"}
              onChange={handleOptionChange}
            />
          </div>
          {/* best csr */}
          <div className="flex gap-5 items-center justify-between border rounded p-5 shadow text-textColor">
            <label
              htmlFor="CSRProjectAwards"
              className="cursor-pointer font-semibold text-lg font-sherif"
            >
              Best CSR Project Awards 2025
            </label>
            <input
              id="CSRProjectAwards"
              type="radio"
              value="CSRProjectAwards"
              checked={selectedOption === "CSRProjectAwards"}
              onChange={handleOptionChange}
            />
          </div>
          {/* Startup */}
          <div className="flex gap-5 items-center justify-between border rounded p-5 shadow text-textColor">
            <label
              htmlFor="SustainabilityStartupAward"
              className="cursor-pointer font-semibold text-lg font-sherif"
            >
              Sustainability Startup Award 2025
            </label>
            <input
              id="SustainabilityStartupAward"
              type="radio"
              value="SustainabilityStartupAward"
              checked={selectedOption === "SustainabilityStartupAward"}
              onChange={handleOptionChange}
            />
          </div>
          {/* imapactful */}
          <div className="flex gap-5 items-center justify-between border rounded p-5 shadow text-textColor">
            <label
              htmlFor="NGOAward"
              className="cursor-pointer font-semibold text-lg font-sherif"
            >
              Impactful NGO Award 2025
            </label>
            <input
              id="NGOAward"
              type="radio"
              value="NGOAward"
              checked={selectedOption === "NGOAward"}
              onChange={handleOptionChange}
            />
          </div>

          {selectedOption && (
            <div className="selected-form flex items-center justify-center mt-10 mb-20 md:mb-40">
              {/* Render the form based on the selected option */}
              {selectedOption === "NGOAward" && (
                <div className="w-full min-h-screen items-center justify-center">
                  {/* Your form fields for KMA Advocacy Awards */}
                  <Form1 />
                </div>
              )}
              {selectedOption === "EnterpriseAward" && (
                <div className="w-full min-h-screen items-center justify-center">
                  {/* Your form fields for KMA CSR Awards */}
                  <Form2 />
                </div>
              )}
              {selectedOption === "SustainabilityStartupAward" && (
                <div className="w-full min-h-screen items-center justify-center">
                  {/* Your form fields for KMA ESG Awards */}
                  <Form3 />
                </div>
              )}
              {selectedOption === "CSRProjectAwards" && (
                <div className="w-full min-h-screen items-center justify-center">
                  {/* Your form fields for KMA ESG Awards */}
                  <Form4 />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Form;
