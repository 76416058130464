export const category = [
    
    {
        id:"KMA",
        categoryName:"KMA Member",
        amount:4000
    },
    {
        id:"Non-KMA",
        categoryName:"Non-KMA Member",
        amount:5000
    },
    // {
    //     id:"KMA Students",
    //     categoryName:"KMA Student Member",
    //     amount:2000
    // },
    // {
    //     id:"Non-Student Members",
    //     categoryName:"Non-KMA Student Member",
    //     amount:3000
    // },
    // {
    //     id:"International delegate",
    //     categoryName: "International delegate",
    //     amount:""
    // }
]


export const sdgGoals = [
    { value: "SDG 01 - No Poverty", label: "SDG 01 - No Poverty" },
    { value: "SDG 02 - Zero Hunger", label: "SDG 02 - Zero Hunger" },
    { value: "SDG 03 - Good Health and Well-being", label: "SDG 03 - Good Health and Well-being" },
    { value: "SDG 04 - Quality Education", label: "SDG 04 - Quality Education" },
    { value: "SDG 05 - Gender Equality", label: "SDG 05 - Gender Equality" },
    { value: "SDG 06 - Clean Water and Sanitation", label: "SDG 06 - Clean Water and Sanitation" },
    { value: "SDG 07 - Affordable and Clean Energy", label: "SDG 07 - Affordable and Clean Energy" },
    { value: "SDG 08 - Decent Work and Economic Growth", label: "SDG 08 - Decent Work and Economic Growth" },
    { value: "SDG 09 - Industry, Innovation, and Infrastructure", label: "SDG 09 - Industry, Innovation, and Infrastructure" },
    { value: "SDG 10 - Reduced Inequalities", label: "SDG 10 - Reduced Inequalities" },
    { value: "SDG 11 - Sustainable Cities and Communities", label: "SDG 11 - Sustainable Cities and Communities" },
    { value: "SDG 12 - Responsible Consumption and Production", label: "SDG 12 - Responsible Consumption and Production" },
    { value: "SDG 13 - Climate Action", label: "SDG 13 - Climate Action" },
    { value: "SDG 14 - Life Below Water", label: "SDG 14 - Life Below Water" },
    { value: "SDG 15 - Life on Land", label: "SDG 15 - Life on Land" },
    { value: "SDG 16 - Peace, Justice, and Strong Institutions", label: "SDG 16 - Peace, Justice, and Strong Institutions" },
    { value: "SDG 17 - Partnerships for the Goals", label: "SDG 17 - Partnerships for the Goals" },
  ];
  