import React from 'react'

const CsrProjectReportModal = ({ isVisible, onClose }) => {
    if (!isVisible) return null;

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };
    return (

        <div
            onClick={handleOverlayClick}
            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg shadow-lg w-96 max-w-full p-6 sm:p-10 relative">
                <button
                    onClick={onClose}
                    className="absolute top-2 right-2 text-gray-400 hover:text-gray-600 text-xl"
                >
                    &times;
                </button>

                <ul className='list-decimal text-gray-800 text-sm '>
                    <li>What was the process adopted to assess community needs?</li>
                    <li>The Process that was employed in implementation</li>
                    <li> Whether the project was carried out directly or through an implementing Agency. Provide
                        relevant details</li>
                    <li>Resource allocation in terms of physical and financial units and time span.</li>
                    <li>Challenges, if any, faced during the course of implementation</li>
                    <li>Improvisations made for course corrections</li>
                    <li>Describe the impact of the program - quantitative and qualitative improvement brought
                        about in the life-style of the community?</li>
                    <li>Steps taken to ensure sustainability of the programme.</li>
                </ul>
            </div>
        </div>
    )
}

export default CsrProjectReportModal