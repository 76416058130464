import React, { useState } from 'react'

const ProjectDetailView = ({ index, item }) => {
console.log(item)
    return (
        <>
            <div className="text-start w-full font-semibold my-5 flex gap-5 items-center ">
                <h2>Project : {index + 1}</h2>


            </div>

            {/* Name of the Project / */}
            <div className="w-full flex flex-col md:flex-row gap-2 py-1">
                <div className="w-full space-y-2">
                    <label
                        htmlFor=""
                        className="text-[#777777] text-[12px] md:text-[13px] font-medium"
                    >
                        Name of the Project
                    </label>
                    <input
                        type="text"
                        name="name"
                        placeholder=""
                        value={item.name}
                        className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
                    />
                </div>
                <div className="w-full space-y-2">
                    <label
                        htmlFor=""
                        className="text-[#777777] text-[12px] md:text-[13px] font-medium"
                    >
                        Key Theme or Focus Area
                    </label>
                    <input
                        type="text"
                        name="keyTheme"
                        value={item.keyTheme}
                        placeholder="Health, Education, Rural Development, Environment Protection"
                        className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
                    />
                </div>
            </div>
            {/*Source of Funding: */}
            <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
                <div className="w-full space-y-2">
                    <label
                        htmlFor=""
                        className="text-[#777777] text-[12px] md:text-[13px] font-medium"
                    >
                        What are the diverse sources of funding?
                    </label>
                    <input
                        type="text"
                        name="sourceOfFunding"
                        placeholder=""
                        value={item.sourceOfFunding}
                        className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
                    />
                </div>
                <div className="w-full space-y-2">
                    <label
                        htmlFor=""
                        className="text-[#777777] text-[12px] md:text-[13px] font-medium"
                    >
                        Project Location :
                    </label>
                    <input
                        type="text"
                        name="location"
                        value={item.location}
                        placeholder=""
                        className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
                    />
                </div>
            </div>
            {/* Name of the Project / */}
            <div className="w-full flex flex-col md:flex-row gap-2 py-1">
                <div className="w-full space-y-2">
                    <label
                        htmlFor=""
                        className="text-[#777777] text-[12px] md:text-[13px] font-medium"
                    >
                        Project Start Date (FY) and End Date (FY):
                    </label>
                    <div className="flex gap-2">
                        <input
                            type="date"
                            name="startDate"
                            placeholder=""
                            value={item?.startDate ? new Date(item.startDate).toISOString().split("T")[0] : ""}
                            className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
                        />
                        <input
                            type="date"
                            name="endDate"
                            placeholder=""
                            value={item?.endDate ? new Date(item.endDate).toISOString().split("T")[0] : ""}
                            className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
                        />
                    </div>

                </div>
                <div className="w-full space-y-2">
                    <label
                        htmlFor=""
                        className="text-[#777777] text-[12px] md:text-[13px] font-medium"
                    >
                        Total Duration of the Project:
                    </label>

                    <span className='text-xs'>
                    {
                        `${item?.duration?.years} years,
                            ${item?.duration?.months} months,
                                ${item?.duration?.days} days`
                    }

                    </span>


                </div>
            </div>
            {/*  */}


            <div className="w-full flex flex-col md:flex-row gap-2 py-1">

                <div className="w-full space-y-2">
                    <label
                        htmlFor=""
                        className="text-[#777777] text-[12px] md:text-[13px] font-medium"
                    >
                        Were the Need Assessment and Baseline studies related to the Project / Programme
                        conducted? If yes, are the reports available?
                    </label>
                    <input
                        type="text"
                        name="needBaselineStudies"
                        placeholder=""
                        value={item.needBaselineStudies}
                        className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
                    />
                </div>
            </div>


            <div className="w-full flex flex-col md:flex-row gap-2 py-1">
                <div className="w-full space-y-2">
                    <label
                        htmlFor=""
                        className="text-[#777777] text-[12px] md:text-[13px] font-medium"
                    >
                        Total Amount Spent (Breakdown by FYs 2020-21, 2021-22, 2022-23, 2023-24):
                    </label>

                    <div
                        className="border border-[#771D32]/40 w-full md:full text-gray-700 text-[10px] md:text-xs
             rounded-lg  placeholder:text-xs  outline-none p-6 "
                    >
                        <table
                            className="w-full"
                        >
                            <thead>
                                <tr className="border-b border-[#771D32]/40">
                                    <th className="text-[#777777] text-[12px] md:text-[13px] font-medium text-left">Financial Year</th>
                                    <th className="text-[#777777] text-[12px] md:text-[13px] font-medium text-left">Amount Spent</th>
                                </tr>
                            </thead>
                            <tbody >
                                {Object.keys(item.totalAmountSpent)?.filter(elem => elem !== '_id').map((key) => (
                                    <tr key={key}
                                        className="border-b border-[#771D32]/40 w-full md:full p-2 md:p-2 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
                                    >
                                        <td className="text-[#777777] text-[12px] md:text-[13px] font-medium uppercase ">
                                            {key?.split('_')?.join('-')}
                                        </td>
                                        <td className="text-[#777777] text-[12px] md:text-[13px] font-medium">
                                            <input
                                                type="text"
                                                name={key}
                                                value={item.totalAmountSpent[key]}
                                                className="border border-[#771D32]/40 w-full md:full p-2 md:p-2 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>

                </div>

            </div>


            <div className="w-full flex flex-col md:flex-row gap-2 py-1">

                <div className="w-full flex flex-col justify-between space-y-2">
                    <label
                        htmlFor=""
                        className="text-[#777777] text-[12px] md:text-[13px] font-medium"
                    >
                        Major Concerns Addressed by the Project:
                    </label>
                    <input
                        type="text"
                        name="concernsAddressed"
                        placeholder=""
                        value={item.concernsAddressed}
                        className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
                    />
                </div>

                <div className="w-full flex flex-col justify-between space-y-2">
                    <label
                        htmlFor=""
                        className="text-[#777777] text-[12px] md:text-[13px] font-medium"
                    >
                        How are the objectives of the Project aligned to national and global developmental
                        priorities?
                    </label>
                    <input
                        type="text"
                        name="objectiveAlignment"
                        placeholder=""
                        value={item.objectiveAlignment}
                        className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
                    />
                </div>

            </div>

            {/*  */}
            <div className="w-full flex flex-col md:flex-row gap-2 py-1">
                <div className="w-full space-y-2">
                    <label
                        htmlFor=""
                        className="text-[#777777] text-[12px] md:text-[13px] font-medium"
                    >
                        Diverse Stakeholders and Beneficiaries  (List Key Groups):
                    </label>
                    <input
                        type="text"
                        name="stakeholders"
                        placeholder=""
                        value={item.stakeholders}
                        className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
                    />
                </div>
                <div className="w-full space-y-2">
                    <label
                        htmlFor=""
                        className="text-[#777777] text-[12px] md:text-[13px] font-medium"
                    >
                        Total Number of Beneficiaries:
                    </label>
                    <input
                        type="text"
                        name="totalBeneficiaries"
                        placeholder=""
                        value={item.totalBeneficiaries}
                        className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
                    />
                </div>
            </div>
            {/*  */}
            <div className="w-full flex flex-col md:flex-row gap-2 py-1">
                <div className="w-full space-y-2">
                    <label
                        htmlFor=""
                        className="text-[#777777] text-[12px] md:text-[13px] font-medium"
                    >
                        Any Innovative Methods Used in Project Design or Execution?
                    </label>
                    <input
                        type="text"
                        name="innovativeMethods"
                        placeholder=""
                        value={item.innovativeMethods}
                        className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
                    />
                </div>
                <div className="w-full space-y-2">
                    <label
                        htmlFor=""
                        className="text-[#777777] text-[12px] md:text-[13px] font-medium"
                    >
                        How was the Project monitored by the donor organisation during implementation?
                    </label>
                    <input
                        type="text"
                        name="donorMonitor"
                        placeholder=""
                        value={item.donorMonitor}
                        className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
                    />
                </div>
            </div>

            <div className="w-full flex flex-col md:flex-row gap-2 py-1">

                <div className="w-full space-y-2">
                    <label
                        htmlFor=""
                        className="text-[#777777] text-[12px] md:text-[13px] font-medium"
                    >
                        Qualitative and Quantitative Impact of the Project:
                    </label>
                    <input
                        type="text"
                        name="impact"
                        placeholder=""
                        value={item.impact}
                        className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
                    />
                </div>
            </div>

            {/*  */}
            <div className="w-full flex flex-col md:flex-row gap-2 py-1">
                <div className="w-full space-y-2">
                    <label
                        htmlFor=""
                        className="text-[#777777] text-[12px] md:text-[13px] font-medium"
                    >
                        Sustainability Strategy of the Project:
                    </label>
                    <input
                        type="text"
                        name="sustainabilityStrategy"
                        placeholder=""
                        value={item.sustainabilityStrategy}
                        className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
                    />
                </div>
                <div className="w-full space-y-2">
                    <label
                        htmlFor=""
                        className="text-[#777777] text-[12px] md:text-[13px] font-medium"
                    >
                        Probability of Replicability of the Project:
                    </label>
                    <input
                        type="text"
                        name="replicabilityProbability"
                        placeholder=""
                        readOnly
                        value={item.replicabilityProbability}
                        className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
                    />
                </div>
            </div>
            <div className="w-full flex flex-col md:flex-row gap-2 py-1">
                <div className="w-full flex flex-col justify-between space-y-2">
                    <label
                        htmlFor=""
                        className="text-[#777777] text-[12px] md:text-[13px] font-medium"
                    >
                       Can you provide specific examples of how your organization has successfully scaled its programs or services? Please describe a project or initiative that expanded its reach or impact, and include details on the strategies, resources, and partnerships that contributed to this growth.
                    </label>
                    <input
                        type="text"
                        name="scaledExample"
                        placeholder=""
                        readOnly
                        value={item.scaledExample}
                        className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
                    />
                </div>
                <div className="w-full flex flex-col justify-between space-y-2">
                    <label
                        htmlFor=""
                        className="text-[#777777] text-[12px] md:text-[13px] font-medium"
                    >
                       How does your organization generate funds independently? Please share successful fundraising campaigns, revenue programs, partnerships, or other innovative strategies for financial sustainability.
                    </label>
                    <input
                        type="text"
                        name="fundGenerations"
                        placeholder=""
                        value={item?.fundGenerations }
                        className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
                    />
                </div>
            </div>
            {/*  */}
            <div className="w-full flex flex-col md:flex-row gap-2 py-1">
                <div className="w-full flex flex-col md:flex-row gap-2 py-1">
                    <div className="w-full space-y-2">
                        <label
                            htmlFor=""
                            className="text-[#777777] text-[12px] md:text-[13px] font-medium"
                        >
                            Was an Impact Assessment Conducted?
                        </label>
                        <div className="flex gap-2 items-center">
                            {item?.impactAssessment?.conducted ? 'Yes' : 'No'}
                        </div>
                    </div>
                    <div
                        className={`w-full space-y-2 ${item?.impactAssessment?.conducted ? "block" : "hidden"
                            }`}
                    >
                        <label
                            htmlFor=""
                            className="text-[#777777] text-[12px] md:text-[13px] font-medium"
                        >
                            Conducted by*
                        </label>
                        {item?.impactAssessment?.by}
                    </div>
                    <div
                        className={`w-full space-y-2 ${item?.impactAssessment?.by === "Third-Party Agency" ? "block" : "hidden"
                            }`}
                    >
                        <label
                            htmlFor=""
                            className="text-[#777777] text-[12px] md:text-[13px] font-medium"
                        >
                            Third-Party Agency *
                        </label>
                        {item?.impactAssessment?.thirdPartyAgencyName}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProjectDetailView