import React, { useState } from 'react'
import { toast } from 'react-toastify';
import axios from 'axios';
import { awardBaseURL, awardUpload } from '../../utils/EndPoint';
import { ClipLoader, PulseLoader } from 'react-spinners';

const FileInput = ({ className, label, name, setFile, allowedFileTypes }) => {
    const [uploading, setUploading] = useState(false);

    const uploadImage = async (file) => {
        setUploading(true);

        const formData = new FormData();
        formData.append('file', file)

        try {
            const response = await axios.post(`${awardBaseURL}${awardUpload}`, formData);

            if (response.status === 200) {
                const file = await response.data.file
                setFile(file)
            }
            else {
                throw new Error(`Upload failed`);
            }

        } catch (err) {
            toast.error('Something went wrong during upload.');
        } finally {
            setUploading(false);
        }
    }

    const docChangeHandler = async (e) => {
        const { files } = e.target;
        const file = files[0];
        if (file) {
            if (Array.isArray(allowedFileTypes) && !allowedFileTypes?.includes(file.type)) {
                toast.error('Unsupported file type.');
                return;
            }

            if (file.size > 25 * 1024 * 1024) {
                toast.warn("File size should be less than 25MB");
            } else {
                await uploadImage(file)
            }
        }
    };

    return (
        <div className={className}>
            <label htmlFor="" className="text-[#777777] text-[12px] md:text-[13px] font-medium flex items-center gap-4">
                <span>{label}</span>
                {
                    uploading
                    &&
                    <ClipLoader size={8} color='red' />
                }
            </label>
            <input
                type="file"
                id={name}
                name={name}
                onChange={docChangeHandler}
                placeholder=""
                disabled={uploading}
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
            />
        </div>
    )
}

export default FileInput