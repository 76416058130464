import { useEffect, useState } from "react";
import axiosInstance from "../../utils/Axios";
import { ApplicationAPI, awardBaseURL, getAllCsrApplns, getAllNgoApplns, getAllSeApplns, getAllSsApplns } from "../../utils/EndPoint";
import { useNavigate } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/slices/AuthSlicer";
import Pagination from "../../components/admin/Pagination";
import SearchData from "../../components/admin/SeaarchData";
import { toast } from "react-toastify";

import Loading from "../../components/loadingPage/Loading";
import CSRTable from "./tables/CSRTable";
import NGOTable from "./tables/NGOTable";
import SSTable from "./tables/SSTable";
import SETable from "./tables/SETable";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const Application = () => {
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [awardType, setAwardType] = useState("CSR");
  const axiosPrivateHook = useAxiosPrivate()

  const route = awardType === "CSR"
    ? getAllCsrApplns
    : awardType === "NGO"
      ? getAllNgoApplns
      : awardType === "SS"
        ? getAllSsApplns
        : awardType === "SE"
        && getAllSeApplns


  const allData = async () => {
    setLoader(true);
    await axiosPrivateHook
      .get(`${awardBaseURL}${route}?page=${page}`)
      .then((res) => {
        setData(res?.data);

        console.log(res.data)
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    allData();
  }, [page, awardType]);

  // Search Student
  const searchHandler = async () => {
    try {
      setLoader(true);
      const response = await axiosInstance.get(
        `${ApplicationAPI}?search=${search}`
      );
      setData(response?.data);
    } catch (error) {
      toast.warning("Please Try After Sometime...");
    } finally {
      setLoader(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      searchHandler();
    }
  };

  const logoutHandler = () => {
    dispatch(logout());
  };

  const HomeHandler = () => {
    // allData();
  };

  const convertToCSV = (objArray, excludedFields) => {
    if (!Array.isArray(objArray) || objArray.length === 0) return "";
  
    let str = "";
  
    // Function to flatten an object
    const flattenObject = (obj, parentKey = "") => {
      let flatObject = {};
      for (let key in obj) {
        let newKey = parentKey ? `${parentKey}_${key}` : key;
  // Special case: Rename organizationProfile.name -> organizationName
  if (parentKey === "organizationProfile" && key === "name") {
    flatObject["organizationName"] = obj[key];
    continue;
  }

  if (typeof obj[key] === "object" && obj[key] !== null) {
    if (Array.isArray(obj[key])) {
      // Check if it's an array of objects
      if (obj[key].every((item) => typeof item === "object" && item !== null)) {
        obj[key].forEach((item, index) => {
          Object.assign(flatObject, flattenObject(item, `${newKey}_${index}`));
        });
      } else {
        flatObject[newKey] = obj[key]
          .map((item) => (typeof item === "object" ? JSON.stringify(item) : String(item)))
          .join("; ");
      }
    } else {
      // Recursively flatten nested objects
      Object.assign(flatObject, flattenObject(obj[key], newKey));
    }
  } else {
    flatObject[newKey] = obj[key];
  }
}
return flatObject;
};

// Extract headers ensuring consistent order
const headersSet = new Set();
const flattenedData = objArray.map((item) => {
const flatItem = flattenObject(item);
Object.keys(flatItem).forEach((key) => {
  if (!excludedFields.includes(key)) headersSet.add(key);
});
return flatItem;
});

const headers = Array.from(headersSet); // Convert Set to ordered array
str += headers.join(",") + "\r\n"; // Add headers row

// Generate CSV rows
for (const flattenedItem of flattenedData) {
const line = headers
  .map((header) => {
    let value = flattenedItem[header];
    if (value === null || value === undefined) value = ""; // Handle empty values
    return `"${String(value).replace(/"/g, '""')}"`; // Escape double quotes
  })
  .join(",");

str += line + "\r\n";
}

return str;
};
  
  
  // Function to download data as CSV
  const downloadData = () => {
    const excludedFields = ["attachments", "_id", "createdAt", "updatedAt", "__v"];
    const csvData = convertToCSV(data, excludedFields);
  
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "application_data.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  

  return (
    <div className=" container mx-auto md:max-w-[1280px] relative min-h-screen w-full p-10">
      <div className="flex items-center justify-between mb-5">
        <h1
          onClick={HomeHandler}
          className="font-sherif text-[30px] text-textColor cursor-pointer"
        >
          Application
        </h1>
        <button onClick={downloadData} className="border p-2 px-5">
          Download Excel
        </button>

        <select value={awardType} onChange={(e) => setAwardType(e.target.value)}>
          <option value="CSR" >CSR</option>
          <option value="NGO" >NGO</option>
          <option value="SS" >SS</option>
          <option value="SE" >SE</option>
        </select>

        <div>
          <SearchData
            placeholder={"Search"}
            searchHandler={searchHandler}
            handleKeyPress={handleKeyPress}
            setSearch={setSearch}
          />
        </div>
        <div>
          <FiLogOut
            size={34}
            onClick={logoutHandler}
            className="cursor-pointer hover:scale-105 ease-in-out duration-300"
          />
        </div>
      </div>

      {
        awardType === "CSR"
        &&
        <CSRTable data={data} page={page} />
      }
      {
        awardType === "NGO"
        &&
        <NGOTable data={data} page={page} />
      }
      {
        awardType === "SS"
        &&
        <SSTable data={data} page={page} />
      }
      {
        awardType === "SE"
        &&
        <SETable data={data} page={page} />
      }

      <div className="mt-5 w-full flex items-center justify-end px-5 ">
        <Pagination
          Data={data}
          page={page}
          setPage={setPage}
          getMethod={allData}
        />
      </div>

      {loader && <Loading />}
    </div>
  );
};

export default Application;
