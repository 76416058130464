import { useEffect } from "react";
import image from "../../assets/1.png";

const Csr = (props) => {
  const formData = props?.data;

  let attachedData = [];
  if (
    typeof formData?.attachments === "object" &&
    formData.attachments !== null
  ) {
    const attachmentKeys = Object.keys(formData.attachments);

    attachmentKeys.forEach((key) => {
      const attachment = formData.attachments[key];
      if (Array.isArray(attachment)) {
        attachedData.push(...attachment);
      } else {
        attachedData.push(attachment);
      }
    });
  }

  if(formData?.paymentDetails?.receipt){
     attachedData.push(formData?.paymentDetails?.receipt)
  }

  return (
    <div className=" border rounded p-5">
      <h1 className="font-semibold text-center mt-5">
        <span className="font-sherif tracking-wide text-xl text-textColor">
          Application Name: Application for KMA CSR Awards 2024
        </span>
      </h1>

      <div className="flex items-start justify-center border rounded-xl shadow-xl w-full max-w-[1280px] mx-auto">
        <form
          className="w-full flex flex-col items-center justify-center md:px-20 p-5 md:p-10"
          action=""
        >

          {/* Division heading */}
          <div className="text-start w-full font-semibold mb-2">
            <h2>Basic Information</h2>
          </div>

          {/* Organization /adress */}
          <div className="w-full md:mt-3  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Name of the Organization:
              </label>
              <input
                type="text"
                name="name"
                value={formData?.organizationProfile?.name}
                readOnly
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>

          </div>

          <div className="w-full md:mt-3  flex flex-col md:flex-row gap-2 py-1">

            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Corporate Office Location:
              </label>
              <input
                type="text"
                name="corporateOfficeLocation"
                value={formData?.organizationProfile?.corporateOfficeLocation}
                readOnly
                placeholder=""

                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          <div className="w-full md:mt-3  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Brief Description of the Applicant Organization (in about 50 words):
              </label>
              <input
                type="text"
                name="description"
                readOnly
                value={formData?.organizationProfile?.description}

                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Ownership:
              </label>
              <input
                type="text"
                name="ownership"
                value={formData?.organizationProfile?.ownership}
                readOnly
                placeholder=""

                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          {/* contact & Email*/}
          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Year of Establishment of the Applicant Organization:
              </label>
              <input
                type="text"
                name=" yearOfEstablishment"
                value={formData?.organizationProfile?.yearOfEstablishment}
                readOnly

                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Annual Turnover of FY 2023-24
              </label>
              <input
                type="text"
                name="annualTurnoverFY2023_24"
                placeholder=""
                value={formData?.organizationProfile?.annualTurnoverFY2023_24}

                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          {/*Governing Body Details*/}
          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Total Number of employees of the Applicant Organization:
              </label>
              <input
                type="number"
                min={0}
                name="totalEmployees"
                value={formData?.organizationProfile?.totalEmployees}

                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Has the Applicant Organization adopted any International Integrated Reporting System
                (IIRS)? Attach a copy of your latest report. In case IIRS is not being followed, then specify
                the reporting system you are following (Attach a copy).
              </label>
              <input
                type="text"
                name="reportingSystem"
                value={formData?.organizationProfile?.reportingSystem}

                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          {/* Brief Description of the Organization */}
          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Indicate if the applicant is certified to ISO 9001 / ISO 14001 / ISO 45001 / OHSAS
                18001 / SA 8000 / others. Attach a copy of the certificate(s).
              </label>
              <input
                type="text"
                name="certifications"
                value={formData?.organizationProfile?.certifications}

                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>
          {/* Division heading */}
          <div className="text-start w-full font-semibold my-5">
            <h2>CSR Policy and Practice</h2>
          </div>

          {/* Name of the Project / */}
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                What are the salient features of the Organization’s CSR Policy?
              </label>
              <input
                type="text"
                name="salientFeatures"
                readOnly
                value={formData?.csrPolicyAndPractice?.salientFeatures}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Is there a CSR committee? What is its composition, role, and responsibilities?
              </label>
              <input
                type="text"
                name="csrCommittee"
                readOnly
                value={formData?.csrPolicyAndPractice?.csrCommittee}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>
          {/*Source of Funding: */}
          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Specify funds allocated for CSR activities (in absolute numbers, and as a percentage of
                net profit), during the last 4 years (year-wise).
              </label>
              <input
                type="text"
                name="fundsAllocated"
                value={formData?.csrPolicyAndPractice?.fundsAllocated}

                readOnly
                placeholder="Select the appropriate category."
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                List the various CSR projects that were carried out during the FY 2023-24
              </label>
              <input
                type="text"
                name="projectsFY2023_24"
                value={formData?.csrPolicyAndPractice?.projectsFY2023_24}

                readOnly
                placeholder="Select the appropriate category."
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>
          {/* Name of the Project / */}
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                What awareness programs are in place to involve employees, their families, and
                community members, in respect of the Organization’s CSR projects.
              </label>
              <input
                type="text"
                name="awarenessPrograms"
                readOnly
                value={formData?.csrPolicyAndPractice?.awarenessPrograms}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Does the Organization’s internal audit system include regular audit of CSR programmes
                and performance? Provide details.
              </label>
              <input
                type="text"
                name="auditSystem"
                readOnly
                value={formData?.csrPolicyAndPractice?.auditSystem}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Is a CSR Report being published separately? If yes, enclose a copy of the latest CSR
                Annual Report.
              </label>
              <input
                type="text"
                name="csrReport"
                readOnly
                value={formData?.csrPolicyAndPractice?.csrReport}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Any accolades received by the Company for its CSR projects?
              </label>
              <input
                type="text"
                name="accolades"
                readOnly
                value={formData?.csrPolicyAndPractice?.accolades}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>
           <div className="text-start w-full font-semibold my-5 flex flex-col ">
            <h2>CSR Project Report/impact assessment study</h2>

            <div className="w-full space-y-2 my-2 ">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Choose the theme of your CSR project*
              </label>
              <select
                id=""
                name="projectTheme"
                value={formData?.projectTheme}
                disabled
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none
                   focus:outline-none focus:shadow-outline font-medium "
              >
                <option
                  value=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Select a Theme
                </option>
                <option
                  value="Education"
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Education
                </option>
                <option
                  value="Health"
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Health
                </option>
                <option
                  value="Social Inclusion"
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Social Inclusion
                </option>
                <option
                  value="Environment"
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Environment
                </option>
              </select>
            </div>
          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
              What key criteria does your organization use to select and prioritize CSR projects and themes? Please include considerations such as alignment with company values, community needs, potential impact, sustainability, and stakeholder engagement.
              </label>
              <input
                type="text"
                name="keyCriteria"
                value={formData?.keyCriteria}

                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>
          </div>
         
          {/* Division heading */}
          <div className="text-start w-full font-semibold my-5">
            <h2>Financial and Payment Details</h2>
          </div>

          {/*payment option */}
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                is KMA Member?*
              </label>
              <select
                id=""
                name="isKmaMember"
                readOnly
                value={formData?.isKmaMember}
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none
                   focus:outline-none focus:shadow-outline font-medium"
              >
                <option
                  value=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Select a category
                </option>
                <option
                  value={true}
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  KMA Member
                </option>
                <option
                  value={false}
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Non-KMA Member
                </option>
              </select>
            </div>
            <div
              className={`w-full space-y-2 ${formData?.isKmaMember ? "block" : "hidden"
                }`}
            >
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Membership Id*
              </label>
              <input
                type="text"
                name="membershipId"
                readOnly
                placeholder=""
                value={formData?.membershipId}
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Application Amount (with GST) :
              </label>
              <input
                type="text"
                name="amountWithGst"
                readOnly
                value={formData?.paymentDetails?.amountWithGst}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Payment Details :*
              </label>
              <select
                id=""
                name="mode"
                readOnly
                value={formData?.paymentDetails?.mode}
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none
                   focus:outline-none focus:shadow-outline font-medium"
              >
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Select a category
                </option>
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Cheque
                </option>
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  DD No.
                </option>
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Online
                </option>
              </select>
            </div>


          </div>

        </form>
      </div>

      <div className="flex w-full">
        <div className="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 mt-10 gap-10 md:px-10">
          {attachedData?.map((attachment, index) => (
            <div className="w-full lg:w-[200px]" key={index}>
              <h1 className="mb-3 text-lg text-center font-semibold font-sherif tracking-wider">
                Attachment {index + 1}
              </h1>
              <div className="overflow-hidden w-full">
                <img
                  src={
                    attachment.key.endsWith(".jpg") ||
                      attachment.key.endsWith(".JPG") ||
                      attachment.key.endsWith(".jpeg") ||
                      attachment.key.endsWith(".JPEG") ||
                      attachment.key.endsWith(".avif") ||
                      attachment.key.endsWith(".png")
                      ? attachment.location
                      : image
                  }
                  alt={attachment?.key}
                  className="object-contain md:h-[250px]"
                />
              </div>
              <a
                href={attachment.location}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="border w-full bg-red-600 text-white text-sm p-1">
                  Download
                </button>
              </a>
            </div>
          ))}
        </div>
      </div>

    </div>
  );
};

export default Csr;
