import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const CSRTable = ({ data, page }) => {
    const Navigate = useNavigate();

    return (
        <table className="w-full h-full max-w-[1200px] mx-auto text-sm text-left shadow-md md:rounded-lg overflow-x-scroll">
            <thead className="text-xs text-white uppercase bg ">
                <tr className="bg-red-500 border-b  ">
                    <th scope="row" className="px-6 py-4 font-bold  text-white">
                        No.
                    </th>
                    <th className="px-6 py-4">Organization</th>
                    <th className="px-6 py-4">Location</th>
                    <th className="px-6 py-4">Payment Mode</th>
                    <th className="px-6 py-4">Ownership</th>
                    <th className="px-6 py-4">Date & Time</th>
                    <th className="px-6 py-4">KMA</th>
                </tr>
            </thead>
            <tbody>
                {data.map((item, i) => (
                    <tr
                        key={i}
                        onClick={() => Navigate(`/admin/application/${item?.formName}/${item?._id}`)}
                        className="bg-white border-b  hover:bg-gray-50 text-black cursor-pointer "
                    >
                        <td className="px-6 py-4 ">{(page - 1) * 10 + i + 1}</td>
                        <td className="px-6 py-4 capitalize">{item?.organizationProfile?.name}</td>
                        <td className="px-6 py-4">{item?.organizationProfile?.corporateOfficeLocation}</td>
                        <td className="px-6 py-4">{item?.paymentDetails?.mode}</td>
                        <td className="px-6 py-4 capitalize text-ellipsis">{item?.organizationProfile?.ownership.split(",")[0]}</td>
                        <td className="px-6 py-4 capitalize truncate">{new Date(item?.createdAt).toLocaleString([])}</td>
                        <td className="px-6 py-4 capitalize">
                            {item?.isKmaMember ? "Yes" : "No"}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default CSRTable