import React, { useState } from 'react'
import { toast } from 'react-toastify';
import axios from 'axios';
import { awardBaseURL, awardUploadMulti } from '../../utils/EndPoint';
import { ClipLoader } from 'react-spinners';

const MultiFileInput = ({ className, label, name, setFiles }) => {
    const [uploading, setUploading] = useState(false);

    const uploadImage = async (files) => {
        setUploading(true);

        const formData = new FormData();

        files.forEach((file) => {
            formData.append('files', file);
        });

        try {
            const response = await axios.post(`${awardBaseURL}${awardUploadMulti}`, formData);

            if (response.status === 200) {
                const files = await response.data.files
                console.log({files})
                setFiles(files)
            }
            else {
                throw new Error(`Upload failed`);
            }

        } catch (err) {
           toast.error('Something went wrong during upload.');
        } finally {
            setUploading(false);
        }
    }

    const docChangeHandler = async (e) => {
        const { files } = e.target;
    
        const fileArray = Array.from(files);
    
        if (fileArray.length > 0) {
            if (fileArray.some(file => file.size > 25 * 1024 * 1024)) {
                toast.warn("Each file size should be less than 25 MB");
            } else {
                await uploadImage(fileArray);
            }
        }
    };
    

    return (
        <div
            className={className}
        >
            <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium flex items-center gap-4"
            >
                <span>{label}</span>
                {
                    uploading
                    &&
                    <ClipLoader size={8} color='red' />
                }
            </label>
            <input
                type="file"
                multiple
                id={name}
                name={name}
                onChange={docChangeHandler}
                placeholder=""
                disabled={uploading}
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
            />
        </div>
    )
}

export default MultiFileInput