import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { awardBaseURL, CreateCSR } from "../../../utils/EndPoint";
import Loading from "../../loadingPage/Loading";
import FileInput from "../FileInput";
import axios from "axios";
import CsrProjectReportModal from "../modals/CsrProjectReportModal";
import MultiFileInput from "../MultiFileInput";
import FileInputImg from "../FileInputImg";
import { validateForm4Data } from "../../../utils/validation/formValidation";

const kmaPaymentDetails = {
  NonKmaAmount: 7000,
  NonKmaWithGSTAmount: 8260,
  KMAAmount: 5000,
  KMAWithGSTAmount: 5900,
};

const initialData = {
  formName: "CSR",
  isKmaMember: false,
  membershipId: "",
  projectTheme: "",
  keyCriteria: "",

  organizationProfile: {
    name: "",
    corporateOfficeLocation: "",
    description: "",
    ownership: "",
    yearOfEstablishment: 0,
    annualTurnoverFY2023_24: "",
    totalEmployees: 0,
    reportingSystem: "",
    certifications: "",
  },

  csrPolicyAndPractice: {
    salientFeatures: "",
    csrCommittee: "",
    fundsAllocated: "",
    projectsFY2023_24: "",
    awarenessPrograms: "",
    auditSystem: "",
    csrReport: "",
    accolades: "",
  },

  csrProjectReport: {
    name: "",
    key: "",
    size: "",
    location: "",
  },

  attachments: {
    reportingSystemReport: {
      name: "",
      key: "",
      size: "",
      location: "",
    },

    certificates: [],

    csrProjectReport: {
      name: "",
      key: "",
      size: "",
      location: "",
    },
  },

  paymentDetails: {
    mode: "",
    amount: kmaPaymentDetails?.NonKmaAmount,
    amountWithGst: kmaPaymentDetails?.NonKmaWithGSTAmount,
    transactionId: "",
    muid: "",
    receipt: {
      name: "",
      key: "",
      location: "",
    },
  },
};

const Form4 = () => {
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState(initialData);
  const [agreed, setAgreed] = useState(false);
  const [formErrors, setFormErrors] = useState({}); // State to store form errors

  const [isModalVisible, setModalVisible] = useState(false);

  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);

  const inPutChangeHandler = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      let updatedData = { ...prev, [name]: value };

      if (name === "isKmaMember") {
        const isKma = value === "true";
        updatedData = {
          ...updatedData,
          [name]: isKma,
          paymentDetails: {
            ...prev.paymentDetails,
            amount: isKma
              ? kmaPaymentDetails?.KMAAmount
              : kmaPaymentDetails?.NonKmaAmount,
            amountWithGst: isKma
              ? kmaPaymentDetails?.KMAWithGSTAmount
              : kmaPaymentDetails?.NonKmaWithGSTAmount,
          },
        };
      }
      console.log(updatedData);
      return updatedData;
    });

    setFormErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[name]; // Removes the error message if field is corrected
      return newErrors;
    });
  };

  // basic information change handler
  const basicInputHandler = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updatedFormData = {
        ...prev,
        organizationProfile: {
          ...prev.organizationProfile,
          [name]:
            name === "totalEmployees" || name === "yearOfEstablishment"
              ? Number(value)
              : value,
        },
      };

      // Validate the specific field only
      const errors = validateForm4Data(updatedFormData);
      // If there's an error, show a toast notification
      if (errors[name]) {
        toast.error(errors[name]); // Show error toast
      }
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errors[name] || "", // Update only the current field's error
      }));

      return updatedFormData;
    });
  };

  const projectInputHandler = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      projectDetails: {
        ...prev.projectDetails,
        [name]: value,
      },
    }));
  };

  // Payment mode Update
  const paymentChangeHandler = (e) => {
    const modeValue = e.target.value;

    setFormData((prev) => {
      const updatedFormData = {
        ...prev,
        paymentDetails: {
          ...prev.paymentDetails,
          mode: modeValue, // Update the mode field
        },
      };

      // Validate the specific field only
      const errors = validateForm4Data(updatedFormData);
      if (errors.mode) {
        toast.error(errors.mode); // Show error toast for the mode field
      }
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        mode: errors.mode || "", // Update only the mode field's error
      }));

      return updatedFormData;
    });
  };

  const csrPolicyAndPracticeCH = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updatedFormData = {
        ...prev,
        csrPolicyAndPractice: {
          ...prev.csrPolicyAndPractice,
          [name]: value,
        },
      };
      console.log(updatedFormData);
      // Validate the specific field only
      const errors = validateForm4Data(updatedFormData);
      if (errors[name]) {
        toast.error(errors[name]); // Show error toast
      }

      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errors[name] || "", // Update only the current field's error
      }));

      return updatedFormData;
    });
  };

  const csrProjectReportCH = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      csrProjectReport: {
        ...prev.csrProjectReport,
        [name]: value,
      },
    }));
  };

  const backToBasics = () => {
    window.scrollTo(0, 0);
    setFormData(initialData);
    setAgreed(false);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    console.log(formData);
    const errors = validateForm4Data(formData);
    console.log(errors);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);

      // Show each error in a separate toast
      Object.values(errors).forEach((errorMsg) => {
        toast.error(errorMsg); // Display each error
      });

      return;
    }

    // Check required attachments separately (and use toast only here)
    // if (!formData?.attachments?.reportingSystemReport?.location) {
    //   return toast.error("Please Attach a copy of Reporting System report.");
    // }
    // if (!formData?.attachments?.csrProjectReport?.location) {
    //   return toast.error(
    //     "Please Attach a copy of CSR Project Report/Case Study."
    //   );
    // }

    try {
      setLoader(true);
      const response = await axios.post(
        `${awardBaseURL}${CreateCSR}`,
        formData
      );

      if (response?.data?.redirectInfo?.url) {
        window.location.href = response?.data?.redirectInfo?.url;
        toast.warning(
          "Please Wait for the Payment Gateway DON'T CLOSE ANYTHING"
        );
      } else {
        toast.success("Your Application Successfully Submitted");
      }
    } catch (error) {
      toast.error(error?.response?.data?.msg || "Try again after sometime");
    } finally {
      setLoader(false);
      backToBasics();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // console.log({ formData })

  return (
    <>
      <h1 className="font-bold font-sherif text-textColor text-2xl text-center mb-5 lg:mb-10">
        {" "}
        Best CSR Project Awards 2025
      </h1>
      <div className="flex items-start justify-center border rounded-xl shadow-xl w-full max-w-[1280px] mx-auto">
        <form
          className="w-full flex flex-col items-center justify-center md:px-20 p-5 md:p-10"
          action=""
          onSubmit={submitHandler}
        >
          {/* Division heading */}
          <div className="text-start w-full font-semibold mb-2">
            <h2>Basic Information</h2>
          </div>
          {/* Organization /adress */}

          <div className="w-full md:mt-3  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Full Name of the Applicant Organization:
              </label>
              <input
                type="text"
                name="name"
                required
                value={formData?.organizationProfile?.name}
                onChange={basicInputHandler}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.name ? "border-red-500" : "border-[#771D32]/40"
                }`}
              />
              {formErrors.name && (
                <p className="text-red-500 text-sm mt-1">{formErrors.name}</p>
              )}
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Corporate Office Location:
              </label>
              <input
                type="text"
                name="corporateOfficeLocation"
                value={formData?.organizationProfile?.corporateOfficeLocation}
                placeholder=""
                onChange={basicInputHandler}
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.corporateOfficeLocation
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.corporateOfficeLocation && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.corporateOfficeLocation}
                </p>
              )}
            </div>
          </div>

          <div className="w-full md:mt-3  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Brief Description of the Applicant Organization (in about 50
                words):
              </label>
              <input
                type="text"
                name="description"
                value={formData?.organizationProfile?.description}
                onChange={basicInputHandler}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.description
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.description && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.description}
                </p>
              )}
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Ownership:
              </label>
              <input
                type="text"
                name="ownership"
                value={formData?.organizationProfile?.ownership}
                placeholder=""
                onChange={basicInputHandler}
                className={`border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none ${
                  formErrors.ownership
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.ownership && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.ownership}
                </p>
              )}
            </div>
          </div>

          {/* contact & Email*/}
          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Year of Establishment of the Applicant Organization:
              </label>
              <input
                type="number"
                name="yearOfEstablishment"
                value={formData?.organizationProfile?.yearOfEstablishment}
                onChange={basicInputHandler}
                placeholder=""
                className={`border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none ${
                  formErrors.yearOfEstablishment
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.yearOfEstablishment && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.yearOfEstablishment}
                </p>
              )}
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Annual Turnover of FY 2023-24
              </label>
              <input
                type="text"
                name="annualTurnoverFY2023_24"
                placeholder=""
                value={formData?.organizationProfile?.annualTurnoverFY2023_24}
                onChange={basicInputHandler}
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.annualTurnoverFY2023_24
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.annualTurnoverFY2023_24 && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.annualTurnoverFY2023_24}
                </p>
              )}
            </div>
          </div>

          {/*Governing Body Details*/}
          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Total Number of employees of the Applicant Organization:
              </label>
              <input
                type="number"
                min={0}
                name="totalEmployees"
                value={formData?.organizationProfile?.totalEmployees}
                onChange={basicInputHandler}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.totalEmployees
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.totalEmployees && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.totalEmployees}
                </p>
              )}
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Has the Applicant Organization adopted any International
                Integrated Reporting System (IIRS)? Attach a copy of your latest
                report. In case IIRS is not being followed, then specify the
                reporting system you are following (Attach a copy).
              </label>
              <input
                type="text"
                name="reportingSystem"
                value={formData?.organizationProfile?.reportingSystem}
                onChange={basicInputHandler}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.reportingSystem
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.reportingSystem && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.reportingSystem}
                </p>
              )}
            </div>
          </div>

          {/* Brief Description of the Organization */}
          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Indicate if the applicant is certified to ISO 9001 / ISO 14001 /
                ISO 45001 / OHSAS 18001 / SA 8000 or others. Attach a copy of
                the certificate(s).
              </label>
              <input
                type="text"
                name="certifications"
                value={formData?.organizationProfile?.certifications}
                onChange={basicInputHandler}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.certifications
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.certifications && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.certifications}
                </p>
              )}
            </div>
          </div>

          {/* Division heading */}
          <div className="text-start w-full font-semibold my-5">
            <h2>CSR Policy and Practice</h2>
          </div>

          {/* Name of the Project / */}
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                What are the salient features of the Organization’s CSR Policy?
              </label>
              <input
                type="text"
                name="salientFeatures"
                onChange={csrPolicyAndPracticeCH}
                value={formData?.csrPolicyAndPractice?.salientFeatures}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.salientFeatures
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.salientFeatures && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.salientFeatures}
                </p>
              )}
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Is there a CSR committee? What is its composition, role, and
                responsibilities?
              </label>
              <input
                type="text"
                name="csrCommittee"
                onChange={csrPolicyAndPracticeCH}
                value={formData?.csrPolicyAndPractice?.csrCommittee}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.csrCommittee
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.csrCommittee && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.csrCommittee}
                </p>
              )}
            </div>
          </div>
          {/*Source of Funding: */}
          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Specify funds allocated for CSR activities (in absolute numbers,
                and as a percentage of net profit), during the last 4 years
                (year-wise).
              </label>
              <input
                type="text"
                name="fundsAllocated"
                value={formData?.csrPolicyAndPractice?.fundsAllocated}
                onChange={csrPolicyAndPracticeCH}
                placeholder="Select the appropriate category."
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.fundsAllocated
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.fundsAllocated && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.fundsAllocated}
                </p>
              )}
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                List the various CSR projects that were carried out during the
                FY 2023-24
              </label>
              <input
                type="text"
                name="projectsFY2023_24"
                value={formData?.csrPolicyAndPractice?.projectsFY2023_24}
                onChange={csrPolicyAndPracticeCH}
                placeholder="Select the appropriate category."
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.projectsFY2023_24
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.projectsFY2023_24 && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.projectsFY2023_24}
                </p>
              )}
            </div>
          </div>
          {/* Name of the Project / */}
          <div className="w-full flex flex-col md:flex-row gap-2 py-1 ">
            <div className="w-full flex flex-col space-y-2 justify-between">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                What awareness programs are in place to involve employees, their
                families, and community members, in respect of the
                Organization’s CSR projects.
              </label>
              <input
                type="text"
                name="awarenessPrograms"
                onChange={csrPolicyAndPracticeCH}
                value={formData?.csrPolicyAndPractice?.awarenessPrograms}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.awarenessPrograms
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.awarenessPrograms && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.awarenessPrograms}
                </p>
              )}
            </div>
            <div className="w-full flex flex-col space-y-2 justify-between">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Does the Organization’s internal audit system include regular
                audit of CSR programmes and performance? Provide details.
              </label>
              <input
                type="text"
                name="auditSystem"
                onChange={csrPolicyAndPracticeCH}
                value={formData?.csrPolicyAndPractice?.auditSystem}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.auditSystem
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.auditSystem && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.auditSystem}
                </p>
              )}
            </div>
          </div>

          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Is a CSR Report being published separately? If yes, enclose a
                copy of the latest CSR Annual Report.
              </label>
              <input
                type="text"
                name="csrReport"
                onChange={csrPolicyAndPracticeCH}
                value={formData?.csrPolicyAndPractice?.csrReport}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.csrReport
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.csrReport && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.csrReport}
                </p>
              )}
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Any accolades received by the Company for its CSR projects?
              </label>
              <input
                type="text"
                name="accolades"
                onChange={csrPolicyAndPracticeCH}
                value={formData?.csrPolicyAndPractice?.accolades}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.accolades
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.accolades && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.accolades}
                </p>
              )}
            </div>
          </div>

          <div className="text-start w-full font-semibold my-5 flex flex-col ">
            <h2>CSR Project Report/impact assessment study</h2>

            <div className="w-full space-y-2 my-2 ">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Choose the theme of your CSR project*
              </label>
              <select
                id=""
                name="projectTheme"
                value={formData?.projectTheme}
                onChange={inPutChangeHandler}
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none
                   focus:outline-none focus:shadow-outline font-medium "
              >
                <option
                  value=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Select a Theme
                </option>
                <option
                  value="Education"
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Education
                </option>
                <option
                  value="Health"
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Health
                </option>
                <option
                  value="Social Inclusion"
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Social Inclusion
                </option>
                <option
                  value="Environment"
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Environment
                </option>
              </select>
            </div>

            <p className="text-[#777777] text-[12px] md:text-[13px] font-medium">
              Describe the CSR project of the organization, that has had a
              perceptible impact on the community and contributed to societal
              development, pertaining to any one of the above stated CSR themes.
              In the context of the above project, prepare a impact
              assessment study, incorporating the following and any other
              details considered relevant.
              <span
                onClick={openModal}
                className="text-blue-500 text-[12px] md:text-[13px] font-medium
                            cursor-pointer "
              >
                {" "}
                Learn more about guidelines
              </span>
            </p>
            <FileInput
              className={`w-fit space-y-2 ${
                formData?.paymentDetails?.mode === "Online" ? "hidden" : "block"
              }`}
              label="Attach CSR Project Report/impact assessment study"
              name="csrProjectReport"
              setFile={(file) =>
                setFormData((prev) => ({
                  ...prev,
                  attachments: {
                    ...prev.attachments,
                    csrProjectReport: file,
                  },
                }))
              }
            />
            <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
              <div className="w-full space-y-2">
                <label
                  htmlFor=""
                  className="text-[#777777] text-[12px] md:text-[13px] font-medium"
                >
                  What key criteria does your organization use to select and
                  prioritize CSR projects and themes? Please include
                  considerations such as alignment with company values,
                  community needs, potential impact, sustainability, and
                  stakeholder engagement.
                </label>
                <input
                  type="text"
                  name="keyCriteria"
                  value={formData?.keyCriteria}
                  onChange={inPutChangeHandler}
                  placeholder=""
                  className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                    formErrors.keyCriteria
                      ? "border-red-500"
                      : "border-[#771D32]/40"
                  }`}
                />
                {formErrors.keyCriteria && (
                  <p className="text-red-500 text-sm mt-1">
                    {formErrors.keyCriteria}
                  </p>
                )}
              </div>
            </div>
          </div>

          {/* Division heading */}
          <div className="text-start w-full font-semibold my-5">
            <h2>Financial and Payment Details</h2>
          </div>

          {/*payment option */}
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                is KMA Member?*
              </label>
              <select
                id=""
                name="isKmaMember"
                value={formData?.isKmaMember}
                onChange={inPutChangeHandler}
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none
                   focus:outline-none focus:shadow-outline font-medium"
              >
                <option
                  value=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Select a category
                </option>
                <option
                  value={true}
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  KMA Member
                </option>
                <option
                  value={false}
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Non-KMA Member
                </option>
              </select>
            </div>
            <div
              className={`w-full space-y-2 ${
                formData?.isKmaMember ? "block" : "hidden"
              }`}
            >
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Membership Id*
              </label>
              <input
                type="text"
                name="membershipId"
                onChange={inPutChangeHandler}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Application Amount (with GST) :
              </label>
              <input
                type="text"
                name="amountWithGst"
                //
                readOnly
                value={formData?.paymentDetails?.amountWithGst}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.mountWithGst
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.mountWithGst && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.mountWithGst}
                </p>
              )}
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Payment Details :*
              </label>
              <select
                id=""
                name="mode"
                value={formData?.paymentDetails?.mode}
                onChange={paymentChangeHandler}
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none focus:outline-none focus:shadow-outline font-medium ${
                  formErrors.mode ? "border-red-500" : "border-[#771D32]/40"
                }`}
              >
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Select a category
                </option>
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Cheque
                </option>
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  DD No.
                </option>
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Online
                </option>
              </select>
              {formErrors.mode && (
                <p className="text-red-500 text-sm mt-1">{formErrors.mode}</p>
              )}
            </div>

            <FileInputImg
              className={`w-full space-y-2 ${
                formData?.paymentDetails?.mode === "Online" ? "hidden" : "block"
              }`}
              label="Payment Receipt (png or jpg)"
              name="receipt"
              allowedFileTypes={["image/jpeg", "image/jpg", "image/png"]}
              setFile={(file) =>
                setFormData((prev) => ({
                  ...prev,
                  paymentDetails: {
                    ...prev.paymentDetails,
                    receipt: file,
                  },
                }))
              }
            />
          </div>

          <div className="my-6 w-full">
            <span className="font-bold">Note : </span>
            <span className="font-">
              Please ensure all file fields are completed before submitting the
              form. Additionally, kindly ensure that each file is under 25MB in
              size.
            </span>
          </div>

          {/* Division heading */}
          <div className="text-start w-full font-semibold my-5">
            <h2>Required Attachments</h2>
          </div>

          {/*Document */}
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <FileInput
              name="reportingSystemReport"
              className={`w-full space-y-2`}
              label="Attach a copy of Reporting System report."
              setFile={(file) =>
                setFormData((prev) => ({
                  ...prev,
                  attachments: {
                    ...prev.attachments,
                    reportingSystemReport: file,
                  },
                }))
              }
            />

            <MultiFileInput
              name="certificates"
              className={`w-full space-y-2`}
              label="Attach a copy of the certificate(s)."
              setFiles={(files) =>
                setFormData((prev) => ({
                  ...prev,
                  attachments: {
                    ...prev.attachments,
                    certificates: files,
                  },
                }))
              }
            />
          </div>

          <div className="w-full flex flex-col md:flex-row gap-2 py-1 mt-5">
            <span className="text-xs md:text-sm ">
              <input
                type="checkbox"
                checked={agreed}
                onChange={(e) => setAgreed(e.target.checked)}
                className="me-2"
              />
              I agree, on behalf of my organization, to abide by the rules of
              the KMA – Advocacy Awards 2024 and accept that the decisions of
              KMA are final and binding. I confirm all information in this
              application and accompanying documents are correct. I accept the
              timelines and the fee mentioned.
            </span>
          </div>

          {/* Submit Button */}
          <div className="w-full flex justify-center mt-10">
            <button
              disabled={!agreed}
              className="flex items-center justify-end border p-2 px-5 rounded bg-red-500 text-white"
            >
              {formData?.paymentDetails?.mode === "Online"
                ? " Pay Now & Submit"
                : "Submit"}
            </button>
          </div>
        </form>
        {loader && <Loading />}

        {isModalVisible && (
          <CsrProjectReportModal
            isVisible={isModalVisible}
            onClose={closeModal}
          />
        )}
      </div>
    </>
  );
};

export default Form4;
