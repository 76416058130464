import React from "react";
import { useNavigate } from "react-router-dom";
import successTick from "../../assets/gif/comp_3.webp";

const Failure = () => {
  return (
    <div className="max-w-[1200px] h-[70vh] mx-auto w-full flex items-center justify-center">
      <div className="w-[80%] border p-5 rounded shadow-lg lg:w-[30%] pb-10 ">
        <div className="col-md-6 text-center flex flex-col items-center justify-center">
          <img
            src={successTick}
            alt="GifSuccessTick"
            className="rounded-full w-40 h-40 object-cover"
          />
          <div className="alert alert-success text-center">
            <h4 className="alert-heading text-lg font-semibold">
              Payment Failed.
            </h4>
          </div>
          <a
            href="https://www.kma.org.in/greenpalms"
            className="text-sm  mt-3 bg-red-600 text-white px-5 p-2 rounded"
          >
            Home
          </a>
        </div>
      </div>
    </div>
  );
};

export default Failure;
