import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/home/Home";
import Form from "./pages/form/Form";
import CsrForm from "./pages/csrAwards/Form";
import TeamsAndConditions from "./pages/extra/TeamsAndConditions";
import Contact from "./pages/extra/Contact";
import PrivacyAndPolicy from "./pages/extra/PrivacyAndPolicy";
import RefundPolicy from "./pages/extra/RefundPolicy";
import ShopDelivery from "./pages/extra/ShopDelivery";
import Success from "./components/status/Success";
import Failure from "./components/status/Failure";
import Admin from "./pages/admin/Admin";
import Table from "./components/admin/Table";
import "react-tooltip/dist/react-tooltip.css";
import Login from "./pages/admin/Login";
import AdminProtectedRoute from "./utils/AdminProtectedRoute";
import ApplicationSingleView from "./pages/admin/ApplicationSingleView";
import Application from "./pages/admin/Application";
import Guideline from "./pages/csrAwards/Guideline";
import GuidelineESG from "./pages/csrAwards/GuidelineESG";
import GuidelineNGO from "./pages/csrAwards/GuidelineNGO";
import CsrLayout from "./layouts/CsrLayout";
import Form1 from "./components/client/form/Form1";
import Form2 from "./components/client/form/Form2";
import Form3 from "./components/client/form/Form3";
import Form4 from "./components/client/form/Form4";
import AuthProtector from "./protectors/AuthProtector";

function App() {
  return (
    // bg-gradient-to-r from-[#9FA5D5]/50 to-[#E8F5C8]/50
    <div className="">
      <Router>
        <Routes>
          {/* <Route path='/' element={<Home />} /> */}
          <Route path="/registration" element={<Form />} />
          <Route path="/TeamsAndConditions" element={<TeamsAndConditions />} />
          <Route path="/ShopDelivery" element={<ShopDelivery />} />
          <Route path="/RefundPolicy" element={<RefundPolicy />} />
          <Route path="/PrivacyAndPolicy" element={<PrivacyAndPolicy />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/kma" element={<Admin />}>
            <Route exact path="admin/Kma_Admin-1234$" element={<Table />} />
          </Route>

          {/* CSR Registration part */}
          <Route element={<CsrLayout />}>
            <Route path="/kma-award-event/registration" element={<CsrForm />} />
            <Route
              path="/kma-impactful-ngo-award/registration"
              element={<Form1 />}
            />
            <Route
              path="/kma-sustainable-enterprise-award/registration"
              element={<Form2 />}
            />
            <Route
              path="/kma-sustainable-startup-award/registration"
              element={<Form3 />}
            />
            <Route
              path="/kma-best-csr-project-award/registration"
              element={<Form4 />}
            />

            <Route path="/guideline/CSR" element={<Guideline />} />
            <Route path="/guideline/ESG" element={<GuidelineESG />} />
            <Route path="/guideline/NGO" element={<GuidelineNGO />} />

            <Route path="/failure" element={<Failure />} />
            <Route path="/success" element={<Success />} />
          </Route>
        </Routes>

        {/* Admin */}
        <Routes>
          <Route path="/admin" element={<AuthProtector />}>
            <Route path="login" element={<Login />} />
          </Route>

          <Route element={<AdminProtectedRoute />}>
            <Route path="/admin/application" element={<Application />} />
            <Route path="/admin/application/:formName/:id" element={<ApplicationSingleView />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
