import React from "react";
import successTick from "../../assets/gif/success1.gif";

const Success = () => {
  return (
    <div className="max-w-[1200px] h-[70vh] mx-auto w-full flex items-center justify-center">
      <div className="w-[80%] border p-5 rounded shadow-lg lg:w-[30%] pb-10">
        <div className="col-md-6 text-center flex flex-col items-center justify-center">
          <img src={successTick} alt="GifSuccessTick" className="rounded-full w-40 h-40 object-cover"/>
          <div className="alert alert-success text-center">
            <h4 className="alert-heading text-lg font-semibold">Payment Successfull</h4>
          </div>
          <a
            href="https://www.kma.org.in/greenpalms"
            className="text-sm  mt-3 bg-green-600 text-white px-5 p-2 rounded"
          >
            Home
          </a>
        </div>
      </div>
    </div>
  );
};

export default Success;
