import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';


const AuthProtector = () => {
    const user = useSelector((state) => state.auth.userInfo)

    return (
      user?.role === "admin"
        ? <Navigate to="/admin/application" replace />
        : <Outlet />
    )
}

export default AuthProtector