import React from 'react'

const TimeInputDropdown = ({years, setYears, months, setMonths, days, setDays}) => {
  
    const handleYearsChange = (e) => setYears(Number(e.target.value));
    const handleMonthsChange = (e) => setMonths(Number(e.target.value));
    const handleDaysChange = (e) => setDays(Number(e.target.value));
  
    return (
      <div style={{ display: "flex", gap: "10px" }}>
        <select value={years} onChange={handleYearsChange}
        className='border border-[#771D32]/40  md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none'
        >
          <option value={0}>Years</option>
          {[...Array(101).keys()].map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
  
        <select value={months} onChange={handleMonthsChange}
        className='border border-[#771D32]/40  md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none'
        >
          <option value={0}>Months</option>
          {[...Array(12).keys()].map((month) => (
            <option key={month + 1} value={month + 1}>
              {month + 1}
            </option>
          ))}
        </select>
  
        <select value={days} onChange={handleDaysChange}
        className='border border-[#771D32]/40  md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none'
        >
          <option value={0}>Days</option>
          {[...Array(32).keys()].map((day) => (
            <option key={day } value={day}>
              {day }
            </option>
          ))}
        </select>
  
      </div>
    );
}

export default TimeInputDropdown