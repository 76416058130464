import React from "react";
import TimeInputDropdown from "./TimeInputDropdown";
import InputTable from "./InputTable";

const ProjectDetail = ({
  index,
  item,
  removeProjectDetail,
  formData,
  setFormData,
}) => {
  const setThePD = (field, name, value) => {
    const pdArray = [...formData?.projectDetails];

    const mappedArr = pdArray.map((elem) => {
      if (elem?.id === item?.id) {
        const updatedElem = { ...elem };

        if (field) {
          if (updatedElem?.[field]) {
            updatedElem[field] = {
              ...updatedElem[field],
              [name]: value,
            };
          } else {
            updatedElem[field] = { [name]: value };
          }
        } else {
          updatedElem[name] = value;
        }

        return updatedElem;
      }
      return elem;
    });

    setFormData((prev) => ({
      ...prev,
      projectDetails: mappedArr,
    }));
    console.log(mappedArr)
  };

  return (
    <>
      <div className="text-start w-full font-semibold my-5 flex gap-5 items-center ">
        <h2>Project : {index + 1}</h2>
        {index > 0 && (
          <button
            type="button"
            onClick={() => removeProjectDetail(item.id)}
            className="bg-red-500 text-white p-2 text-xs rounded-md"
          >
            Remove
          </button>
        )}
      </div>

      {/* Name of the Project */}
      <div className="w-full flex flex-col md:flex-row gap-2 py-1">
        <div className="w-full space-y-2">
          <label className="text-[#777777] text-[12px] md:text-[13px] font-medium">
            Name of the Project
          </label>
          <input
            type="text"
            name="name"
            placeholder=""
            value={item?.name || ""}
            onChange={(e) => setThePD("", e.target.name, e.target.value)}
            className="border border-[#771D32]/40 w-full p-2 text-gray-700 text-[10px] md:text-xs rounded"
          />
        </div>
        <div className="w-full space-y-2">
          <label className="text-[#777777] text-[12px] md:text-[13px] font-medium">
            Key Theme or Focus Area
          </label>
          <input
            type="text"
            name="keyTheme"
            placeholder="Health, Education, Rural Development, Environment Protection"
            value={item?.keyTheme || ""}
            onChange={(e) => setThePD("", e.target.name, e.target.value)}
            className="border border-[#771D32]/40 w-full p-2 text-gray-700 text-[10px] md:text-xs rounded"
          />
        </div>
      </div>

      {/* Source of Funding */}
      <div className="w-full flex flex-col md:flex-row gap-2 py-1">
        <div className="w-full space-y-2">
          <label className="text-[#777777] text-[12px] md:text-[13px] font-medium">
            What are the diverse sources of funding? :
          </label>
          <input
            type="text"
            name="sourceOfFunding"
            placeholder=""
            value={item?.sourceOfFunding || ""}
            onChange={(e) => setThePD("", e.target.name, e.target.value)}
            className="border border-[#771D32]/40 w-full p-2 text-gray-700 text-[10px] md:text-xs rounded"
          />
        </div>
        <div className="w-full space-y-2">
          <label className="text-[#777777] text-[12px] md:text-[13px] font-medium">
            Project Location :
          </label>
          <input
            type="text"
            name="location"
            placeholder=""
            value={item?.location || ""}
            onChange={(e) => setThePD("", e.target.name, e.target.value)}
            className="border border-[#771D32]/40 w-full p-2 text-gray-700 text-[10px] md:text-xs rounded"
          />
        </div>
      </div>

      {/* Start Date and End Date */}
      <div className="w-full flex flex-col md:flex-row gap-2 py-1">
        <div className="w-full space-y-2">
          <label className="text-[#777777] text-[12px] md:text-[13px] font-medium">
            Project Start Date (FY) and End Date (FY):
          </label>
          <div className="flex gap-2">
            <input
              type="date"
              name="startDate"
              value={item?.startDate || ""}
              onChange={(e) => setThePD("", e.target.name, e.target.value)}
              className="border border-[#771D32]/40 w-full p-2 text-gray-700 text-[10px] md:text-xs rounded"
            />
            <input
              type="date"
              name="endDate"
              value={item?.endDate || ""}
              onChange={(e) => setThePD("", e.target.name, e.target.value)}
              className="border border-[#771D32]/40 w-full p-2 text-gray-700 text-[10px] md:text-xs rounded"
            />
          </div>
        </div>

        {/* Total Duration */}
        <div className="w-full space-y-2">
          <label className="text-[#777777] text-[12px] md:text-[13px] font-medium">
            Total Duration of the Project:
          </label>
          <TimeInputDropdown
            years={item?.duration?.years}
            months={item?.duration?.months}
            days={item?.duration?.days}
            setYears={(val) => setThePD("duration", "years", val)}
            setMonths={(val) => setThePD("duration", "months", val)}
            setDays={(val) => setThePD("duration", "days", val)}
          />
        </div>
      </div>

      {/* Danger Zone */}

      <div className="w-full flex flex-col md:flex-row gap-2 py-1">
        <div className="w-full space-y-2">
          <label
            htmlFor=""
            className="text-[#777777] text-[12px] md:text-[13px] font-medium"
          >
            Were the Need Assessment and Baseline studies related to the Project
            / Programme conducted? If yes, are the reports available?
          </label>
          <input
            type="text"
            name="needBaselineStudies"
            placeholder=""
            value={item?.needBaselineStudies || ""}
            onChange={(e) => setThePD("", e.target.name, e.target.value)}
            className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
          />
        </div>
      </div>

      <div className="w-full flex flex-col md:flex-row gap-2 py-1">
        <div className="w-full space-y-2">
          <label
            htmlFor=""
            className="text-[#777777] text-[12px] md:text-[13px] font-medium"
          >
            Total Amount Spent (Breakdown by FYs 2020-21, 2021-22, 2022-23,
            2023-24):
          </label>

          <InputTable
            formData={item?.totalAmountSpent}
            handleChange={(e) => {
              const { name, value } = e.target;
              setThePD("totalAmountSpent", name, value);
            }}
          />
        </div>
      </div>

      <div className="w-full flex flex-col md:flex-row gap-2 py-1">
        <div className="w-full space-y-2">
          <label
            htmlFor=""
            className="text-[#777777] text-[12px] md:text-[13px] font-medium"
          >
            Major Concerns Addressed by the Project:
          </label>
          <input
            type="text"
            name="concernsAddressed"
            placeholder=""
            value={item?.concernsAddressed || ""}
            onChange={(e) => setThePD("", e.target.name, e.target.value)}
            className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
          />
        </div>

        <div className="w-full space-y-2">
          <label
            htmlFor=""
            className="text-[#777777] text-[12px] md:text-[13px] font-medium"
          >
            How are the objectives of the Project aligned to national and global
            developmental priorities?
          </label>
          <input
            type="text"
            name="objectiveAlignment"
            placeholder=""
            value={item?.objectiveAlignment || ""}
            onChange={(e) => setThePD("", e.target.name, e.target.value)}
            className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
          />
        </div>
      </div>

      {/*  */}
      <div className="w-full flex flex-col md:flex-row gap-2 py-1">
        <div className="w-full space-y-2">
          <label
            htmlFor=""
            className="text-[#777777] text-[12px] md:text-[13px] font-medium"
          >
            Diverse Stakeholders and Beneficiaries (List Key Groups):
          </label>
          <input
            type="text"
            name="stakeholders"
            placeholder=""
            value={item?.stakeholders || ""}
            onChange={(e) => setThePD("", e.target.name, e.target.value)}
            className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
          />
        </div>
        <div className="w-full space-y-2">
          <label
            htmlFor=""
            className="text-[#777777] text-[12px] md:text-[13px] font-medium"
          >
            Total Number of Beneficiaries:
          </label>
          <input
            type="number"
            name="totalBeneficiaries"
            placeholder=""
            value={item?.totalBeneficiaries || ""}
            onChange={(e) => setThePD("", e.target.name, e.target.value)}
            className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
          />
        </div>
      </div>
      {/*  */}
      <div className="w-full flex flex-col md:flex-row gap-2 py-1">
        <div className="w-full space-y-2">
          <label
            htmlFor=""
            className="text-[#777777] text-[12px] md:text-[13px] font-medium"
          >
            Any Innovative Methods Used in Project Design or Execution?
          </label>
          <input
            type="text"
            name="innovativeMethods"
            placeholder=""
            value={item?.innovativeMethods || ""}
            onChange={(e) => setThePD("", e.target.name, e.target.value)}
            className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
          />
        </div>
        <div className="w-full space-y-2">
          <label
            htmlFor=""
            className="text-[#777777] text-[12px] md:text-[13px] font-medium"
          >
            How was the Project monitored by the donor organisation during
            implementation?
          </label>
          <input
            type="text"
            name="donorMonitor"
            placeholder=""
            value={item?.donorMonitor || ""}
            onChange={(e) => setThePD("", e.target.name, e.target.value)}
            className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
          />
        </div>
      </div>

      <div className="w-full flex flex-col md:flex-row gap-2 py-1">
        <div className="w-full space-y-2">
          <label
            htmlFor=""
            className="text-[#777777] text-[12px] md:text-[13px] font-medium"
          >
            Qualitative and Quantitative Impact of the Project:
          </label>
          <input
            type="text"
            name="impact"
            placeholder=""
            value={item?.impact || ""}
            onChange={(e) => setThePD("", e.target.name, e.target.value)}
            className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
          />
        </div>
      </div>

      {/*  */}
      <div className="w-full flex flex-col md:flex-row gap-2 py-1">
        <div className="w-full space-y-2">
          <label
            htmlFor=""
            className="text-[#777777] text-[12px] md:text-[13px] font-medium"
          >
            Sustainability Strategy of the Project:
          </label>
          <input
            type="text"
            name="sustainabilityStrategy"
            placeholder=""
            value={item?.sustainabilityStrategy || ""}
            onChange={(e) => setThePD("", e.target.name, e.target.value)}
            className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
          />
        </div>
        <div className="w-full space-y-2">
          <label
            htmlFor=""
            className="text-[#777777] text-[12px] md:text-[13px] font-medium"
          >
            Probability of Replicability of the Project:
          </label>
          <input
            type="text"
            name="replicabilityProbability"
            placeholder=""
            value={item?.replicabilityProbability || ""}
            onChange={(e) => setThePD("", e.target.name, e.target.value)}
            className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
          />
        </div>
      </div>
      <div className="w-full flex flex-col md:flex-row gap-2 py-1">
        <div className="w-full space-y-2">
          <label
            htmlFor=""
            className="text-[#777777] text-[12px] md:text-[13px] font-medium"
          >
            Can you provide specific examples of how your organization has
            successfully scaled its programs or services? Please describe a
            project or initiative that expanded its reach or impact, and include
            details on the strategies, resources, and partnerships that
            contributed to this growth.
          </label>
          <input
            type="text"
            name="scaledExamples"
            placeholder=""
            value={item?.scaledExamples || ""}
            onChange={(e) => setThePD("", e.target.name, e.target.value)}
            className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
          />
        </div>
        <div className="w-full space-y-2">
          <label
            htmlFor=""
            className="text-[#777777] text-[12px] md:text-[13px] font-medium"
          >
            How does your organization generate funds independently? Please
            share successful fundraising campaigns, revenue programs,
            partnerships, or other innovative strategies for financial
            sustainability.
          </label>
          <input
            type="text"
            name="fundGenerations"
            placeholder=""
            value={item?.fundGenerations || ""}
            onChange={(e) => setThePD("", e.target.name, e.target.value)}
            className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
          />
        </div>
      </div>
      {/*  */}
      <div className="w-full flex flex-col md:flex-row gap-2 py-1">
        <div className="w-full flex flex-col md:flex-row gap-2 py-1">
          <div className="w-full space-y-2">
            <label
              htmlFor=""
              className="text-[#777777] text-[12px] md:text-[13px] font-medium"
            >
              Was an Impact Assessment Conducted?
            </label>
            <div className="flex gap-2 items-center">
              <label
                htmlFor={`${item?.id}Yes`}
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Yes
              </label>
              <input
                type="radio"
                id={`${item?.id}Yes`}
                name={`${item?.id}`}
                value={true}
                onChange={(e) => {
                  if (e.target.checked === true) {
                    setThePD("impactAssessment", "conducted", true);
                  }
                }}
                checked={item?.impactAssessment?.conducted === true}
                className="border border-[#771D32]/40 w-fit md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded placeholder:text-xs ps-2 outline-none"
              />
              <label
                htmlFor={`${item?.id}No`}
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                No
              </label>
              <input
                type="radio"
                id={`${item?.id}No`}
                name={`${item?.id}`}
                value={false}
                onChange={(e) => {
                  if (e.target.checked === true) {
                    setThePD("impactAssessment", "conducted", false);
                  }
                }}
                checked={item?.impactAssessment?.conducted === false}
                className="border border-[#771D32]/40 w-fit md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          <div
            className={`w-full space-y-2 ${item?.impactAssessment?.conducted ? "block" : "hidden"
              }`}
          >
            <label
              htmlFor=""
              className="text-[#777777] text-[12px] md:text-[13px] font-medium"
            >
              Conducted by*
            </label>
            <select
              id=""
              name="by"
              onChange={(e) =>
                setThePD("impactAssessment", "by", e.target.value)
              }
              className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none
   focus:outline-none focus:shadow-outline font-medium"
            >
              <option
                value=""
                className="text-xs ring-0 outline-none focus:outline-none"
              >
                Select a category
              </option>
              <option
                value="Internal Resources"
                className="text-xs ring-0 outline-none focus:outline-none"
              >
                Internal Resources
              </option>
              <option
                value="Third-Party Agency"
                className="text-xs ring-0 outline-none focus:outline-none"
              >
                Third-Party Agency
              </option>
            </select>
          </div>
          <div
            className={`w-full space-y-2 ${item?.impactAssessment?.conducted &&
                item?.impactAssessment?.by === "Third-Party Agency"
                ? "block"
                : "hidden"
              }`}
          >
            <label
              htmlFor=""
              className="text-[#777777] text-[12px] md:text-[13px] font-medium"
            >
              Third-Party Agency *
            </label>
            <input
              type="text"
              name="thirdPartyAgencyName"
              placeholder="Specify Name"
              onChange={(e) =>
                setThePD(
                  "impactAssessment",
                  "thirdPartyAgencyName",
                  e.target.value
                )
              }
              className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectDetail;
