// export const baseURL = "http://localhost:4000/"
export const baseURL = "https://server.kma.qmarkdesk.com/"
export const awardBaseURL = "https://kmacsrregistration-axd6c5ffh3hmfbd9.southindia-01.azurewebsites.net"
// export const awardBaseURL = "http://localhost:8080"

// from csr project file
// export const baseURl = "https://server.kmaaward.qmarkdesk.com/"

export const FormSubmit = "/registration/amount/session/"
export const phonepe = "/registration/initiate/payment/"
export const admin = "/registration/admin/get/details/"
// export const FormSubmit = "/register-form"


// CSR Awards API
export const CreateCSR = "/api/application/create-csr";
export const CreateNGO = "/api/application/create-ngo";
export const CreateSE = "/api/application/create-se";
export const CreateSS = "/api/application/create-ss";

export const awardUpload = "/api/uploads/single";
export const awardUploadMulti = "/api/uploads/multiple";

export const CreateESG = "/api/application/create-esg";

export const getAllApplns = '/api/application/get-all';
export const getAllNgoApplns = '/api/application/ngo-get-all';
export const getAllCsrApplns = '/api/application/csr-get-all';
export const getAllSeApplns = '/api/application/se-get-all';
export const getAllSsApplns = '/api/application/ss-get-all';

export const getSingleAppln = '/api/application/get-single'

// Login
export const LoginAPI = "/api/auth/login"
export const refreshTokenRoute = '/api/auth/refresh-token'

// Get All Application
export const ApplicationAPI = "/api/application/get-all"

//Get A Single Application
export const GetOneApplicationAPI = "/api/application/get-single/"