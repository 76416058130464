import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const GuidelineNGO = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="max-w-[1280px] mx-auto w-full min-h-screen ">
      <div className="  mt-20 md:mt-40 p-5 md:p-0">
        <h1 className="font-bold text-textColor font-sherif text-[40px]">
          GUIDELINES
        </h1>
      </div>

      {/* GUIDELINES OF THE APPLICATION FOR KMA – IMPACTFUL NGO AWARDS 2024 */}
      <div className="md:mt-16 p-5 md:p-0">
        <h1 className="mb-3 font-semibold text-lg">
          GUIDELINES OF THE APPLICATION FOR KMA – IMPACTFUL NGO AWARDS 2024
        </h1>

        <h1 className="mb-3 font-semibold">
          For the Special Attention of Applicants:
        </h1>

        <span className="border-b border-black pb-1 font-semibold">
          Instructions:{" "}
        </span>

        <ol style={{ listStyleType: "number" }} className="px-4 my-5">
          <li>
            Projects commenced in 2019-20 or afterwards and still continuing in
            2023-24 will also be considered. (The entries shall be of Long term
            projects commenced in 2019-20 or afterwards and completed by 2022-23
            (Max.4 years) and short term projects, commenced and completed in FY
            2022-23)
          </li>
          <li>
            If any particular question is not applicable or relevant to your
            organization, please answer it as “Not Applicable”(NA)). If answer
            to any particular question cannot be furnished due to
            confidentiality or other reasons, please answer it as “Confidential/
            No Comments”.
          </li>
        </ol>

        <span className="border-b border-black pb-1 font-semibold">
          I. Profile of the Organization:
        </span>

        <ol style={{ listStyleType: "number" }} className="px-4 my-5">
          <li>Full Name of the Applicant Organization:</li>
          <li>Corporate Office Location:</li>
          <li>
            Brief Description of the Applicant Organization (in about 50 words):
          </li>
          <li>Year of Establishment of the Applicant Organization: </li>
          <li>
            Legal status of the organization (Registration details) - (Society,
            Trust, Section 8 company, etc.):
          </li>
          <li>Details of the Governing Body: </li>
          <li>
            Thematic Areas of Intervention (e.g. Education, Health Care, Skills
            Upgradation, women empowerment, etc.):
          </li>
          <li>
            {" "}
            Geographies in which the organization is active (State and
            Districts):
          </li>
        </ol>

        <span className="border-b border-black pb-1 font-semibold">
          II. Details of the Nominated Project(s)- If more than one Project is
          being submitted, please provide details of each project in the
          following format
        </span>

        <ol style={{ listStyleType: "number" }} className="px-4 my-5">
          <li> Name of the Project:</li>
          <li>
            Key theme/central focus of the Project (Health / Education / Skills
            Development / Rural Development / Environment Protection / Women
            Empowerment / Empowerment of vulnerable sections of society/
            promotion of sports/ welfare of armed force personnel/ sustainable
            agriculture/rights realization/ income generation/ rural enterprise
            development / others):
          </li>
          <li>Source of funding for the Project:</li>
          <li> Project Location:</li>
          <li> Project Start Date: FY ------ End Date: FY -------</li>
          <li> Duration of the Project: ------- Years --------- Months</li>
          <li>
            Amount spent for the Project: (a) FY 2019-20: (b) FY 2020-21: (c) FY
            2021-22: (d) FY 2022-23: (e) Total:
          </li>
          <li>
            Were the Need Assessment and Baseline studies related to the Project
            / Programme conducted? If yes, are the reports available?:{" "}
          </li>
          <li>What are the major concerns the Projects sought to address?</li>
          <li>
            How are the objectives of the Project aligned to national and global
            developmental priorities?
          </li>
          <li>Who are the Stakeholders and Beneficiaries of the Project?</li>
          <li>Number of beneficiaries of the Project:</li>
          <li>
            Any innovative method adopted in designing / execution of the
            Project:
          </li>
          <li>
            How was the Project monitored by the donor organisation during
            implementation?
          </li>
          <li>
            Impact (both qualitative and quantitative) of the intervention:
          </li>
          <li>
            Whether Impact Assessment of the Project conducted: (Yes / No): If
            yes, has it been conducted by: - (A) Deploying internal resources
            (B) If through third party agency, then the name of third-party
            agency (C) Attach a copy of impact assessment report.
          </li>
          <li>What is the sustainability strategy of the Project?</li>
          <li>What is the probability of replicability of the Project?</li>
        </ol>
      </div>

      <div className="w-full flex justify-start mt-10 p-5 md:p-0">
        <Link
          to={"/registration"}
          className="flex items-center justify-start border p-2 px-5 rounded bg-red-500 text-white"
        >
          Apply Now
        </Link>
      </div>
    </div>
  );
};

export default GuidelineNGO;
