import axios from "axios";
import { baseURL, awardBaseURL } from "./EndPoint";

const axiosInstance = axios.create({
     baseURL: baseURL,
     withCredentials: true
});

export const axiosPrivate = axios.create({
    baseURL: awardBaseURL,
    withCredentials: true
});

export default axiosInstance;