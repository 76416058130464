import { useEffect, useState } from "react";
import { awardBaseURL, CreateSE } from "../../../utils/EndPoint";
import { toast } from "react-toastify";
import Loading from "../../loadingPage/Loading";
import axios from "axios";
import FileInput from "../FileInput";
import MultiFileInput from "../MultiFileInput";
import FileInputImg from "../FileInputImg";
import { validateFormData } from "../../../utils/validation/formValidation";

const Form2 = () => {
  const [loader, setLoader] = useState(false);
  const [agreed, setAgreed] = useState(false);

  const kmaPaymentDetails = {
    NonKmaAmount: 9000,
    NonKmaWithGSTAmount: 9000 + 9000 * (18 / 100),
    KMAAmount: 7500,
    KMAWithGSTAmount: 7500 + 7500 * (18 / 100),
  };

  const initialData = {
    formName: "SE",
    isKmaMember: false,
    membershipId: "",
    parityMeasures: "",

    organizationProfile: {
      name: "",
      corporateOfficeLocation: "",
      briefDescription: "",
      ownershipDetails: "",
      yearOfEstablishment: 0,
      annualTurnoverFY2023_24: "",
      marketCapitalization: "",
      totalEmployees: 0,
    },

    sustainabilityGovernance: {
      integrationOfResponsibleBusiness: "",
      esgTopics: "",
      stakeholderEngagement: "",
      seniorManagementResponsibilities: "",
      sustainabilityMeasurements: "",
      boardResponsibilities: "",
      complianceProcess: "",
    },

    sustainabilityIndicators: {
      ghgFootprint: "",
      waterFootprint: "",
      energyFootprint: "",
      wasteManagement: "",
      employeeWellBeing: "",
      genderDiversity: "",
      inclusiveDevelopment: "",
      customerEngagement: "",
      openness: "",
    },

    sustainabilityFrameworks: {
      adoptedFrameworks: "",
      isoStandards: "",
      officeStandards: "",
      thirdPartyAssurance: "",
      innovativePractices: "",
      awarenessInitiatives: "",
      collaborativeInitiatives: "",
      significantAchievements: "",
      publicCommitments: "",
    },

    attachments: {
      organizationProfile: {
        name: "",
        key: "",
        size: "",
        location: "",
      },
      sustainabilityReports: {
        name: "",
        key: "",
        size: "",
        location: "",
      },
      thirdPartyAssuranceReport: {
        name: "",
        key: "",
        size: "",
        location: "",
      },
      supportingDocuments: [],
    },

    contactDetails: {
      organizationName: "",
      address: "",
      contactPerson: "",
      mobileNumber: "",
      emailAddress: "",
      website: "",
    },

    paymentDetails: {
      mode: "",
      amount: kmaPaymentDetails?.NonKmaAmount,
      amountWithGst: kmaPaymentDetails?.NonKmaWithGSTAmount,
      transactionId: "",
      muid: "",
      receipt: {
        name: "",
        key: "",
        location: "",
      },
    },
  };

  const [formData, setFormData] = useState(initialData);
  const [formErrors, setFormErrors] = useState({}); // State to store form errors

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // input change handlers
  const baseCH = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const organizationProfileCH = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updatedData = {
        ...prev,
        organizationProfile: {
          ...prev.organizationProfile,
          [name]:
            name === "totalEmployees" || name === "yearOfEstablishment"
              ? Number(value)
              : value,
        },
      };

      console.log(updatedData);

      // Validate the specific field only
      const errors = validateFormData(updatedData);
      if (errors[name]) {
        toast.error(errors[name]); // Show error toast
      }

      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errors[name] || "", // Update only the current field's error
      }));

      return updatedData;
    });
  };

  const sustainabilityGovernanceCH = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updatedFormData = {
        ...prev,
        sustainabilityGovernance: {
          ...prev.sustainabilityGovernance,
          [name]: value,
        },
      };
      // Validate the specific field only
      const errors = validateFormData(updatedFormData);
      if (errors[name]) {
        toast.error(errors[name]); // Show error toast
      }
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errors[name] || "", // Update only the current field's error
      }));

      return updatedFormData;
    });
  };

  const sustainabilityIndicatorsCH = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updatedFormData = {
        ...prev,
        sustainabilityIndicators: {
          ...prev.sustainabilityIndicators,
          [name]: value,
        },
      };
      // Validate the specific field only
      const errors = validateFormData(updatedFormData);
      if (errors[name]) {
        toast.error(errors[name]); // Show error toast
      }
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errors[name] || "", // Update only the current field's error
      }));

      return updatedFormData;
    });
  };

  const sustainabilityFrameworksCH = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updatedFormData = {
        ...prev,
        sustainabilityFrameworks: {
          ...prev.sustainabilityFrameworks,
          [name]: value,
        },
      };
      // Validate the specific field only
      const errors = validateFormData(updatedFormData);
      if (errors[name]) {
        toast.error(errors[name]); // Show error toast
      }
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errors[name] || "", // Update only the current field's error
      }));

      return updatedFormData;
    });
  };

  const contactDetailsCH = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updatedFormData = {
        ...prev,
        contactDetails: {
          ...prev.contactDetails,
          [name]: value,
        },
      };
      // Validate the specific field only
      const errors = validateFormData(updatedFormData);
      if (errors[name]) {
        toast.error(errors[name]); // Show error toast
      }
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errors[name] || "", // Update only the current field's error
      }));

      return updatedFormData;
    });
  };

  const paymentDetailsCH = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      paymentDetails: {
        ...prev.paymentDetails,
        [name]: value,
      },
    }));
  };

  // is Check the member from kma or not
  const isKmaMemberHandler = (e) => {
    const kmaValue = e.target.value;
    if (kmaValue === "KMA Member") {
      setFormData((prev) => ({
        ...prev,
        kma_member: true,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        kma_member: false,
      }));
    }
  };

  const inPutChangeHandler = (e) => {
    const { name, value } = e.target;

    if (name === "isKmaMember") {
      setFormData((prev) => ({
        ...prev,
        [name]: value === "true",
      }));

      // Application Amount auto inputting depending on the kma or non kma
      if (value === "true") {
        setFormData((prev) => ({
          ...prev,
          paymentDetails: {
            ...prev.paymentDetails,
            amount: kmaPaymentDetails?.KMAAmount,
            amountWithGst: kmaPaymentDetails?.KMAWithGSTAmount,
          },
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          paymentDetails: {
            ...prev.paymentDetails,
            amount: kmaPaymentDetails?.NonKmaAmount,
            amountWithGst: kmaPaymentDetails?.NonKmaWithGSTAmount,
          },
        }));
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    console.log(formData);
  };

  // Payment mode Update
  const paymentChangeHandler = (e) => {
    const modeValue = e.target.value;

    setFormData((prev) => {
      const updatedFormData = {
        ...prev,
        paymentDetails: {
          ...prev.paymentDetails,
          mode: modeValue, // Update the mode field
        },
      };

      // Validate the specific field only
      const errors = validateFormData(updatedFormData);
      if (errors.mode) {
        toast.error(errors.mode); // Show error toast for the mode field
      }
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        mode: errors.mode || "", // Update only the mode field's error
      }));

      return updatedFormData;
    });
  };

  const backToBasics = () => {
    window.scrollTo(0, 0);
    setFormData(initialData);
    setAgreed(false);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const errors = validateFormData(formData);
    console.log(errors);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);

      // Show each error in a separate toast
      Object.values(errors).forEach((errorMsg) => {
        toast.error(errorMsg); // Display each error
      });

      return;
    }
    // Validate organization profile attachment
    // if (!formData?.attachments?.organizationProfile?.location) {
    //   toast.error("Please upload Profile of the Organization (PDF/Doc)");
    //   return;
    // } else if (!formData?.attachments?.sustainabilityReports?.location) {
    //   toast.error("Please upload Sustainability Reports (PDF/Doc)");
    //   return;
    // }

    try {
      setLoader(true);
      const response = await axios.post(`${awardBaseURL}${CreateSE}`, formData);

      console.log(response);
      if (response?.data?.redirectInfo?.url) {
        window.location.href = response?.data?.redirectInfo?.url;
        toast.warning(
          "Please Wait for the Payment Gateway DON'T CLOSE ANYTHING"
        );
        window.scrollTo(0, 0);
        setFormData(initialData);
      } else {
        toast.success("Your Application Successfully Submitted");
        window.scrollTo(0, 0);

        setFormData(initialData);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.msg || "Try again after sometime");
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <h1 className="font-bold font-sherif text-textColor text-2xl text-center mb-5 lg:mb-10">
        Sustainable Enterprise Award - Large and Medium 2025
      </h1>
      <div className="flex items-start justify-center border rounded-xl shadow-xl w-full max-w-[1280px] mx-auto">
        <form
          className="w-full flex flex-col items-center justify-center md:px-20 p-5 md:p-10"
          action=""
          onSubmit={submitHandler}
        >
          {/* Division heading */}
          <div className="text-start w-full font-semibold mb-5">
            <h2>Organization Profile</h2>
          </div>
          {/* Organization /adress */}
          <div className="w-full md:mt-3  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Registered Name of the Applicant Organization * :
              </label>
              <input
                type="text"
                name="name"
                required
                value={formData?.organizationProfile?.name}
                onChange={organizationProfileCH}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${formErrors.name ? "border-red-500" : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.name && (
                <p className="text-red-500 text-sm mt-1">{formErrors.name}</p>
              )}
            </div>

            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Corporate Office Location* :
              </label>
              <input
                type="text"
                name="corporateOfficeLocation"
                value={formData?.organizationProfile?.corporateOfficeLocation}
                placeholder=""
                onChange={organizationProfileCH}
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${formErrors.corporateOfficeLocation
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.corporateOfficeLocation && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.corporateOfficeLocation}
                </p>
              )}
            </div>
          </div>

          {/* contact & Email*/}
          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Total Number of Employees (Full-Time and Contract) :
              </label>
              <input
                type="number"
                name="totalEmployees"
                min="0"
                value={formData?.organizationProfile?.totalEmployees}
                onChange={organizationProfileCH}
                placeholder="Total Employees"
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${formErrors.totalEmployees
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.totalEmployees && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.totalEmployees}
                </p>
              )}
            </div>

            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Year of Establishment* :
              </label>
              <input
                type="number"
                name="yearOfEstablishment"
                min="0"
                placeholder=""
                value={formData?.organizationProfile?.yearOfEstablishment}
                onChange={organizationProfileCH}
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${formErrors.yearOfEstablishment
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.yearOfEstablishment && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.yearOfEstablishment}
                </p>
              )}
            </div>
          </div>

          {/*Governing Body Details*/}
          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Annual Turnover for FY 2023-24 :
              </label>
              <input
                type="text"
                name="annualTurnoverFY2023_24"
                value={formData?.organizationProfile?.annualTurnoverFY2023_24}
                onChange={organizationProfileCH}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${formErrors.annualTurnoverFY2023_24
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.annualTurnoverFY2023_24 && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.annualTurnoverFY2023_24}
                </p>
              )}
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Market Capitalization (as of March 31, 2024 – for listed
                entities only) :
              </label>
              <input
                type="text"
                name="marketCapitalization"
                value={formData?.organizationProfile?.marketCapitalization}
                onChange={organizationProfileCH}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${formErrors.marketCapitalization
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.marketCapitalization && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.marketCapitalization}
                </p>
              )}
            </div>
          </div>

          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Ownership Details * :
              </label>
              <input
                type="text"
                name="ownershipDetails"
                value={formData?.organizationProfile?.ownershipDetails}
                onChange={organizationProfileCH}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${formErrors.ownershipDetails
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.ownershipDetails && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.ownershipDetails}
                </p>
              )}
            </div>
          </div>

          {/* Brief Description of the Organization */}
          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Brief description of the applicant organisation describing its
                business, generic description of value chain (customers, company
                offices / plants, suppliers, products / services) - Maximum 100
                words.:
              </label>
              <textarea
                id=""
                name="briefDescription"
                value={formData?.organizationProfile?.briefDescription}
                onChange={organizationProfileCH}
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${formErrors.briefDescription
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                  }`}
              ></textarea>
              {formErrors.briefDescription && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.briefDescription}
                </p>
              )}
            </div>
          </div>

          {/* sustainabilityGovernance */}
          {/* Division heading */}
          <div className="text-start w-full font-semibold my-5">
            <h2>Sustainability Governance and Management Approach </h2>
          </div>

          <div className="w-full flex flex-col md:flex-row gap-4 py-1">
            <div className="w-full flex flex-col space-y-2 justify-between">
              <label
                htmlFor="integrationOfResponsibleBusiness"
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                How has the organization integrated principles of responsible
                business into its strategy, policies, and governance in line
                with NGRBC or any other guidelines
              </label>
              <input
                type="text"
                name="integrationOfResponsibleBusiness"
                id="integrationOfResponsibleBusiness"
                value={
                  formData?.sustainabilityGovernance
                    ?.integrationOfResponsibleBusiness
                }
                onChange={sustainabilityGovernanceCH}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${formErrors.integrationOfResponsibleBusiness
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.integrationOfResponsibleBusiness && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.integrationOfResponsibleBusiness}
                </p>
              )}
            </div>

            <div className="w-full flex flex-col space-y-2 justify-between">
              <label
                htmlFor="esgTopics"
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Describe the process adopted by the organisation to identify,
                prioritise, strategize on the most relevant environmental,
                social and governance topics, measurement systems, targets, and
                investments for the entity.
              </label>
              <input
                type="text"
                name="esgTopics"
                id="esgTopics"
                onChange={sustainabilityGovernanceCH}
                value={formData?.sustainabilityGovernance?.esgTopics}
                placeholder=""
                className={`border w-full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded placeholder:text-xs ps-2 outline-none ${formErrors.esgTopics
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.esgTopics && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.esgTopics}
                </p>
              )}
            </div>
          </div>

          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full flex flex-col space-y-2 justify-between">
              <label
                htmlFor="stakeholderEngagement"
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Could you outline the methodology employed by the organization
                to identify, prioritize, and engage with stakeholders, gather
                their expectations, obtain their feedback, and provide feedback
                to them?
              </label>
              <input
                type="text"
                name="stakeholderEngagement"
                value={
                  formData?.sustainabilityGovernance?.stakeholderEngagement
                }
                onChange={sustainabilityGovernanceCH}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded placeholder:text-xs ps-2 outline-none ${formErrors.stakeholderEngagement
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.stakeholderEngagement && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.stakeholderEngagement}
                </p>
              )}
            </div>

            <div className="w-full flex flex-col space-y-2 justify-between">
              <label
                htmlFor="seniorManagementResponsibilities"
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Specify the level and title of the management personnel
                responsible for achieving sustainability targets, realizing
                investments, and handling disclosures and assurance activities.
              </label>
              <input
                type="text"
                name="seniorManagementResponsibilities"
                onChange={sustainabilityGovernanceCH}
                value={
                  formData?.sustainabilityGovernance
                    ?.seniorManagementResponsibilities
                }
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded placeholder:text-xs ps-2 outline-none ${formErrors.seniorManagementResponsibilities
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.seniorManagementResponsibilities && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.seniorManagementResponsibilities}
                </p>
              )}
            </div>
          </div>

          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full flex flex-col space-y-2 justify-between">
              <label
                htmlFor="complianceProcess"
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Describe the process by which the organisation identifies
                compliance requirements and other emerging risks related to
                sustainability topics and ensure compliance and mitigation.
              </label>
              <input
                type="text"
                name="complianceProcess"
                value={formData?.sustainabilityGovernance?.complianceProcess}
                onChange={sustainabilityGovernanceCH}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded placeholder:text-xs ps-2 outline-none ${formErrors.complianceProcess
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.complianceProcess && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.complianceProcess}
                </p>
              )}
            </div>

            <div className="w-full flex flex-col space-y-2 justify-between">
              <label
                htmlFor="boardResponsibilities"
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                How does the Board of Directors ensure compliance and risk
                mitigation in sustainability governance?
              </label>
              <input
                type="text"
                name="boardResponsibilities"
                onChange={sustainabilityGovernanceCH}
                value={
                  formData?.sustainabilityGovernance?.boardResponsibilities
                }
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded placeholder:text-xs ps-2 outline-none ${formErrors.boardResponsibilities
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.boardResponsibilities && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.boardResponsibilities}
                </p>
              )}
            </div>
          </div>

          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor="sustainabilityMeasurements"
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Outline the process for ensuring the accuracy, transparency, and
                integrity of sustainability measurements.(Max 300 words)
              </label>
              <textarea
                name="sustainabilityMeasurements"
                value={
                  formData?.sustainabilityGovernance?.sustainabilityMeasurements
                }
                onChange={sustainabilityGovernanceCH}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded placeholder:text-xs ps-2 outline-none ${formErrors.sustainabilityMeasurements
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.sustainabilityMeasurements && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.sustainabilityMeasurements}
                </p>
              )}
            </div>
          </div>

          {/* sustainabilityIndicators / */}
          <div className="text-start w-full font-semibold my-5">
            <h2>
              Sustainability Indicators Measurement (Indicate the current
              status: Measured, Planned to Measure, or Not Applicable with
              Explanation)
            </h2>
          </div>
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                GHG Footprint:
              </label>
              <input
                type="text"
                name="ghgFootprint"
                value={formData?.sustainabilityIndicators?.ghgFootprint}
                onChange={sustainabilityIndicatorsCH}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>

            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Water Footprint:
              </label>
              <input
                type="text"
                name="waterFootprint"
                onChange={sustainabilityIndicatorsCH}
                value={formData?.sustainabilityIndicators?.waterFootprint}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Energy Footprint:
              </label>
              <input
                type="text"
                name="energyFootprint"
                value={formData?.sustainabilityIndicators?.energyFootprint}
                onChange={sustainabilityIndicatorsCH}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Waste Management and Circularity:
              </label>
              <input
                type="text"
                name="wasteManagement"
                value={formData?.sustainabilityIndicators?.wasteManagement}
                onChange={sustainabilityIndicatorsCH}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Employee Well-Being and Safety:
              </label>
              <input
                type="text"
                name="employeeWellBeing"
                value={formData?.sustainabilityIndicators?.employeeWellBeing}
                onChange={sustainabilityIndicatorsCH}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Gender Diversity:
              </label>
              <input
                type="text"
                name="genderDiversity"
                value={formData?.sustainabilityIndicators?.genderDiversity}
                onChange={sustainabilityIndicatorsCH}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Inclusive Development:
              </label>
              <input
                type="text"
                name="inclusiveDevelopment"
                value={formData?.sustainabilityIndicators?.inclusiveDevelopment}
                onChange={sustainabilityIndicatorsCH}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Fairness in Customer Engagement:
              </label>
              <input
                type="text"
                name="customerEngagement"
                value={formData?.sustainabilityIndicators?.customerEngagement}
                onChange={sustainabilityIndicatorsCH}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Openness of the business :
              </label>
              <input
                type="text"
                name="openness"
                value={formData?.sustainabilityIndicators?.openness}
                onChange={sustainabilityIndicatorsCH}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          {/* sustainabilityFrameworks */}

          <div className="text-start w-full font-semibold my-5">
            <h2>Frameworks, Initiatives, and Achievements </h2>
          </div>

          {/* Name of the Project / */}
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full flex flex-col space-y-2 justify-between">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Has the organization adopted sustainability frameworks like SDG,
                GRI, BRSR, SASB, or others? Provide details on your existing ESG
                policy.
              </label>
              <input
                type="text"
                name="adoptedFrameworks"
                onChange={sustainabilityFrameworksCH}
                value={formData?.sustainabilityFrameworks?.adoptedFrameworks}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${formErrors.adoptedFrameworks
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.adoptedFrameworks && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.adoptedFrameworks}
                </p>
              )}
            </div>

            <div className="w-full flex flex-col space-y-2 justify-between">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Indicate if the applicant has adopted / certified on ISO 9001,
                ISO 14001, ISO 45001, OHSAS 18001, SA 8000, ISO 50001, ISO
                26000, ISO 20400 or any other relevant sustainability related
                management systems.
              </label>
              <input
                type="text"
                name="isoStandards"
                value={formData?.sustainabilityFrameworks?.isoStandards}
                onChange={sustainabilityFrameworksCH}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${formErrors.isoStandards
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.isoStandards && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.isoStandards}
                </p>
              )}
            </div>
          </div>

          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full flex flex-col space-y-2 justify-between">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Mention if the applicant has adopted IGBC, USGBC (LEED), GRIHA,
                ECBC, WELL Being, EDGE (IFC), BEE star ratings GEM (ASSOCHAM),
                NGT Guidelines standards for their offices or other buildings or
                any other relevant standards related to built environment and
                office space.
              </label>
              <input
                type="text"
                name="officeStandards"
                onChange={sustainabilityFrameworksCH}
                value={formData?.sustainabilityFrameworks?.officeStandards}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${formErrors.officeStandards
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.officeStandards && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.officeStandards}
                </p>
              )}
            </div>

            <div className="w-full flex flex-col space-y-2 justify-between">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Describe any collaborative initiatives the organisation has
                taken to improve the sustainability performance with its peers,
                suppliers, customers or other entities such as NGOs for example
                sector focused collaborative initiatives.
              </label>
              <input
                type="text"
                name="collaborativeInitiatives"
                onChange={sustainabilityFrameworksCH}
                value={
                  formData?.sustainabilityFrameworks?.collaborativeInitiatives
                }
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${formErrors.collaborativeInitiatives
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.collaborativeInitiatives && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.collaborativeInitiatives}
                </p>
              )}
            </div>
          </div>

          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full flex flex-col space-y-2 justify-between">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Indicate whether the applicant is seeking third party assurance
                of their sustainability disclosures using AA 1000, ISAE 3000 or
                ISSA 5000 or are planning to do it in the next two years.
              </label>
              <input
                type="text"
                name="thirdPartyAssurance"
                value={formData?.sustainabilityFrameworks?.thirdPartyAssurance}
                onChange={sustainabilityFrameworksCH}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${formErrors.thirdPartyAssurance
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.thirdPartyAssurance && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.thirdPartyAssurance}
                </p>
              )}
            </div>
            <div className="w-full flex flex-col space-y-2 justify-between">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Describe how the organisation is adopting and implementing
                technology and innovative practices to improve sustainability of
                the organisation. :
              </label>
              <input
                type="text"
                name="innovativePractices"
                value={formData?.sustainabilityFrameworks?.innovativePractices}
                onChange={sustainabilityFrameworksCH}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${formErrors.innovativePractices
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.innovativePractices && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.innovativePractices}
                </p>
              )}
            </div>
          </div>

          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full flex flex-col space-y-2 justify-between">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Mention any public commitments made in areas like
                decarbonization, Net Zero, Diversity, or Waste Management.
              </label>
              <input
                type="text"
                name="publicCommitments"
                value={formData?.sustainabilityFrameworks?.publicCommitments}
                onChange={sustainabilityFrameworksCH}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${formErrors.publicCommitments
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.publicCommitments && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.publicCommitments}
                </p>
              )}
            </div>

            <div className="w-full flex flex-col space-y-2 justify-between">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Describe briefly, three most important sustainability
                achievements during FY 2022 to 2024 in quantifiable terms.
                Applicant may choose from any of the nine indicators mentioned
                in section 3 or choose any other quantifiable indicator that
                deemed relevant for the organisation. :
              </label>
              <input
                type="text"
                name="significantAchievements"
                value={
                  formData?.sustainabilityFrameworks?.significantAchievements
                }
                onChange={sustainabilityFrameworksCH}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${formErrors.significantAchievements
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.significantAchievements && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.significantAchievements}
                </p>
              )}
            </div>
          </div>
          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Can you describe the measures your company takes to ensure pay
                parity among employees with similar roles and responsibilities?
                Please include any policies, practices, or initiatives that
                contribute to equitable compensation.
              </label>
              <input
                type="text"
                name="parityMeasures"
                value={formData?.parityMeasures}
                onChange={inPutChangeHandler}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>
          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Outline initiatives taken to build awareness and competence on
                sustainability topics among employees and stakeholders. (Max 300
                words)
              </label>
              <textarea
                name="awarenessInitiatives"
                value={formData?.sustainabilityFrameworks?.awarenessInitiatives}
                onChange={sustainabilityFrameworksCH}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${formErrors.awarenessInitiatives
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.awarenessInitiatives && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.awarenessInitiatives}
                </p>
              )}
            </div>
          </div>

          {/* Contact details */}
          <div className="text-start w-full font-semibold my-5">
            <h2>Contact Details</h2>
          </div>

          {/* Name of the Project / */}
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Name of the Organization:
              </label>
              <input
                type="text"
                name="organizationName"
                value={formData?.contactDetails?.organizationName}
                onChange={contactDetailsCH}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${formErrors.organizationName
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.organizationName && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.organizationName}
                </p>
              )}
            </div>

            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Address:
              </label>
              <input
                type="text"
                name="address"
                onChange={contactDetailsCH}
                value={formData?.contactDetails?.address}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${formErrors.address ? "border-red-500" : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.address && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.address}
                </p>
              )}
            </div>
          </div>

          {/*Source of Funding: */}
          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Contact Person:
              </label>
              <input
                type="text"
                name="contactPerson"
                value={formData?.contactDetails?.contactPerson}
                onChange={contactDetailsCH}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${formErrors.contactPerson
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.contactPerson && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.contactPerson}
                </p>
              )}
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Mobile Number:
              </label>
              <input
                type="text"
                pattern="[0-9]*"
                inputMode="numeric"
                placeholder=""
                name="mobileNumber"
                value={formData?.contactDetails?.mobileNumber}
                onChange={contactDetailsCH}
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${formErrors.mobileNumber
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.mobileNumber && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.mobileNumber}
                </p>
              )}
            </div>
          </div>

          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Email Address:
              </label>
              <input
                type="text"
                name="emailAddress"
                value={formData?.contactDetails?.emailAddress}
                onChange={contactDetailsCH}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${formErrors.emailAddress
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.emailAddress && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.emailAddress}
                </p>
              )}
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Website :
              </label>
              <input
                type="text"
                name="website"
                value={formData?.contactDetails?.website}
                onChange={contactDetailsCH}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${formErrors.website ? "border-red-500" : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.website && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.website}
                </p>
              )}
            </div>
          </div>

          {/*payment option */}
          {/* Division heading */}
          <div className="text-start w-full font-semibold my-5">
            <h2>Financial and Payment Details</h2>
          </div>

          {/*payment option */}
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                is KMA Member?*
              </label>
              <select
                id=""
                name="isKmaMember"
                required
                value={formData?.isKmaMember}
                onChange={inPutChangeHandler}
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none
                   focus:outline-none focus:shadow-outline font-medium"
              >
                <option
                  value=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Select a category
                </option>
                <option
                  value={true}
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  KMA Member
                </option>
                <option
                  value={false}
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Non-KMA Member
                </option>
              </select>
            </div>
            <div
              className={`w-full space-y-2 ${formData?.isKmaMember ? "block" : "hidden"
                }`}
            >
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Membership Id*
              </label>
              <input
                type="text"
                name="membershipId"
                onChange={inPutChangeHandler}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Application Amount (with GST) :
              </label>
              <input
                type="text"
                name="amountWithGst"
                readOnly
                value={formData?.paymentDetails?.amountWithGst}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Payment Details :*
              </label>
              <select
                id=""
                name="mode"
                required
                value={formData?.paymentDetails?.mode}
                onChange={paymentChangeHandler}
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none focus:outline-none focus:shadow-outline font-medium ${formErrors.mode ? "border-red-500" : "border-[#771D32]/40"
                  }`}
              >
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Select a category
                </option>
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Cheque
                </option>
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  DD No.
                </option>
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Online
                </option>
              </select>
              {formErrors.mode && (
                <p className="text-red-500 text-sm mt-1">{formErrors.mode}</p>
              )}
            </div>

            <FileInputImg
              className={`w-full space-y-2 ${formData?.paymentDetails?.mode === "Online" ? "hidden" : "block"
                }`}
              label="Payment Receipt (png or jpg)"
              name="receipt"
              allowedFileTypes={["image/jpeg", "image/jpg", "image/png"]}
              setFile={(file) =>
                setFormData((prev) => ({
                  ...prev,
                  paymentDetails: {
                    ...prev.paymentDetails,
                    receipt: file,
                  },
                }))
              }
            />
          </div>

          <div className="my-6 w-full">
            <span className="font-bold">Note : </span>
            <span className="font-">
              Please ensure all file fields are completed before submitting the
              form. Additionally, kindly ensure that each file is under 25MB in
              size.
            </span>
          </div>

          {/* Division heading */}
          <div className="text-start w-full font-semibold my-5">
            <h2>Required Attachments</h2>
          </div>

          {/*Document */}
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <FileInput
              className={`w-full space-y-2`}
              label="Profile of the Organization (PDF/Doc)"
              name="organizationProfile"
              allowedFileTypes={[
                "application/pdf",
                "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              ]}
              setFile={(file) =>
                setFormData((prev) => ({
                  ...prev,
                  attachments: {
                    ...prev.attachments,
                    organizationProfile: file,
                  },
                }))
              }
            />

            <FileInput
              className={`w-full space-y-2`}
              label="Sustainability Reports (PDF/Doc)"
              name="sustainabilityReports"
              allowedFileTypes={[
                "application/pdf",
                "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              ]}
              setFile={(file) =>
                setFormData((prev) => ({
                  ...prev,
                  attachments: {
                    ...prev.attachments,
                    sustainabilityReports: file,
                  },
                }))
              }
            />
          </div>

          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <FileInput
              className={`w-full space-y-2`}
              label="Third-Party Assurance Report (if applicable):"
              name="thirdPartyAssuranceReport"
              allowedFileTypes={[
                "application/pdf",
                "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              ]}
              setFile={(file) =>
                setFormData((prev) => ({
                  ...prev,
                  attachments: {
                    ...prev.attachments,
                    thirdPartyAssuranceReport: file,
                  },
                }))
              }
            />

            <MultiFileInput
              className={`w-full space-y-2`}
              label="Supporting Documents:"
              name="supportingDocuments"
              setFiles={(files) =>
                setFormData((prev) => ({
                  ...prev,
                  attachments: {
                    ...prev.attachments,
                    supportingDocuments: files,
                  },
                }))
              }
            />
          </div>

          <div className="w-full flex flex-col md:flex-row gap-2 py-1 mt-5">
            <span className="text-xs md:text-sm ">
              <input
                type="checkbox"
                checked={agreed}
                onChange={(e) => setAgreed(e.target.checked)}
                required
                className="me-2"
              />
              I agree, on behalf of my organization, to abide by the rules of
              the KMA – Advocacy Awards 2024 and accept that the decisions of
              KMA are final and binding. I confirm all information in this
              application and accompanying documents are correct. I accept the
              timelines and the fee mentioned.
            </span>
          </div>

          {/* Submit Button */}
          <div className="w-full flex justify-center mt-10">
            <button
              disabled={!agreed}
              className="flex items-center justify-end border p-2 px-5 rounded bg-red-500 text-white"
            >
              {formData?.paymentDetails?.mode === "Online"
                ? " Pay Now & Submit"
                : "Submit"}
            </button>
          </div>
        </form>
        {loader && <Loading />}
      </div>
    </>
  );
};

export default Form2;
