import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const NGOTable = ({data, page}) => {
    const Navigate = useNavigate();

    return (
        <table className="w-full h-full max-w-[1200px] mx-auto text-sm text-left shadow-md md:rounded-lg overflow-x-scroll md:overflow-hidden">
            <thead className="text-xs text-white uppercase bg ">
                <tr className="bg-red-500 border-b  ">
                    <th scope="row" className="px-6 py-4 font-bold  text-white">
                        No.
                    </th>
                    <th className="px-6 py-4">Applicant</th>
                    <th className="px-6 py-4">Email</th>
                    <th className="px-6 py-4">Phone</th>
                    <th className="px-6 py-4">Date & Mode</th>
                    <th className="px-6 py-4">Organization</th>
                    <th className="px-6 py-4">KMA</th>
                </tr>
            </thead>
            <tbody>
                {data.map((item, i) => (
                    <tr
                        key={i}
                        onClick={() =>
                            Navigate(`/admin/application/${item?.formName}/${item?._id}`)
                        }
                        className="bg-white border-b text-ellipsis hover:bg-gray-50 text-black cursor-pointer "
                    >
                        <td className="px-6 py-4 ">{(page - 1) * 10 + i + 1}</td>
                        <td className="px-6 py-4 capitalize">{item?.contactDetails?.contactPerson}</td>
                        <td className="px-6 py-4">{item?.contactDetails?.emailAddress}</td>
                        <td className="px-6 py-4">{item?.contactDetails?.mobileNumber}</td>
                        <td className="px-6 py-4 capitalize">{new Date(item?.createdAt).toLocaleString([])}</td>
                        <td className="px-6 py-4 capitalize">{item?.organizationProfile?.name}</td>
                        <td className="px-6 py-4 capitalize">
                            {item?.kma_member ? "Yes" : "No"}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default NGOTable