import { useDispatch, useSelector } from 'react-redux';
import { axiosPrivate } from "../utils/Axios"
import { setAccessToken, setRefreshToken } from '../redux/slices/TokenReducer';
import { refreshTokenRoute } from '../utils/EndPoint';

const useRefreshToken = () => {
    const dispatch = useDispatch();
    const refreshToken = useSelector((state)=> state.token.refreshToken)

    const refresh = async () => {
        const response = await axiosPrivate.post(refreshTokenRoute, {
            refreshToken: refreshToken
        });

        dispatch(setAccessToken(response?.data?.accessToken));
        dispatch(setRefreshToken(response?.data?.refreshToken));

        return response?.data?.accessToken;
    }
    return refresh;
};

export default useRefreshToken;