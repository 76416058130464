import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { awardBaseURL, CreateSS } from "../../../utils/EndPoint";
import Loading from "../../loadingPage/Loading";
import FileInput from "../FileInput";
import axios from "axios";
import Select from "react-select";
import { sdgGoals } from "../../../data/FormData";
import FileInputImg from "../FileInputImg";
import {
  validateForm3Data,
  validateForm3SubData,
} from "../../../utils/validation/formValidation";

const Form3 = () => {
  const [loader, setLoader] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [selectedSdgs, setSelectedSdgs] = useState([]);
  const [formErrors, setFormErrors] = useState({}); // State to store form errors

  const kmaPaymentDetails = {
    NonKmaAmount: 3000,
    NonKmaWithGSTAmount: 3000 + 3000 * (18 / 100),
    KMAAmount: 2500,
    KMAWithGSTAmount: 2500 + 2500 * (18 / 100),
  };

  const initialData = {
    formName: "SS",
    isKmaMember: false,
    membershipId: "",

    organizationProfile: {
      name: "",
      establishedDate: "",
      foundersAndTeam: "",
      location: "",
      contactPerson: "",
      mobile: "",
      email: "",
      website: "",
    },

    generalOverview: {
      description: "",
      sdgsAddressed: [],
    },

    businessModel: {
      primaryProductOrService: "",
      uniqueValueProposition: "",
      primaryCustomersOrBeneficiaries: "",
      revenueModel: "",
      startupStage: "",
    },

    sustainabilityImpact: {
      positiveImpact: "",
      longTermImpact: "",
    },

    innovation: {
      technologiesOrProcesses: "",
      scalability: "",
      approach: "",
    },

    marketAndFinancials: {
      targetMarketSize: "",
      annualRevenueOrFunding: "",
      partnerships: "",
      grantsAwardsRecognitions: "",
    },

    challengesAndGrowth: {
      majorChallenges: "",
      supportNeeded: "",
    },

    teamAndGovernance: {
      teamDiversity: "",
      governanceStructure: "",
    },

    attachments: {
      businessPlan: {
        name: "",
        key: "",
        size: "",
        location: "",
      },
      certifications: {
        name: "",
        key: "",
        size: "",
        location: "",
      },
      testimonialsOrCaseStudies: {
        name: "",
        key: "",
        size: "",
        location: "",
      },
      impactMetrics: {
        name: "",
        key: "",
        size: "",
        location: "",
      },
    },

    paymentDetails: {
      mode: "",
      amount: kmaPaymentDetails?.NonKmaAmount,
      amountWithGst: kmaPaymentDetails?.NonKmaWithGSTAmount,
      transactionId: "",
      muid: "",
      receipt: {
        name: "",
        key: "",
        location: "",
      },
    },
  };

  const [formData, setFormData] = useState(initialData);

  const [doc, setDoc] = useState({
    ProjectReport: null,
    organizationProfile: null,
    ss: null,
  });

  const organizationProfileCH = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      const updatedFormData = {
        ...prev,
        organizationProfile: {
          ...prev.organizationProfile,
          [name]: value,
        },
      };

      // Validate the specific field only
      const errors = validateForm3Data(updatedFormData);
      if (errors[name]) {
        toast.error(errors[name]); // Show error toast
      }
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errors[name] || "", // Update only the current field's error
      }));

      return updatedFormData;
    });
  };

  const generalOverviewCH = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updatedFormData = {
        ...prev,
        generalOverview: {
          ...prev.generalOverview,
          [name]: value,
        },
      };

      // Validate the specific field only
      const errors = validateForm3Data(updatedFormData);
      if (errors[name]) {
        toast.error(errors[name]); // Show error toast
      }
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errors[name] || "", // Update only the current field's error
      }));

      return updatedFormData;
    });
  };
  const handleSdgChange = (selectedOptions) => {
    setSelectedSdgs(selectedOptions);

    // Update formData.generalOverview.sdgsAddressed
    setFormData((prev) => ({
      ...prev,
      generalOverview: {
        ...prev.generalOverview,
        sdgsAddressed: selectedOptions.map((option) => option.value), // Store only values
      },
    }));
  };

  const businessModelCH = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updatedFormData = {
        ...prev,
        businessModel: {
          ...prev.businessModel,
          [name]: value,
        },
      };

      // Validate the specific field only
      const errors = validateForm3Data(updatedFormData);
      if (errors[name]) {
        toast.error(errors[name]); // Show error toast
      }
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errors[name] || "", // Update only the current field's error
      }));

      return updatedFormData;
    });
  };

  const sustainabilityImpactCH = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updatedFormData = {
        ...prev,
        sustainabilityImpact: {
          ...prev.sustainabilityImpact,
          [name]: value,
        },
      };

      // Validate the specific field only
      const errors = validateForm3Data(updatedFormData);
      if (errors[name]) {
        toast.error(errors[name]); // Show error toast
      }
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errors[name] || "", // Update only the current field's error
      }));

      return updatedFormData;
    });
  };

  const marketAndFinancialsCH = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updatedFormData = {
        ...prev,
        marketAndFinancials: {
          ...prev.marketAndFinancials,
          [name]: value,
        },
      };

      // Validate the specific field only
      const errors = validateForm3Data(updatedFormData);
      if (errors[name]) {
        toast.error(errors[name]); // Show error toast
      }
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errors[name] || "", // Update only the current field's error
      }));

      return updatedFormData;
    });
  };

  const challengesAndGrowthCH = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updatedFormData = {
        ...prev,
        challengesAndGrowth: {
          ...prev.challengesAndGrowth,
          [name]: value,
        },
      };

      // Validate the specific field only
      const errors = validateForm3Data(updatedFormData);
      if (errors[name]) {
        toast.error(errors[name]); // Show error toast
      }
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errors[name] || "", // Update only the current field's error
      }));

      return updatedFormData;
    });
  };

  const teamAndGovernanceCH = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updatedFormData = {
        ...prev,
        teamAndGovernance: {
          ...prev.teamAndGovernance,
          [name]: value,
        },
      };
      // Validate the specific field only
      const errors = validateForm3Data(updatedFormData);
      if (errors[name]) {
        toast.error(errors[name]); // Show error toast
      }

      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errors[name] || "", // Update only the current field's error
      }));

      return updatedFormData;
    });
  };

  const innovationCH = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updatedFormData = {
        ...prev,
        innovation: {
          ...prev.innovation,
          [name]: value,
        },
      };

      // Validate the specific field only
      const errors = validateForm3Data(updatedFormData);
      if (errors[name]) {
        toast.error(errors[name]); // Show error toast
      }
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errors[name] || "", // Update only the current field's error
      }));

      return updatedFormData;
    });
  };

  // Payment mode Update
  const paymentChangeHandler = (e) => {
    const modeValue = e.target.value;

    setFormData((prev) => {
      const updatedFormData = {
        ...prev,
        paymentDetails: {
          ...prev.paymentDetails,
          mode: modeValue, // Update the mode field
        },
      };

      // Validate the specific field only
      const errors = validateForm3Data(updatedFormData);
      if (errors.mode) {
        toast.error(errors.mode); // Show error toast for the mode field
      }
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        mode: errors.mode || "", // Update only the mode field's error
      }));

      return updatedFormData;
    });
  };

  const docChangeHandler = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (file) {
      if (file.size > 25 * 1024 * 1024) {
        toast.warn("File size should be less than 25MB");
      } else {
        setDoc((prev) => ({
          ...prev,
          [name]: file,
        }));
      }
    }
  };

  const inPutChangeHandler = (e) => {
    const { name, value } = e.target;

    if (name === "isKmaMember") {
      setFormData((prev) => ({
        ...prev,
        [name]: value === "true",
      }));

      // Application Amount auto inputting depending on the kma or non kma
      if (value === "true") {
        setFormData((prev) => ({
          ...prev,
          paymentDetails: {
            ...prev.paymentDetails,
            amount: kmaPaymentDetails?.KMAAmount,
            amountWithGst: kmaPaymentDetails?.KMAWithGSTAmount,
          },
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          paymentDetails: {
            ...prev.paymentDetails,
            amount: kmaPaymentDetails?.NonKmaAmount,
            amountWithGst: kmaPaymentDetails?.NonKmaWithGSTAmount,
          },
        }));
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const backToBasics = () => {
    window.scrollTo(0, 0);
    setFormData(initialData);
    setAgreed(false);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const errors = validateForm3Data(formData);
    console.log(errors);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);

      // Show each error in a separate toast
      Object.values(errors).forEach((errorMsg) => {
        toast.error(errorMsg); // Display each error
      });

      return;
    }
    console.log(formData);

    // if (!formData?.attachments?.businessPlan?.location) {
    //   toast.error("Please upload Business Plan or Pitch Deck (PDF/Doc).");
    //   return;
    // } else if (!formData?.attachments?.certifications?.location) {
    //   toast.error("Environmental or Sustainability Certifications (PDF/Doc)");
    //   return;
    // } else if (!formData?.attachments?.impactMetrics?.location) {
    //   toast.error("Please upload Impact Metrics or Reports (PDF/Doc).");
    //   return;
    // } else if (!formData?.attachments?.testimonialsOrCaseStudies?.location) {
    //   toast.error("Please upload Testimonials or Case Studies (PDF/Doc).");
    //   return;
    // }

    try {
      setLoader(true);
      const response = await axios.post(`${awardBaseURL}${CreateSS}`, {
        ...formData,
        generalOverview: {
          ...formData.generalOverview,
          sdgsAddressed: selectedSdgs?.map((item) => item.value),
        },
      });

      if (response?.data?.redirectInfo?.url) {
        window.location.href = response?.data?.redirectInfo?.url;
        toast.warning(
          "Please Wait for the Payment Gateway DON'T CLOSE ANYTHING"
        );
        window.scrollTo(0, 0);

        setFormData(initialData);
        setSelectedSdgs([]);
      } else {
        toast.success("Your Application Successfully Submitted");
        window.scrollTo(0, 0);
        setFormData(initialData);
        setSelectedSdgs([]);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.msg || "Try again after sometime");
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log(formData, formErrors);

  return (
    <>
      <h1 className="font-bold font-sherif text-textColor text-2xl text-center mb-5 lg:mb-10">
        {" "}
        Sustainability Startup Award 2025
      </h1>

      <div className="flex items-start justify-center border rounded-xl shadow-xl w-full max-w-[1280px] mx-auto">
        <form
          className="w-full flex flex-col items-center justify-center md:px-20 p-5 md:p-10"
          action=""
          onSubmit={submitHandler}
        >
          {/* Basic Information */}
          <div className="text-start w-full font-semibold mb-2">
            <h2>Basic Information</h2>
          </div>
          <div className="w-full md:mt-3 flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                What is the name of your startup?
              </label>
              <input
                type="text"
                required
                name="name"
                value={formData?.organizationProfile?.name}
                onChange={organizationProfileCH}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.name ? "border-red-500" : "border-[#771D32]/40"
                }`}
              />
              {formErrors.name && (
                <p className="text-red-500 text-sm mt-1">{formErrors.name}</p>
              )}
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                When was your startup established?
              </label>
              <input
                type="date"
                name="establishedDate"
                value={formData?.organizationProfile?.establishedDate}
                onChange={organizationProfileCH}
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none 
                  ${
                    formErrors.establishedDate
                      ? "border-red-500"
                      : "border-[#771D32]/40"
                  }`}
              />
              {formErrors.establishedDate && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.establishedDate}
                </p>
              )}
            </div>
          </div>

          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Provide details about the founders and the team.
              </label>
              <input
                type="text"
                name="foundersAndTeam"
                value={formData?.organizationProfile?.foundersAndTeam}
                onChange={organizationProfileCH}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.foundersAndTeam
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.foundersAndTeam && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.foundersAndTeam}
                </p>
              )}
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Where is your startup located or headquartered?
              </label>
              <input
                type="text"
                name="location"
                value={formData?.organizationProfile?.location}
                onChange={organizationProfileCH}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.location ? "border-red-500" : "border-[#771D32]/40"
                }`}
              />
              {formErrors.location && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.location}
                </p>
              )}
            </div>
          </div>

          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Contact Person Name
              </label>
              <input
                type="text"
                name="contactPerson"
                value={formData?.organizationProfile?.contactPerson}
                onChange={organizationProfileCH}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.contactPerson
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.contactPerson && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.contactPerson}
                </p>
              )}
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Mobile Number
              </label>
              <input
                type="text"
                name="mobile"
                value={formData?.organizationProfile?.mobile}
                onChange={organizationProfileCH}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.mobile ? "border-red-500" : "border-[#771D32]/40"
                }`}
              />
              {formErrors.mobile && (
                <p className="text-red-500 text-sm mt-1">{formErrors.mobile}</p>
              )}
            </div>
          </div>

          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Email Address
              </label>
              <input
                type="email"
                name="email"
                value={formData?.organizationProfile?.email}
                onChange={organizationProfileCH}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.email ? "border-red-500" : "border-[#771D32]/40"
                }`}
              />
              {formErrors.email && (
                <p className="text-red-500 text-sm mt-1">{formErrors.email}</p>
              )}
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Do you have a website or social media profiles? If yes, provide
                the links.
              </label>
              <input
                type="text"
                name="website"
                value={formData?.organizationProfile?.website}
                onChange={organizationProfileCH}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.website ? "border-red-500" : "border-[#771D32]/40"
                }`}
              />
              {formErrors.website && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.website}
                </p>
              )}
            </div>
          </div>

          {/* General Overview */}
          <div className="text-start w-full font-semibold my-5">
            <h2>General Overview</h2>
          </div>
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Briefly describe your startup and its core mission (max. 300
                words).
              </label>
              <textarea
                name="description"
                value={formData?.generalOverview?.description}
                onChange={generalOverviewCH}
                id=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.description
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              ></textarea>
              {formErrors.description && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.description}
                </p>
              )}
            </div>
          </div>
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Which United Nations Sustainable Development Goals (SDGs) does
                your startup address? (Select all that apply).
              </label>

              <Select
                options={sdgGoals}
                isMulti={true}
                onChange={handleSdgChange}
                value={selectedSdgs}
              />
            </div>
          </div>

          {/* Business Model */}
          <div className="text-start w-full font-semibold my-5">
            <h2>Business Model</h2>
          </div>
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label className="text-[#777777] text-[12px] md:text-[13px] font-medium">
                What is your primary product or service?
              </label>
              <input
                type="text"
                name="primaryProductOrService"
                value={formData?.businessModel?.primaryProductOrService}
                onChange={businessModelCH}
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.primaryProductOrService
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.primaryProductOrService && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.primaryProductOrService}
                </p>
              )}
            </div>
            <div className="w-full space-y-2">
              <label className="text-[#777777] text-[12px] md:text-[13px] font-medium">
                What is your startup's unique value proposition (UVP) for
                promoting sustainability?
              </label>
              <input
                type="text"
                name="uniqueValueProposition"
                value={formData?.businessModel?.uniqueValueProposition}
                onChange={businessModelCH}
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.uniqueValueProposition
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.uniqueValueProposition && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.uniqueValueProposition}
                </p>
              )}
            </div>
          </div>
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label className="text-[#777777] text-[12px] md:text-[13px] font-medium">
                Who are your primary customers or beneficiaries?
              </label>
              <input
                type="text"
                name="primaryCustomersOrBeneficiaries"
                onChange={businessModelCH}
                value={formData?.businessModel?.primaryCustomersOrBeneficiaries}
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.primaryCustomersOrBeneficiaries
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.primaryCustomersOrBeneficiaries && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.primaryCustomersOrBeneficiaries}
                </p>
              )}
            </div>
            <div className="w-full space-y-2">
              <label className="text-[#777777] text-[12px] md:text-[13px] font-medium">
                What is the current stage of your startup (e.g., Idea stage,
                MVP, Early Growth, Scaling)?
              </label>
              <input
                type="text"
                name="startupStage"
                onChange={businessModelCH}
                value={formData?.businessModel?.startupStage}
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.startupStage
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.startupStage && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.startupStage}
                </p>
              )}
            </div>
          </div>
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label className="text-[#777777] text-[12px] md:text-[13px] font-medium">
                Describe your revenue model. (e.g., product sales,
                subscriptions, partnerships, etc.)
              </label>
              <input
                type="text"
                name="revenueModel"
                onChange={businessModelCH}
                value={formData?.businessModel?.revenueModel}
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.revenueModel
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.revenueModel && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.revenueModel}
                </p>
              )}
            </div>
          </div>

          {/* Sustainability Impact */}
          <div className="text-start w-full font-semibold my-5">
            <h2>Sustainability Impact</h2>
          </div>
          <div className="w-full flex flex-col md:flex-row gap-2 py-1 ">
            <div className="w-full flex flex-col space-y-2 justify-between">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                How does your startup positively impact the environment or
                society? Provide measurable results, if available. (e.g., carbon
                footprint reduction, waste diverted, lives improved, etc.)
              </label>
              <input
                type="text"
                name="positiveImpact"
                onChange={sustainabilityImpactCH}
                value={formData?.sustainabilityImpact?.positiveImpact}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.positiveImpact
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.positiveImpact && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.positiveImpact}
                </p>
              )}
            </div>
            <div className="w-full flex flex-col space-y-2 justify-between">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                What is the estimated long-term impact of your startup in terms
                of sustainability (5–10 years)?
              </label>
              <input
                type="text"
                name="longTermImpact"
                onChange={sustainabilityImpactCH}
                value={formData?.sustainabilityImpact?.longTermImpact}
                placeholder=""
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.longTermImpact
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.longTermImpact && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.longTermImpact}
                </p>
              )}
            </div>
          </div>

          {/* Innovation */}
          <div className="text-start w-full font-semibold my-5">
            <h2>Innovation</h2>
          </div>
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label className="text-[#777777] text-[12px] md:text-[13px] font-medium">
                What innovative technologies or processes does your startup use
                to achieve sustainability goals?
              </label>
              <input
                type="text"
                name="technologiesOrProcesses"
                onChange={innovationCH}
                value={formData?.innovation?.technologiesOrProcesses}
                placeholder=""
                className={`border w-full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.technologiesOrProcesses
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.technologiesOrProcesses && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.technologiesOrProcesses}
                </p>
              )}
            </div>

            <div className="w-full space-y-2">
              <label className="text-[#777777] text-[12px] md:text-[13px] font-medium">
                How scalable is your solution?
              </label>
              <input
                type="text"
                name="scalability"
                onChange={innovationCH}
                value={formData?.innovation?.scalability}
                placeholder=""
                className={`border w-full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.scalability
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.scalability && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.scalability}
                </p>
              )}
            </div>
          </div>
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label className="text-[#777777] text-[12px] md:text-[13px] font-medium">
                What makes your approach different from existing solutions in
                the market in terms of sustainability?
              </label>
              <input
                type="text"
                name="approach"
                onChange={innovationCH}
                value={formData?.innovation?.approach}
                placeholder=""
                className={`border w-full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.approach ? "border-red-500" : "border-[#771D32]/40"
                }`}
              />
              {formErrors.approach && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.approach}
                </p>
              )}
            </div>
          </div>
          {/* Market and Financials */}
          <div className="text-start w-full font-semibold my-5">
            <h2>Market and Financials</h2>
          </div>
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full flex flex-col space-y-2 justify-between">
              <label className="text-[#777777] text-[12px] md:text-[13px] font-medium">
                What is the size of your target market? (e.g., Kerala, India,
                global)
              </label>
              <input
                type="text"
                name="targetMarketSize"
                onChange={marketAndFinancialsCH}
                value={formData?.marketAndFinancials?.targetMarketSize}
                placeholder=""
                className={`border w-full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.targetMarketSize
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.targetMarketSize && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.targetMarketSize}
                </p>
              )}
            </div>
            <div className="w-full flex flex-col space-y-2 justify-between">
              <label className="text-[#777777] text-[12px] md:text-[13px] font-medium">
                What is your annual revenue or funding raised (if applicable)?
              </label>
              <input
                type="text"
                name="annualRevenueOrFunding"
                onChange={marketAndFinancialsCH}
                value={formData?.marketAndFinancials?.annualRevenueOrFunding}
                placeholder=""
                className={`border w-full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.annualRevenueOrFunding
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.annualRevenueOrFunding && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.annualRevenueOrFunding}
                </p>
              )}
            </div>
          </div>
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full flex flex-col space-y-2 justify-between">
              <label className="text-[#777777] text-[12px] md:text-[13px] font-medium">
                Do you have partnerships or collaborations with other
                organisations, NGOs, or governmental bodies? If yes, please
                provide details.
              </label>
              <input
                type="text"
                name="partnerships"
                onChange={marketAndFinancialsCH}
                value={formData?.marketAndFinancials?.partnerships}
                placeholder=""
                className={`border w-full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.partnerships
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.partnerships && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.partnerships}
                </p>
              )}
            </div>

            <div className="w-full flex flex-col space-y-2 justify-between">
              <label className="text-[#777777] text-[12px] md:text-[13px] font-medium">
                Have you received any grants, awards, or recognitions related to
                sustainability?
              </label>
              <input
                type="text"
                name="grantsAwardsRecognitions"
                onChange={marketAndFinancialsCH}
                value={formData?.marketAndFinancials?.grantsAwardsRecognitions}
                placeholder=""
                className={`border w-full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.grantsAwardsRecognitions
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.grantsAwardsRecognitions && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.grantsAwardsRecognitions}
                </p>
              )}
            </div>
          </div>

          {/* Challenges and Growth */}
          <div className="text-start w-full font-semibold my-5">
            <h2>Challenges and Growth</h2>
          </div>
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full flex flex-col space-y-2 justify-between">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                What are the major challenges your startup faces in scaling or
                achieving its sustainability goals?
              </label>
              <input
                type="text"
                name="majorChallenges"
                onChange={challengesAndGrowthCH}
                value={formData?.challengesAndGrowth?.majorChallenges}
                placeholder=""
                className={`border w-full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.majorChallenges
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.majorChallenges && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.majorChallenges}
                </p>
              )}
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                What support or resources do you need to accelerate your growth?
                (e.g., funding, mentorship, partnerships)
              </label>
              <input
                type="text"
                name="supportNeeded"
                onChange={challengesAndGrowthCH}
                value={formData?.challengesAndGrowth?.supportNeeded}
                placeholder=""
                className={`border w-full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.supportNeeded
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.supportNeeded && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.supportNeeded}
                </p>
              )}
            </div>
          </div>

          {/* Team and Governance */}
          <div className="text-start w-full font-semibold my-5">
            <h2>Team and Governance</h2>
          </div>

          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                How diverse and inclusive is your team? (e.g., gender,
                background, expertise)
              </label>
              <input
                type="text"
                name="teamDiversity"
                onChange={teamAndGovernanceCH}
                value={formData?.teamAndGovernance?.teamDiversity}
                placeholder=""
                className={`border w-full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.teamDiversity
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.teamDiversity && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.teamDiversity}
                </p>
              )}
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                What governance structure does your startup follow to ensure
                ethical practices?
              </label>
              <input
                type="text"
                name="governanceStructure"
                onChange={teamAndGovernanceCH}
                value={formData?.teamAndGovernance?.governanceStructure}
                placeholder=""
                className={`border w-full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.governanceStructure
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.governanceStructure && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.governanceStructure}
                </p>
              )}
            </div>
          </div>

          {/*payment option */}
          {/* Division heading */}
          <div className="text-start w-full font-semibold my-5">
            <h2>Financial and Payment Details</h2>
          </div>

          {/*payment option */}
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                is KMA Member?*
              </label>
              <select
                id=""
                name="isKmaMember"
                required
                value={formData?.isKmaMember}
                onChange={inPutChangeHandler}
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none
                   focus:outline-none focus:shadow-outline font-medium"
              >
                <option
                  value=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Select a category
                </option>
                <option
                  value={true}
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  KMA Member
                </option>
                <option
                  value={false}
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Non-KMA Member
                </option>
              </select>
            </div>
            <div
              className={`w-full space-y-2 ${
                formData?.isKmaMember ? "block" : "hidden"
              }`}
            >
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Membership Id*
              </label>
              <input
                type="text"
                name="membershipId"
                onChange={inPutChangeHandler}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Application Amount (with GST) :
              </label>
              <input
                type="text"
                name="amountWithGst"
                readOnly
                value={formData?.paymentDetails?.amountWithGst}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Payment Details :*
              </label>
              <select
                id=""
                name="mode"
                required
                value={formData?.paymentDetails?.mode}
                onChange={paymentChangeHandler}
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none focus:outline-none focus:shadow-outline font-medium ${
                  formErrors.mode ? "border-red-500" : "border-[#771D32]/40"
                }`}
              >
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Select a category
                </option>
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Cheque
                </option>
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  DD No.
                </option>
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Online
                </option>
              </select>
              {formErrors.mode && (
                <p className="text-red-500 text-sm mt-1">{formErrors.mode}</p>
              )}
            </div>
            <FileInputImg
              className={`w-full space-y-2 ${
                formData?.paymentDetails?.mode === "Online" ? "hidden" : "block"
              }`}
              label="Payment Receipt (png or jpg)"
              name="receipt"
              allowedFileTypes={["image/jpeg", "image/jpg", "image/png"]}
              setFile={(file) =>
                setFormData((prev) => ({
                  ...prev,
                  paymentDetails: {
                    ...prev.paymentDetails,
                    receipt: file,
                  },
                }))
              }
            />
          </div>

          <div className="my-6 w-full">
            <span className="font-bold">Note : </span>
            <span className="font-">
              Please ensure all file fields are completed before submitting the
              form. Additionally, kindly ensure that each file is under 25MB in
              size.
            </span>
          </div>

          {/* Division heading */}
          <div className="text-start w-full font-semibold my-5">
            <h2>Required Attachments</h2>
          </div>

          {/*Document */}
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <FileInput
              className={`w-full space-y-2 `}
              label="Business Plan or Pitch Deck (PDF/Doc)."
              name="businessPlan"
              setFile={(file) =>
                setFormData((prev) => ({
                  ...prev,
                  attachments: {
                    ...prev.attachments,
                    businessPlan: file,
                  },
                }))
              }
            />

            <FileInput
              className={`w-full space-y-2 `}
              label="Environmental or Sustainability Certifications (PDF/Doc)"
              name="certifications"
              setFile={(file) =>
                setFormData((prev) => ({
                  ...prev,
                  attachments: {
                    ...prev.attachments,
                    certifications: file,
                  },
                }))
              }
            />
          </div>
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <FileInput
              className={`w-full space-y-2 `}
              label="Testimonials or Case Studies (PDF/Doc)."
              name="testimonialsOrCaseStudies"
              setFile={(file) =>
                setFormData((prev) => ({
                  ...prev,
                  attachments: {
                    ...prev.attachments,
                    testimonialsOrCaseStudies: file,
                  },
                }))
              }
            />

            <FileInput
              className={`w-full space-y-2 `}
              label="Impact Metrics or Reports (PDF/Doc)."
              name="impactMetrics"
              setFile={(file) =>
                setFormData((prev) => ({
                  ...prev,
                  attachments: {
                    ...prev.attachments,
                    impactMetrics: file,
                  },
                }))
              }
            />
          </div>

          <div className="w-full flex flex-col md:flex-row gap-2 py-1 mt-5">
            <span className="text-xs md:text-sm ">
              <input
                type="checkbox"
                checked={agreed}
                onChange={(e) => setAgreed(e.target.checked)}
                required
                className="me-2"
              />
              I agree, on behalf of my organization, to abide by the rules of
              the KMA – Advocacy Awards 2024 and accept that the decisions of
              KMA are final and binding. I confirm all information in this
              application and accompanying documents are correct. I accept the
              timelines and the fee mentioned.
            </span>
          </div>

          {/* Submit Button */}
          <div className="w-full flex justify-center mt-10">
            <button
              disabled={!agreed}
              className="flex items-center justify-end border p-2 px-5 rounded bg-red-500 text-white"
            >
              {formData?.paymentDetails?.mode === "Online"
                ? " Pay Now & Submit"
                : "Submit"}
            </button>
          </div>
        </form>
        {loader && <Loading />}
      </div>
    </>
  );
};

export default Form3;
