import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Guideline = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="w-full min-h-screen">
      <div className="max-w-[1280px] mx-auto mt-20 md:mt-40 p-5 md:p-0">
        <h1 className="font-bold text-textColor font-sherif text-[40px]">
          GUIDELINES
        </h1>

        {/* GUIDELINES OF THE APPLICATION FOR KMA – CSR AWARDS 2024 */}
        <div className="mt-10">
          <h1 className="mb-3 font-semibold text-lg">
            GUIDELINES OF THE APPLICATION FOR KMA – CSR AWARDS 2024
          </h1>

          <h1 className="mb-3 font-semibold">For the Special Attention of Applicants:</h1>
          
          <span className="border-b border-black pb-1 font-semibold ">
            Instructions:{" "}
          </span>

          <ol style={{ listStyleType: "number" }} className="px-4 my-5">
            <li>
              CSR Projects commenced in 2019-20 or afterwards and still
              continuing in 2023-24 will also be considered. (The entries shall
              be of Long term projects commenced in 2019-20 or afterwards and
              completed by 2022-23 (Max 4 years) and short term projects
              commenced and completed in FY 2022-23).
            </li>
            <li>
              If any particular question is not applicable or relevant to your
              organization, please answer it as “Not Applicable”(NA)). If answer
              to any particular question cannot be furnished due to
              confidentiality or other reasons, please answer it as
              “Confidential/ No Comments”.
            </li>
            <li>
              Illustrations of CSR Projects:
              <ol
                className="mt-3 ms-3"
                style={{ listStyleType: "lower-roman" }}
              >
                <li>
                  Education - Providing scholarships and learning materials,
                  supporting digital education initiatives, establishing smart
                  class rooms, providing school infrastructure, improving
                  education methods especially in Government schools, providing
                  special coaching to students for professional careers, skill
                  development projects, prevention of school dropouts, providing
                  rehabilitation courses for differently abled children,
                  increasing community participation in protecting children from
                  all sorts of abuses, employee participation in giving tuitions
                  to needy students, capacity building for farmers …{" "}
                </li>
                <li>
                  Health - Providing access to quality healthcare services,
                  promoting health education, free medical camps in underserved
                  communities, donating medical supplies and equipment to
                  disaster-stricken regions, supporting medical research and
                  development in critical areas, encouraging healthy lifestyles
                  among employees and customers by providing education,
                  resources, and incentives, working towards removing the stigma
                  around mental health problem, prevention of open defecation
                  and reduce incidence of water-borne diseases by providing
                  sanitation infrastructure and drive for behavioural change,
                  employee volunteering in health care programs ….
                </li>
                <li>
                  Social Inclusion – Empowerment programs for women such as
                  livelihood enhancement projects, rehabilitation projects for
                  differently abled persons, providing homes for homeless, day
                  care centres for elderly and for persons with special
                  conditions such as Dementia, involving senior citizens in
                  productive vocations, improving conditions of orphanages,
                  setting up old age homes, measures for reducing inequalities
                  faced by socially and economically backward groups such as
                  improved facilities for tribals, inclusive measures for
                  LGBTQ+, rural development projects, slum area development ……
                </li>
                <li>
                  Environment – Reducing carbon footprint, leveraging renewable
                  energies, projects to reduce pollution, ecological balance,
                  protection of flora and funa, animal welfare, agroforestry,
                  conservation of natural resources and maintaining quality of
                  soil, air and water, Projects that promote the 6 R’s of
                  sustainability - Reduce, Reuse, Recyle, Refuse, Rethink and
                  Repair…..
                </li>
              </ol>
            </li>
          </ol>

          <span className="border-b border-black pb-1 font-semibold">
            I. Profile of the Organization:
          </span>

          <ol style={{ listStyleType: "number" }} className="px-4 my-5">
            <li>Full Name of the Applicant Organization:</li>
            <li>Corporate Office Location:</li>
            <li>
              Brief Description of the Applicant Organization (in about 50
              words):
            </li>
            <li>Ownership:</li>
            <li>Year of Establishment of the Applicant Organization: </li>
            <li>Annual Turnover of FY 2022-23 </li>
            <li>Total Number of employees of the Applicant Organization: </li>
            <li>
              {" "}
              Has the Applicant Organization adopted any International
              Integrated Reporting System (IIRS)? Attach a copy of your latest
              report. In case IIRS is not being followed, then specify the
              reporting system you are following (Attach a copy).
            </li>
            <li>
              Indicate if the applicant is certified to ISO 9001 / ISO 14001 /
              ISO 45001 / OHSAS 18001 / SA 8000 / others. Attach a copy of the
              certificate(s).{" "}
            </li>
          </ol>

          <span className="border-b border-black pb-1 font-semibold">
            II. CSR Policy and Practice
          </span>

          <ol style={{ listStyleType: "number" }} className="px-4 my-5">
            <li>
              What are the salient features of the Organization’s CSR Policy?{" "}
            </li>
            <li>
              Is there a CSR committee? What is its composition, role, and
              responsibilities?
            </li>
            <li>
              Specify funds allocated for CSR activities (in absolute numbers,
              and as a percentage of net profit), during the last 4 years
              (year-wise).
            </li>
            <li>
              List the various CSR projects that were carried out during the FY
              2022-23
            </li>
            <li>
              What awareness programs are in place to involve employees, their
              families, and community members, in respect of the Organization’s
              CSR projects
            </li>
            <li>
              Does the Organization’s internal audit system include regular
              audit of CSR programmes and performance? Provide details.
            </li>
            <li>
              Is a CSR Report being published separately? If yes, enclose a copy
              of the latest CSR Annual Report.{" "}
            </li>
            <li>Any accolades received by the Company for its CSR projects?</li>
          </ol>

          <span className="border-b border-black pb-1 font-semibold">
            III. CSR Project Report/Case Study : (Education/Health/Social
            Inclusion/Environment)
          </span>
          <div className="mt-4">
            Describe the CSR project of the organization, that has had a
            perceptible impact on the community and contributed to societal
            development, pertaining to any one of the above stated CSR themes .
            In the context of the above project, prepare a Case Study,
            incorporating the following and any other details considered
            relevant:
          </div>
          <ol style={{ listStyleType: "number" }} className="px-4 my-5">
            <li>What was the process adopted to assess community needs?</li>
            <li>The Process that was employed in implementation</li>
            <li>
              {" "}
              Whether the project was carried out directly or through an
              implementing Agency. Provide relevant details
            </li>
            <li>
              Resource allocation in terms of physical and financial units and
              time span.
            </li>
            <li>
              {" "}
              Challenges, if any, faced during the course of implementation
            </li>
            <li> Improvisations made for course corrections</li>
            <li>
              Describe the impact of the program - quantitative and qualitative
              improvement brought about in the life-style of the community?
            </li>
            <li>Steps taken to ensure sustainability of the programme.</li>
          </ol>
        </div>
        <div className="w-full flex justify-start mt-10">
          <Link
            to={"/registration"}
            className="flex items-center justify-start border p-2 px-5 rounded bg-red-500 text-white"
          >
            Apply Now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Guideline;
