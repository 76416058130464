import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const AdminProtectedRoute = () => {
  const user = useSelector((state) => state.auth.userInfo);
  // console.log(user);
  return (
    <div>
      {user ? <Outlet /> : <Navigate to="/admin/login" replace />}
    </div>
  );
};

export default AdminProtectedRoute;
