import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { awardBaseURL, CreateNGO } from "../../../utils/EndPoint";
import Loading from "../../loadingPage/Loading";
import FileInput from "../FileInput";
import axios from "axios";
import ProjectDetail from "../ProjectDetail";
import FileInputImg from "../FileInputImg";
import { validateForm1Data } from "../../../utils/validation/formValidation";

const kmaPaymentDetails = {
  NonKmaAmount: 3000,
  NonKmaWithGSTAmount: 3540,
  KMAAmount: 2500,
  KMAWithGSTAmount: 2950,
};

const Form1 = () => {
  const [loader, setLoader] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [formErrors, setFormErrors] = useState({}); // State to store form errors

  const generateId = () => `${Date.now()}-${Math.random().toString(36)}`;

  const projectDetailInitial = {
    id: generateId(),
    name: "",
    keyTheme: "",
    scaledExamples: "",
    fundGenerations: "",
    sourceOfFunding: "",
    location: "",
    startDate: null,
    endDate: null,
    duration: {
      years: 0,
      months: 0,
      days: 0,
    },

    totalAmountSpent: {
      fy_2020_21: "",
      fy_2021_22: "",
      fy_2022_23: "",
      fy_2023_24: "",
      total: "",
    },
    needBaselineStudies: "",
    concernsAddressed: "",
    objectiveAlignment: "",
    stakeholders: "",
    totalBeneficiaries: 0,
    innovativeMethods: "",
    donorMonitor: "",
    impact: "",
    impactAssessment: {
      conducted: false,
      by: "",
      thirdPartyAgencyName: "",
      report: {
        name: "",
        key: "",
        size: "",
        location: "",
      },
    },
    sustainabilityStrategy: "",
    replicabilityProbability: "",
  };

  const initialData = {
    formName: "NGO",
    isKmaMember: false,
    membershipId: "",
    organizationProfile: {
      name: "",
      description: "",
      yearOfEstablishment: "",
      legalStatus: "",
      governingBodyDetails: "",
      thematicAreas: "",
      activeGeographies: "",
    },

    projectDetails: [projectDetailInitial],

    attachments: {
      organizationProfile: {
        name: "",
        key: "",
        size: "",
        location: "",
      },

      csrPolicy: {
        name: "",
        key: "",
        size: "",
        location: "",
      },
      csrProjectReport: {
        name: "",
        key: "",
        size: "",
        location: "",
      },
      impactAssessmentReport: {
        name: "",
        key: "",
        size: "",
        location: "",
      },
    },

    paymentDetails: {
      mode: "",
      amount: kmaPaymentDetails?.NonKmaAmount,
      amountWithGst: kmaPaymentDetails?.NonKmaWithGSTAmount,
      transactionId: "",
      muid: "",
      receipt: {
        name: "",
        key: "",
        location: "",
      },
    },
  };

  const [formData, setFormData] = useState(initialData);

  // common input change handler
  const inPutChangeHandler = (e) => {
    const { name, value } = e.target;

    if (name === "isKmaMember") {
      // Update 'isKmaMember' state
      setFormData((prev) => ({
        ...prev,
        [name]: value === "true",
      }));

      // Set payment details based on KMA membership status
      if (value === "true") {
        setFormData((prev) => ({
          ...prev,
          paymentDetails: {
            ...prev.paymentDetails,
            amount: kmaPaymentDetails?.KMAAmount,
            amountWithGst: kmaPaymentDetails?.KMAWithGSTAmount,
          },
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          paymentDetails: {
            ...prev.paymentDetails,
            amount: kmaPaymentDetails?.NonKmaAmount,
            amountWithGst: kmaPaymentDetails?.NonKmaWithGSTAmount,
          },
        }));
      }
    } else {
      // Update other fields in formData
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const organizationInputHandler = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updatedFormData = {
        ...prev,
        organizationProfile: {
          ...prev.organizationProfile,
          [name]: value,
        },
      };

      // Validate the specific field only
      const errors = validateForm1Data(updatedFormData);
      if (errors[name]) {
        toast.error(errors[name]); // Show error toast
      }
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errors[name] || "", // Update only the current field's error
      }));

      return updatedFormData;
    });
  };

  // Payment mode Update
  const paymentChangeHandler = (e) => {
    const modeValue = e.target.value;

    setFormData((prev) => {
      const updatedFormData = {
        ...prev,
        paymentDetails: {
          ...prev.paymentDetails,
          mode: modeValue, // Update the mode field
        },
      };

      // Validate the specific field only
      const errors = validateForm1Data(updatedFormData);
      if (errors.mode) {
        toast.error(errors.mode); // Show error toast for the mode field
      }
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        mode: errors.mode || "", // Update only the mode field's error
      }));

      return updatedFormData;
    });
  };

  const backToBasics = () => {
    window.scrollTo(0, 0);
    setFormData(initialData);
    setAgreed(false);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    console.log(formData);
    const errors = validateForm1Data(formData);
    console.log(errors);

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);

      // Show each error in a separate toast
      Object.values(errors).forEach((errorMsg) => {
        toast.error(errorMsg); // Display each error
      });

      return;
    }

    // Validate organization profile attachments
    const requiredAttachments = [
      {
        field: "organizationProfile",
        label: "Profile of the Organization (PDF/Doc)",
      },
      { field: "csrPolicy", label: "CSR Policy (PDF/Doc)" },
      { field: "csrProjectReport", label: "CSR Project Report" },
    ];

    // Loop through required attachments and check conditions
    for (const { field, label } of requiredAttachments) {
      // if (!formData?.attachments?.[field]?.location) {
      //   toast.error(`Please upload ${label}.`);
      //   return; 
      // }
      if (formData?.attachments?.[field]?.size > 25 * 1024 * 1024) {
        toast.error(`Please make sure ${label} file size is less than 25MB.`);
        return; // Exit the function if an error is found
      }
    }
    try {
      setLoader(true);
      const response = await axios.post(
        `${awardBaseURL}${CreateNGO}`,
        formData
      );

      // console.log(response)
      if (response?.data?.redirectInfo?.url) {
        window.location.href = response?.data?.redirectInfo?.url;
        toast.warning(
          "Please Wait for the Payment Gateway DON'T CLOSE ANYTHING"
        );
        window.scrollTo(0, 0);
        setFormData(initialData);
      } else {
        toast.success("Your Application Successfully Submitted");
        window.scrollTo(0, 0);
        setFormData(initialData);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.msg || "Try again after sometime");
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    // window.scrollTo(0, 0);
  }, []);

  const addProjectDetail = () => {
    if (formData?.projectDetails?.length > 4) {
      return;
    }

    setFormData((prev) => ({
      ...prev,
      projectDetails: [
        ...prev.projectDetails,
        {
          ...projectDetailInitial,
          id: generateId(),
        },
      ],
    }));
  };

  const removeProjectDetail = (id) => {
    if (formData?.projectDetails?.length < 2) {
      return;
    }

    const newData = formData?.projectDetails?.filter((item) => item?.id !== id);

    setFormData((prev) => ({
      ...prev,
      projectDetails: newData,
    }));
  };

  // console.log({ formData })
  return (
    <>
      <h1 className="font-bold font-sherif text-textColor text-2xl text-center mb-5 lg:mb-10">
        Impactful NGO Awards 2025
      </h1>
      <div className="flex items-start justify-center border rounded-xl shadow-xl w-full max-w-[1280px] mx-auto">
        <form
          className="w-full flex flex-col items-center justify-center md:px-20 p-5 md:p-10"
          action=""
          onSubmit={submitHandler}
        >
          {/* Division heading */}
          <div className="text-start w-full font-semibold mb-2">
            <h2>Organization Profile</h2>
          </div>
          {/* Organization /adress */}
          <div className="w-full md:mt-3  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Name of the Applicant Organization * :
              </label>
              <input
                type="text"
                name="name"
                required
                value={formData?.organizationProfile?.name}
                onChange={organizationInputHandler}
                placeholder="Eg: Qmark Technolabs"
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.name ? "border-red-500" : "border-[#771D32]/40"
                }`}
              />
              {formErrors.name && (
                <p className="text-red-500 text-sm mt-1">{formErrors.name}</p>
              )}
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Year of Establishment* :
              </label>
              <input
                type="number"
                name="yearOfEstablishment"
                value={formData?.organizationProfile?.yearOfEstablishment}
                placeholder=""
                onChange={organizationInputHandler}
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.yearOfEstablishment
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.yearOfEstablishment && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.yearOfEstablishment}
                </p>
              )}
            </div>
          </div>

          {/* contact & Email*/}
          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Legal Status of the Organization * :
              </label>
              <input
                type="text"
                name="legalStatus"
                value={formData?.organizationProfile?.legalStatus}
                onChange={organizationInputHandler}
                placeholder="Eg: Society, Trust, Section 8 Company"
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.legalStatus
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.legalStatus && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.legalStatus}
                </p>
              )}
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Governing Body Details* :
              </label>
              <input
                type="text"
                name="governingBodyDetails"
                placeholder=""
                value={formData?.organizationProfile?.governingBodyDetails}
                onChange={organizationInputHandler}
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.governingBodyDetails
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.governingBodyDetails && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.governingBodyDetails}
                </p>
              )}
            </div>
          </div>

          {/*Governing Body Details*/}
          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Thematic Areas of Intervention :
              </label>
              <input
                type="text"
                name="thematicAreas"
                value={formData?.organizationProfile?.thematicAreas}
                onChange={organizationInputHandler}
                placeholder="Eg: Education, Health, Skills Development, Women Empowerment"
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.thematicAreas
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.thematicAreas && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.thematicAreas}
                </p>
              )}
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Active Geographies* :
              </label>
              <input
                type="text"
                name="activeGeographies"
                value={formData?.organizationProfile?.activeGeographies}
                onChange={organizationInputHandler}
                placeholder="States and Districts"
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.activeGeographies
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.activeGeographies && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.activeGeographies}
                </p>
              )}
            </div>
          </div>

          {/* Brief Description of the Organization */}
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Brief Description of the Applicant Organization (in about 50
                words):
              </label>
              <textarea
                value={formData?.organizationProfile?.description}
                name="description"
                id=""
                onChange={organizationInputHandler}
                className={`border w-full md:w-full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none ${
                  formErrors.description
                    ? "border-red-500"
                    : "border-[#771D32]/40"
                }`}
              />
              {formErrors.description && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.description}
                </p>
              )}
            </div>
          </div>

          {/* Division heading */}
          <div className="text-start w-full font-semibold my-5">
            <h2>
              Project Details{" "}
              <span className="text-xs "> (Add upto 5 Projects)</span>
            </h2>
          </div>

          {formData?.projectDetails?.map((item, index) => (
            <ProjectDetail
              key={item?.id}
              index={index}
              item={item}
              removeProjectDetail={removeProjectDetail}
              formData={formData}
              setFormData={setFormData}
            />
          ))}

          <button
            type="button"
            onClick={addProjectDetail}
            className="bg-blue-600 text-white text-sm p-2 rounded-md my-4 "
          >
            Add Project
          </button>

          {/* Division heading */}
          <div className="text-start w-full font-semibold my-5">
            <h2>Financial and Payment Details</h2>
          </div>

          {/*payment option */}
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                is KMA Member?*
              </label>
              <select
                id=""
                name="isKmaMember"
                value={formData?.isKmaMember}
                onChange={inPutChangeHandler}
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none
                   focus:outline-none focus:shadow-outline font-medium"
              >
                <option
                  value=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Select a category
                </option>
                <option
                  value={true}
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  KMA Member
                </option>
                <option
                  value={false}
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Non-KMA Member
                </option>
              </select>
            </div>
            <div
              className={`w-full space-y-2 ${
                formData?.isKmaMember ? "block" : "hidden"
              }`}
            >
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Membership Id*
              </label>
              <input
                type="text"
                name="membershipId"
                onChange={inPutChangeHandler}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Application Amount (with GST) :
              </label>
              <input
                type="text"
                name="amountWithGst"
                //
                readOnly
                value={formData?.paymentDetails?.amountWithGst}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>

            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Payment Details :*
              </label>
              <select
                id=""
                name="mode"
                value={formData?.paymentDetails?.mode}
                onChange={paymentChangeHandler}
                className={`border w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded ps-2 outline-none focus:outline-none focus:shadow-outline font-medium ${
                  formErrors.mode ? "border-red-500" : "border-[#771D32]/40"
                }`}
              >
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Select a category
                </option>
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Cheque
                </option>
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  DD No.
                </option>
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Online
                </option>
              </select>
              {formErrors.mode && (
                <p className="text-red-500 text-sm mt-1">{formErrors.mode}</p>
              )}
            </div>

            <FileInputImg
              className={`w-full space-y-2 ${
                formData?.paymentDetails?.mode === "Online" ? "hidden" : "block"
              }`}
              label="Payment Receipt (png or jpg)"
              name="receipt"
              allowedFileTypes={["image/jpeg", "image/jpg", "image/png"]}
              setFile={(file) =>
                setFormData((prev) => ({
                  ...prev,
                  paymentDetails: {
                    ...prev.paymentDetails,
                    receipt: file,
                  },
                }))
              }
            />
          </div>

          <div className="my-6 w-full">
            <span className="font-bold">Note : </span>
            <span className="font-">
              Please ensure all file fields are completed before submitting the
              form. Additionally, kindly ensure that each file is under 25MB in
              size.
            </span>
          </div>

          {/* Division heading */}
          <div className="text-start w-full font-semibold my-5">
            <h2> Required Attachments</h2>
          </div>

          {/*Document */}
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <FileInput
              className={`w-full space-y-2`}
              label="Profile of the Organization (PDF/Doc)"
              name="organizationProfile"
              setFile={(file) =>
                setFormData((prev) => ({
                  ...prev,
                  attachments: {
                    ...prev.attachments,
                    organizationProfile: file,
                  },
                }))
              }
            />
            <FileInput
              className={`w-full space-y-2`}
              label="CSR Policy/Practices (PDF/Doc)"
              name="csrPolicy"
              setFile={(file) =>
                setFormData((prev) => ({
                  ...prev,
                  attachments: {
                    ...prev.attachments,
                    csrPolicy: file,
                  },
                }))
              }
            />
          </div>
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <FileInput
              className={`w-full space-y-2`}
              label="CSR Project Report/Case Study (PDF/Doc):"
              name="csrProjectReport"
              setFile={(file) =>
                setFormData((prev) => ({
                  ...prev,
                  attachments: {
                    ...prev.attachments,
                    csrProjectReport: file,
                  },
                }))
              }
            />
            <FileInput
              className={`w-full space-y-2`}
              label="Impact Assessment Report (if applicable):"
              name="impactAssessmentReport"
              setFile={(file) =>
                setFormData((prev) => ({
                  ...prev,
                  attachments: {
                    ...prev.attachments,
                    impactAssessmentReport: file,
                  },
                }))
              }
            />
          </div>

          <div className="w-full flex flex-col md:flex-row gap-2 py-1 mt-5">
            <span className="text-xs md:text-sm ">
              <input
                type="checkbox"
                checked={agreed}
                onChange={(e) => setAgreed(e.target.checked)}
                className="me-2"
              />
              I agree, on behalf of my organization, to abide by the rules of
              the KMA – Advocacy Awards 2024 and accept that the decisions of
              KMA are final and binding. I confirm all information in this
              application and accompanying documents are correct. I accept the
              timelines and the fee mentioned.
            </span>
          </div>

          {/* Submit Button */}
          <div className="w-full flex justify-center mt-10">
            <button
              disabled={!agreed}
              className="flex items-center justify-end border p-2 px-5 rounded bg-red-500 text-white"
            >
              {formData?.paymentDetails?.mode === "Online"
                ? " Pay Now & Submit"
                : "Submit"}
            </button>
          </div>
        </form>
        {loader && <Loading />}
      </div>
    </>
  );
};

export default Form1;
