export const validateForm1Data = (formData) => {
  const errors = {};

  // Helper function to validate date format (yyyy-mm-dd)
  function isValidDateFormat(dateStr) {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    return dateRegex.test(dateStr);
  }

  // Validate organization profile attachments
  const requiredAttachments = [
    { field: "organizationProfile", label: "Profile of the Organization (PDF/Doc)" },
    { field: "csrPolicy", label: "CSR Policy (PDF/Doc)" },
    { field: "csrProjectReport", label: "CSR Project Report" },

  ];

  // requiredAttachments.forEach(({ field, label }) => {
  //   if (!formData?.attachments?.[field]?.location) {
  //     errors[field] = `Please upload ${label}.`;
  //   }
  //   if (formData?.attachments?.[field]?.size > 25 * 1024 * 1024) {
  //     errors[field] = `Please make sure ${label} file size is less than 25MB.`;
  //   }
  // });

  // Validate organization profile details
  const organizationProfile = formData.organizationProfile || {};

  if (!organizationProfile.name) {
    errors.name = "Organization Name is required.";
  } else if (!/^[A-Za-z0-9&.\- ]+$/.test(organizationProfile.name)) {
    errors.name = "Organization Name can only contain letters, numbers, spaces, '&', '.', and '-'.";
  }

  if (!organizationProfile.description) {
    errors.description = "Organization Description is required.";
  } else if (organizationProfile.description.trim().split(/\s+/).length > 50) {
    errors.description = "Maximum 50 words are allowed.";
  }

  if (!organizationProfile.yearOfEstablishment) {
    errors.yearOfEstablishment = "Year of Establishment is required.";
  } else if (!/^\d{4}$/.test(organizationProfile.yearOfEstablishment)) {
    errors.yearOfEstablishment = "Year of Establishment must be a valid 4-digit year.";
  }

  if (!organizationProfile.legalStatus) {
    errors.legalStatus = "Legal Status is required.";
  } else if (!/^[A-Za-z0-9&.\- ]+$/.test(organizationProfile.legalStatus)) {
    errors.legalStatus = "Legal Status must contain only letters, numbers, spaces, '&', '.', and '-'.";
  }

  if (!organizationProfile.governingBodyDetails) {
    errors.governingBodyDetails = "Governing Body Details are required.";
  }

  if (!organizationProfile.thematicAreas) {
    errors.thematicAreas = "Thematic Areas are required.";
  }

  if (!organizationProfile.activeGeographies) {
    errors.activeGeographies = "Active Geographies is required.";
  } else if (!/^[A-Za-z0-9&.\- ]+$/.test(organizationProfile.activeGeographies)) {
    errors.activeGeographies = "Active Geographies must contain only letters, numbers, spaces, '&', '.', and '-'.";
  }

  // Validate project details
  // (formData.projectDetails || []).forEach((project, index) => {
  //   if (!project?.name) {
  //     errors[`project_${index}_name`] = `Project ${index + 1}: Name is required.`;
  //   }
  //   if (!project?.keyTheme) {
  //     errors[`project_${index}_keyTheme`] = `Project ${index + 1}: Key Theme is required.`;
  //   }
  //   if (!project?.sourceOfFunding) {
  //     errors[`project_${index}_sourceOfFunding`] = `Project ${index + 1}: Source of Funding is required.`;
  //   }
  //   if (!project?.startDate) {
  //     errors[`project_${index}_startDate`] = `Project ${index + 1}: Start Date is required.`;
  //   } else if (!isValidDateFormat(project.startDate)) {
  //     errors[`project_${index}_startDate`] = `Project ${index + 1}: Start Date must be in yyyy-mm-dd format and valid.`;
  //   }

  //   if (!project?.endDate) {
  //     errors[`project_${index}_endDate`] = `Project ${index + 1}: End Date is required.`;
  //   } else if (!isValidDateFormat(project.endDate)) {
  //     errors[`project_${index}_endDate`] = `Project ${index + 1}: End Date must be in yyyy-mm-dd format and valid.`;
  //   }

  //   // Ensure logical consistency between Start Date and End Date
  //   if (project.startDate && project.endDate && isValidDateFormat(project.startDate) && isValidDateFormat(project.endDate)) {
  //     const startDate = new Date(project.startDate);
  //     const endDate = new Date(project.endDate);

  //     if (endDate < startDate) {
  //       errors[`project_${index}_endDate`] = `Project ${index + 1}: End Date cannot be earlier than Start Date.`;
  //     }
  //   }

  //   if (!project?.totalBeneficiaries || isNaN(project?.totalBeneficiaries) || project.totalBeneficiaries <= 0) {
  //     errors[`project_${index}_totalBeneficiaries`] = `Project ${index + 1}: Total Beneficiaries must be greater than 0.`;
  //   }

  //   if (project?.impactAssessment?.conducted === undefined) {
  //     errors[`project_${index}_impactAssessment`] = `Project ${index + 1}: Impact Assessment conducted status is required.`;
  //   }
  // });

  // Validate payment details
  const payment = formData.paymentDetails || {};

  if (!payment.mode) {
    errors.mode = "Payment Mode is required.";
  }
 

  return errors;
};

// form2
export const validateForm2Data = (formData) => {
  const errors = [];

  // Validate organization profile attachment
  // if (!formData?.attachments?.organizationProfile?.location) {
  //   errors.push("Please upload Profile of the Organization (PDF/Doc)");
  // }

  // // Validate sustainability reports attachment
  // if (!formData?.attachments?.sustainabilityReports?.location) {
  //   errors.push("Please upload Sustainability Reports (PDF/Doc)");
  // }

  // // Validate third-party assurance report attachment
  // if (formData?.attachments?.supportingDocuments?.length === 0) {
  //   errors.push("Please upload supporting documents");
  // }

  // Additional validation for required fields

  if (!formData?.organizationProfile?.name) {
    errors.push("Organization Name is required.");
  } else if (!/^[A-Za-z0-9&.\- ]+$/.test(formData?.organizationProfile?.name)) {
    errors.push("Organization Name can only contain letters, numbers, spaces, '&', '.', and '-'.");
  }
  if (!formData?.organizationProfile?.corporateOfficeLocation) {
    errors.push("Corporate Office Location is required.");
  } else if (!/^[A-Za-z0-9&.\- ]+$/.test(formData?.organizationProfile?.corporateOfficeLocation)) {
    errors.push("Corporate Office Location can only contain letters, numbers, spaces, '&', '.', and '-'.");
  }

  if (!formData?.organizationProfile?.ownershipDetails) {
    errors.push("Ownership Details is required.");
  }

  if (!formData?.organizationProfile?.yearOfEstablishment) {
    errors.push("Year of Establishment is required.");
  } else if (!/^\d+$/.test(formData.organizationProfile.yearOfEstablishment)) {
    errors.push("Year of Establishment must be a valid number.");
  }
  if (!formData?.organizationProfile?.marketCapitalization) {
    errors.push("Market Capitalization is required (as of March 31, 2024 – for listed entities only).");
  }

  if (!formData?.organizationProfile?.annualTurnoverFY2023_24) {
    errors.push("Annual Turnover is required ");
  }

  if (!formData?.organizationProfile?.totalEmployees) {
    errors.push("Total Employees is required.");
  } else if (!/^\d+$/.test(formData.organizationProfile.totalEmployees)) {
    errors.push("Total Employees must be a valid number.");
  }

  if (!formData?.contactDetails?.organizationName) {
    errors.push("Organization Name is required.");
  } else if (!/^[A-Za-z0-9&.\- ]+$/.test(formData?.contactDetails?.organizationName)) {
    errors.push("Organization Name can only contain letters, numbers, spaces, '&', '.', and '-'.");
  }

  if (!formData?.contactDetails?.address) {
    errors.push("Address is required.");
  } else if (!/^[a-zA-Z0-9\s,.'-]+$/.test(formData?.contactDetails?.address)) {
    errors.push("Address must contain only letters, numbers, spaces, commas, periods, or hyphens.");
  }

  if (!formData?.contactDetails?.contactPerson) {
    errors.contactPerson = "Contact Person is required.";
  } else if (!/^[A-Za-z0-9&.\- ]+$/.test(formData?.contactDetails?.contactPerson)) {
    errors.contactPerson = "Contact Person can only contain letters, numbers, spaces, '&', '.', and '-'.";
  }

  if (!formData?.contactDetails?.emailAddress) {
    errors.push("Email Address is required.");
  }

  if (!formData?.contactDetails?.mobileNumber) {
    errors.push("Mobile Number is required.");
  }

  if (!formData?.paymentDetails?.mode) {
    errors.push("Payment mode is required.");
  }

  // Validate email format
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (formData?.contactDetails?.emailAddress && !emailRegex.test(formData?.contactDetails?.emailAddress)) {
    errors.push("Please provide a valid Email Address.");
  }

  // Validate mobile number format (example: Indian 10-digit numbers)
  const mobileRegex = /^\d{10}$/;
  if (formData?.contactDetails?.mobileNumber && !mobileRegex.test(formData?.contactDetails?.mobileNumber)) {
    errors.push("Please provide a valid 10-digit Mobile Number.");
  }

  return errors;
};
export const validateFormData = (formData) => {
  const errors = {};

  // Validate organization profile attachment
  // if (!formData?.attachments?.organizationProfile?.location) {
  //   errors.organizationProfileAttachment = "Please upload Profile of the Organization (PDF/Doc)";
  // }

  // // Validate sustainability reports attachment
  // if (!formData?.attachments?.sustainabilityReports?.location) {
  //   errors.sustainabilityReportsAttachment = "Please upload Sustainability Reports (PDF/Doc)";
  // }

  // Validate third-party assurance report attachment
  // if (formData?.attachments?.supportingDocuments?.length === 0) {
  //   errors.supportingDocumentsAttachment = "Please upload supporting documents";
  // }

  // Validate organization profile fields
  if (!formData?.organizationProfile?.name) {
    errors.name = "Organization Name is required.";
  } else if (!/^[A-Za-z0-9&.\- ]+$/.test(formData?.organizationProfile?.name)) {
    errors.name = "Organization Name can only contain letters, numbers, spaces, '&', '.', and '-'.";
  }

  if (!formData?.organizationProfile?.corporateOfficeLocation) {
    errors.corporateOfficeLocation = "Corporate Office Location is required.";
  } else if (!/^[A-Za-z0-9&.\- ]+$/.test(formData?.organizationProfile?.corporateOfficeLocation)) {
    errors.corporateOfficeLocation = "Corporate Office Location can only contain letters, numbers, spaces, '&', '.', and '-'.";
  }

  if (!formData?.organizationProfile?.ownershipDetails) {
    errors.ownershipDetails = "Ownership Details is required.";
  }

  if (!formData?.organizationProfile?.yearOfEstablishment) {
    errors.yearOfEstablishment = "Year of Establishment is required.";
   } else if (!/^\d{4}$/.test(formData?.organizationProfile?.yearOfEstablishment)) {
    errors.yearOfEstablishment = "Year of Establishment must be a valid year (e.g., 2023).";
  }

  if (!formData?.organizationProfile?.marketCapitalization) {
    errors.marketCapitalization = "Market Capitalization is required (as of March 31, 2024 – for listed entities only).";
  }

  if (!formData?.organizationProfile?.annualTurnoverFY2023_24) {
    errors.annualTurnoverFY2023_24 = "Annual Turnover is required.";
  }else if (!/^\d+$/.test(formData.organizationProfile.annualTurnoverFY2023_24)) {
    errors.annualTurnoverFY2023_24 = "Annual Turnover must be a valid number.";
  }


  if (!formData?.organizationProfile?.totalEmployees) {
    errors.totalEmployees = "Total Employees is required.";
  } else if (!/^\d+$/.test(formData.organizationProfile.totalEmployees)) {
    errors.totalEmployees = "Total Employees must be a valid number.";
  }
  if (!formData?.organizationProfile?.briefDescription) {
    errors.briefDescription = "Description is required.";
  } else if (formData?.organizationProfile?.briefDescription.trim().split(/\s+/).length > 100) {
    errors.briefDescription = "Maximum 100 words are allowed.";
  }
  //  sustainabilityGovernance 
  if (!formData?.sustainabilityGovernance?.integrationOfResponsibleBusiness) {
    errors.integrationOfResponsibleBusiness = "Integration Of Responsible Business is required.";
  }
  if (!formData?.sustainabilityGovernance?.esgTopics) {
    errors.esgTopics = "ESGTopics is required.";
  }

  // Validate contact details
  if (!formData?.contactDetails?.organizationName) {
    errors.organizationName = "Organization Name is required.";
  } else if (!/^[A-Za-z0-9&.\- ]+$/.test(formData?.contactDetails?.organizationName)) {
    errors.organizationName = "Organization Name can only contain letters, numbers, spaces, '&', '.', and '-'.";
  }

  if (!formData?.contactDetails?.address) {
    errors.address = "Address is required.";
  } else if (!/^[a-zA-Z0-9\s,.'-]+$/.test(formData?.contactDetails?.address)) {
    errors.address = "Address must contain only letters, numbers, spaces, commas, periods, or hyphens.";
  }

  if (!formData?.contactDetails?.contactPerson) {
    errors.contactPerson = "Contact Person is required.";
  } else if (!/^[A-Za-z0-9&.\- ]+$/.test(formData?.contactDetails?.contactPerson)) {
    errors.contactPerson = "Contact Person can only contain letters, numbers, spaces, '&', '.', and '-'.";
  }

  if (!formData?.contactDetails?.emailAddress) {
    errors.emailAddress = "Email Address is required.";
  } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData?.contactDetails?.emailAddress)) {
    errors.emailAddress = "Please provide a valid Email Address.";
  }
  if (!formData?.contactDetails?.website) {
    errors.website = "Website is required.";
} else if (!/^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\/?$/.test(formData?.contactDetails?.website)) {
    errors.website = "Please provide a valid website URL.";
}

  if (!formData?.contactDetails?.mobileNumber) {
    errors.mobileNumber = "Mobile Number is required.";
  } else if (!/^\d{10}$/.test(formData?.contactDetails?.mobileNumber)) {
    errors.mobileNumber = "Please provide a valid 10-digit Mobile Number.";
  }

  // Validate payment details
  if (!formData?.paymentDetails?.mode) {
    errors.mode = "Payment mode is required.";
  }

  return errors;
};

// Form 3
export const validateForm3SubData = (formData) => {
  const errors = [];

  // Helper function to check if the input is a valid number
  function isValidNumber(value) {
    return !isNaN(value) && value > 0;
  }

  // Validate Organization Profile
  const orgProfile = formData.organizationProfile || {};
  if (!orgProfile.name) {
    errors.push("Organization Name is required.");
  } else if (!/^[A-Za-z0-9&.\- ]+$/.test(orgProfile.name)) {
    errors.push("Organization Name can only contain letters, numbers, spaces, '&', '.', and '-'.");
  }

  if (!orgProfile.establishedDate) {
    errors.push("Established Date is required.");
  }

  if (!orgProfile.foundersAndTeam) {
    errors.push("Founders and Team information is required.");
  }
  if (!orgProfile.location) {
    errors.push("Location is required.");
  }
  if (!orgProfile.contactPerson) {
    errors.push("Contact Person is required.");
  }
  if (!orgProfile.mobile) {
    errors.push("Mobile number is required.");
  } else if (!/^\d{10}$/.test(orgProfile.mobile)) {
    errors.push("Mobile number must be a valid 10-digit number.");
  }
  if (!orgProfile.email) {
    errors.push("Email is required.");
  } else if (!/\S+@\S+\.\S+/.test(orgProfile.email)) {
    errors.push("Email must be a valid email address.");
  }
  if (!orgProfile.website) {
    errors.push("Website is required.");
  }

  // Validate General Overview
  const generalOverview = formData.generalOverview || {};
  if (!generalOverview.description) {
    errors.push("Description is required.");
  }

  // Validate Business Model
  const businessModel = formData.businessModel || {};
  if (!businessModel.primaryProductOrService) {
    errors.push("Primary Product or Service is required.");
  }
  if (!businessModel.uniqueValueProposition) {
    errors.push("Unique Value Proposition is required.");
  }
  if (!businessModel.primaryCustomersOrBeneficiaries) {
    errors.push("Primary Customers or Beneficiaries are required.");
  }
  if (!businessModel.revenueModel) {
    errors.push("Revenue Model is required.");
  }
  if (!businessModel.startupStage) {
    errors.push("Startup Stage is required.");
  }

  // Validate Sustainability Impact
  const sustainabilityImpact = formData.sustainabilityImpact || {};
  if (!sustainabilityImpact.positiveImpact) {
    errors.push("Positive Impact is required.");
  }
  if (!sustainabilityImpact.longTermImpact) {
    errors.push("Long-Term Impact is required.");
  }

  // Validate Innovation
  const innovation = formData.innovation || {};
  if (!innovation.technologiesOrProcesses) {
    errors.push("Technologies or Processes are required.");
  }
  if (!innovation.scalability) {
    errors.push("Scalability is required.");
  }
  if (!innovation.approach) {
    errors.push("Approach is required.");
  }

  // Validate Market and Financials
  const marketAndFinancials = formData.marketAndFinancials || {};
  if (!marketAndFinancials.targetMarketSize) {
    errors.push("Target Market Size is required.");
  }
  if (!marketAndFinancials.annualRevenueOrFunding) {
    errors.push("Annual Revenue or Funding is required.");
  }
  if (!marketAndFinancials.partnerships) {
    errors.push("Partnerships information is required.");
  }
  if (!marketAndFinancials.grantsAwardsRecognitions) {
    errors.push("Grants/Awards/Recognitions information is required.");
  }

  // Validate Challenges and Growth
  const challengesAndGrowth = formData.challengesAndGrowth || {};
  if (!challengesAndGrowth.majorChallenges) {
    errors.push("Major Challenges are required.");
  }
  if (!challengesAndGrowth.supportNeeded) {
    errors.push("Support Needed is required.");
  }

  // Validate Team and Governance
  const teamAndGovernance = formData.teamAndGovernance || {};
  if (!teamAndGovernance.teamDiversity) {
    errors.push("Team Diversity is required.");
  }
  if (!teamAndGovernance.governanceStructure) {
    errors.push("Governance Structure is required.");
  }

  // Validate Attachments
  // const attachments = formData.attachments || {};
  // const requiredAttachments = [
  //   { field: "businessPlan", label: "Business Plan" },
  //   { field: "certifications", label: "Certifications" },
  //   { field: "testimonialsOrCaseStudies", label: "Testimonials/Case Studies" },
  //   { field: "impactMetrics", label: "Impact Metrics" },
  // ];
  // requiredAttachments.forEach(({ field, label }) => {
  //   if (!attachments?.[field]?.location) {
  //     errors.push(`Please upload ${label}.`);
  //   }
  // });

  // Validate Payment Details
  const paymentDetails = formData.paymentDetails || {};
  if (!paymentDetails.mode) {
    errors.push("Payment Mode is required.");
  }

  return errors;
};
export const validateForm3Data = (formData) => {
  const errors = {};

  // Validate Organization Profile
  const orgProfile = formData.organizationProfile || {};
  if (!orgProfile.name || orgProfile.name.trim() === "") {
    errors.name = "Organization Name is required.";
  } else if (!/^[A-Za-z0-9&.\- ]+$/.test(orgProfile.name)) {
    errors.name = "Organization Name can only contain letters, numbers, spaces, '&', '.', and '-'.";
  }

  if (!orgProfile.establishedDate) {
    errors.establishedDate = "Established Date is required.";
  }

  if (!orgProfile.foundersAndTeam || orgProfile.foundersAndTeam.trim() === "") {
    errors.foundersAndTeam = "Founders and Team information is required.";
  }
  if (!orgProfile.location || orgProfile.location.trim() === "") {
    errors.location = "Location is required.";
  }
  if (!orgProfile.contactPerson || orgProfile.contactPerson.trim() === "") {
    errors.contactPerson = "Contact Person is required.";
  }
  if (!orgProfile.mobile || orgProfile.mobile.trim() === "") {
    errors.mobile = "Mobile number is required.";
  } else if (!/^\d{10}$/.test(orgProfile.mobile)) {
    errors.mobile = "Mobile number must be a valid 10-digit number.";
  }
  if (!orgProfile.email || orgProfile.email.trim() === "") {
    errors.email = "Email is required.";
  } else if (!/\S+@\S+\.\S+/.test(orgProfile.email)) {
    errors.email = "Email must be a valid email address.";
  }
  if (!orgProfile.website || orgProfile.website.trim() === "") {
    errors.website = "Website is required.";
  }

  // Validate General Overview
  const generalOverview = formData.generalOverview || {};
  if (!generalOverview.description || generalOverview.description.trim() === "") {
    errors.description = "Description is required.";
  } else if (generalOverview.description.trim().split(/\s+/).length > 300) {
    errors.description = "Maximum 300 words are allowed.";
  }

  // Validate Business Model
  const businessModel = formData.businessModel || {};
  if (!businessModel.primaryProductOrService || businessModel.primaryProductOrService.trim() === "") {
    errors.primaryProductOrService = "Primary Product or Service is required.";
  }
  if (!businessModel.uniqueValueProposition || businessModel.uniqueValueProposition.trim() === "") {
    errors.uniqueValueProposition = "Unique Value Proposition is required.";
  }
  if (!businessModel.primaryCustomersOrBeneficiaries || businessModel.primaryCustomersOrBeneficiaries.trim() === "") {
    errors.primaryCustomersOrBeneficiaries = "Primary Customers or Beneficiaries are required.";
  }
  if (!businessModel.revenueModel || businessModel.revenueModel.trim() === "") {
    errors.revenueModel = "Revenue Model is required.";
  }
  if (!businessModel.startupStage || businessModel.startupStage.trim() === "") {
    errors.startupStage = "Startup Stage is required.";
  }

  // Validate Sustainability Impact
  const sustainabilityImpact = formData.sustainabilityImpact || {};
  if (!sustainabilityImpact.positiveImpact) {
    errors.positiveImpact = "Positive Impact is required.";
  }
  if (!sustainabilityImpact.longTermImpact || sustainabilityImpact.longTermImpact.trim() === "") {
    errors.longTermImpact = "Long-Term Impact is required.";
  }

  // Validate Innovation
  const innovation = formData.innovation || {};
  if (!innovation.technologiesOrProcesses || innovation.technologiesOrProcesses.trim() === "") {
    errors.technologiesOrProcesses = "Technologies or Processes are required.";
  }
  if (!innovation.scalability || innovation.scalability.trim() === "") {
    errors.scalability = "Scalability is required.";
  }
  if (!innovation.approach || innovation.approach.trim() === "") {
    errors.approach = "Approach is required.";
  }

  // Validate Market and Financials
  const marketAndFinancials = formData.marketAndFinancials || {};
  if (!marketAndFinancials.targetMarketSize) {
    errors.targetMarketSize = "Target Market Size is required.";
  }
  if (!marketAndFinancials.annualRevenueOrFunding) {
    errors.annualRevenueOrFunding = "Annual Revenue or Funding is required.";
  }
  if (!marketAndFinancials.partnerships) {
    errors.partnerships = "Partnerships information is required.";
  }
  if (!marketAndFinancials.grantsAwardsRecognitions) {
    errors.grantsAwardsRecognitions = "Grants/Awards/Recognitions information is required.";
  }

  // Validate Challenges and Growth
  const challengesAndGrowth = formData.challengesAndGrowth || {};
  if (!challengesAndGrowth.majorChallenges) {
    errors.majorChallenges = "Major Challenges are required.";
  }
  if (!challengesAndGrowth.supportNeeded) {
    errors.supportNeeded = "Support Needed is required.";
  }

  // Validate Team and Governance
  const teamAndGovernance = formData.teamAndGovernance || {};
  if (!teamAndGovernance.teamDiversity) {
    errors.teamDiversity = "Team Diversity is required.";
  }
  if (!teamAndGovernance.governanceStructure) {
    errors.governanceStructure = "Governance Structure is required.";
  }

  // Validate Attachments
  // const attachments = formData.attachments || {};
  // const requiredAttachments = [
  //   { field: "businessPlan", label: "Business Plan" },
  //   { field: "certifications", label: "Certifications" },
  //   { field: "testimonialsOrCaseStudies", label: "Testimonials/Case Studies" },
  //   { field: "impactMetrics", label: "Impact Metrics" },
  // ];
  // requiredAttachments.forEach(({ field, label }) => {
  //   if (!attachments?.[field]?.location) {
  //     errors[`attachment${field}`] = `Please upload ${label}.`;
  //   }
  // });

  // Validate Payment Details
  const paymentDetails = formData.paymentDetails || {};
  if (!paymentDetails.mode) {
    errors.mode = "Payment Mode is required.";
  }

  return errors;
};

// form4
export const validateForm4Data = (formData) => {
  const errors = {};

  // Helper function to check if the input is a valid number
  function isValidNumber(value) {
    return !isNaN(value) && value > 0;
  }

  // Validate Organization Profile
  const orgProfile = formData.organizationProfile || {};
  if (!orgProfile.name) {
    errors.name = "Organization Name is required.";
  } else if (!/^[A-Za-z0-9&.\- ]+$/.test(orgProfile.name)) {
    errors.name = "Organization Name can only contain letters, numbers, spaces, '&', '.', and '-'.";
  }
  if (!orgProfile.corporateOfficeLocation) {
    errors.corporateOfficeLocation = "Corporate Office Location is required.";
  }
  if (!orgProfile.description) {
    errors.description = "Description is required.";
  }
  if (!orgProfile.ownership) {
    errors.ownership = "Ownership is required.";
  }
  if (!orgProfile.yearOfEstablishment) {
    errors.yearOfEstablishment = "Year of Establishment is required.";
  } else if (!/^\d{4}$/.test(orgProfile.yearOfEstablishment)) {
    errors.yearOfEstablishment = "Year of Establishment must be a valid year (e.g., 2023).";
  }
  if (!orgProfile.annualTurnoverFY2023_24) {
    errors.annualTurnoverFY2023_24 = "Annual Turnover FY 2023-24 is required.";
  }
  if (orgProfile.totalEmployees === 0) {
    errors.totalEmployees = "Total Employees is required.";
  }
  if (!orgProfile.reportingSystem) {
    errors.reportingSystem = "Reporting System is required.";
  }
  if (!orgProfile.certifications) {
    errors.certifications = "Certifications are required.";
  }

  // Validate CSR Policy and Practice
  const csrPolicyAndPractice = formData.csrPolicyAndPractice || {};
  if (!csrPolicyAndPractice.salientFeatures) {
    errors.salientFeatures = "Salient Features of CSR Policy are required.";
  }
  if (!csrPolicyAndPractice.csrCommittee) {
    errors.csrCommittee = "CSR Committee details are required.";
  }
  if (!csrPolicyAndPractice.fundsAllocated) {
    errors.fundsAllocated = "Funds Allocated for CSR are required.";
  }
  if (!csrPolicyAndPractice.projectsFY2023_24) {
    errors.projectsFY2023_24 = "CSR Projects FY 2023-24 are required.";
  }
  if (!csrPolicyAndPractice.awarenessPrograms) {
    errors.awarenessPrograms = "Awareness Programs details are required.";
  }
  if (!csrPolicyAndPractice.auditSystem) {
    errors.auditSystem = "Audit System is required.";
  }
  if (!csrPolicyAndPractice.csrReport) {
    errors.csrReport = "CSR Report is required.";
  }
  if (!csrPolicyAndPractice.accolades) {
    errors.accolades = "Accolades information is required.";
  }

  // Validate CSR Project Report
  // const csrProjectReport = formData.attachments?.csrProjectReport || {};
  // if (!csrProjectReport.name) {
  //   errors.csrProjectReportName = "CSR Project Report Name is required.";
  // }
  // if (!csrProjectReport.location) {
  //   errors.csrProjectReportLocation = "CSR Project Report Location is required.";
  // }

  // // Validate Attachments
  // const attachments = formData.attachments || {};
  // if (!attachments.reportingSystemReport?.location) {
  //   errors.reportingSystemReportLocation = "Please upload the Reporting System Report.";
  // }
  // if (attachments.certificates.length === 0) {
  //   errors.certificates = "At least one certificate is required.";
  // }
  // if (!attachments.csrProjectReport?.location) {
  //   errors.csrProjectReportLocation = "Please upload the CSR Project Report.";
  // }

  // Validate Payment Details
  const paymentDetails = formData.paymentDetails || {};
  if (!paymentDetails.mode) {
    errors.mode = "Payment Mode is required.";
  }
  // if (!paymentDetails.amount || !isValidNumber(paymentDetails.amount)) {
  //   errors.paymentAmount = "Amount is required and must be a positive number.";
  // }
  // if (!paymentDetails.amountWithGst || !isValidNumber(paymentDetails.amountWithGst)) {
  //   errors.paymentAmountWithGst = "Amount with GST is required and must be a positive number.";
  // }
  // if (!paymentDetails.transactionId) {
  //   errors.transactionId = "Transaction ID is required.";
  // }
  // if (!paymentDetails.muid) {
  //   errors.muid = "MUID is required.";
  // }
  // if (!paymentDetails.receipt?.location) {
  //   errors.paymentReceipt = "Please upload a payment receipt.";
  // }

  return errors;
};




