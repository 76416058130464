import React, { useState } from "react";

const InputTable = ({ formData, handleChange }) => {

    return (
        <div
            className="border border-[#771D32]/40 w-full md:full text-gray-700 text-[10px] md:text-xs
             rounded-lg  placeholder:text-xs  outline-none p-6 "
        >
            <table
                className="w-full"
            >
                <thead>
                    <tr className="border-b border-[#771D32]/40">
                        <th className="text-[#777777] text-[12px] md:text-[13px] font-medium text-left">Financial Year</th>
                        <th className="text-[#777777] text-[12px] md:text-[13px] font-medium text-left">Amount Spent</th>
                    </tr>
                </thead>
                <tbody >
                    {Object.keys(formData).map((key) => (
                        <tr key={key}
                            className="border-b border-[#771D32]/40 w-full md:full p-2 md:p-2 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
                        >
                            <td className="text-[#777777] text-[12px] md:text-[13px] font-medium uppercase ">
                                {key?.split('_')?.join('-')}
                            </td>
                            <td className="text-[#777777] text-[12px] md:text-[13px] font-medium">
                                <input
                                    type="text"
                                    name={key}
                                    value={formData[key]}
                                    onChange={handleChange}
                                    className="border border-[#771D32]/40 w-full md:full p-2 md:p-2 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

        </div>

    );
};

export default InputTable;
