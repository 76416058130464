// import Attachment from "./Attachment";
import image from "../../assets/1.png";
import ProjectDetailView from "./ProjectDetailView";
import ReadInputTable from "./ReadInputTable";

const Ngo = (props) => {
  const formData = props?.data;

  let attachedData = [];
  if (
    typeof formData?.attachments === "object" &&
    formData.attachments !== null
  ) {
    const attachmentKeys = Object.keys(formData.attachments);

    attachmentKeys.forEach((key) => {
      const attachment = formData.attachments[key];
      if (Array.isArray(attachment)) {
        attachedData.push(...attachment);
      } else {
        attachedData.push(attachment);
      }
    });
  }

  if (formData?.paymentDetails?.receipt) {
    attachedData.push(formData?.paymentDetails?.receipt)
  }

  return (
    <div className="w-full  border rounded p-5">
      <h1 className="font-semibold w-full  text-center mt-5">
        <span className="font-sherif tracking-wide text-xl text-textColor">
          Application Name: Application for KMA Impactful NGO Awards 2024
        </span>
      </h1>

      <div className="flex items-start justify-center border rounded-xl shadow-xl w-full max-w-[1280px] mx-auto">
        <form
          className="w-full flex flex-col items-center justify-center md:px-20 p-5 md:p-10"
          action=""
        >

          {/* Division heading */}
          <div className="text-start w-full font-semibold mb-2">
            <h2>Organization Profile</h2>
          </div>
          {/* Organization /adress */}
          <div className="w-full md:mt-3  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Name of the Applicant Organization * :
              </label>
              <input
                type="text"
                name="name"
                required
                value={formData?.organizationProfile?.name}
                readOnly
                placeholder="Eg: Qmark Technolabs"
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Year of Establishment* :
              </label>
              <input
                type="text"
                name="yearOfEstablishment"
                value={formData?.organizationProfile?.yearOfEstablishment}
                required
                placeholder=""
                readOnly
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          {/* contact & Email*/}
          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Legal Status of the Organization * :
              </label>
              <input
                type="text"
                name="legalStatus"
                value={formData?.organizationProfile?.legalStatus}
                required
                readOnly
                placeholder="Eg: Society, Trust, Section 8 Company"
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Governing Body Details* :
              </label>
              <input
                type="text"
                name="governingBodyDetails"
                // pattern="[0-9]*"
                // inputMode="numeric"
                placeholder=""
                required
                value={formData?.organizationProfile?.governingBodyDetails}
                readOnly
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          {/*Governing Body Details*/}
          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Thematic Areas of Intervention  :
              </label>
              <input
                type="text"
                name="thematicAreas"
                value={formData?.organizationProfile?.thematicAreas}
                readOnly
                placeholder="Eg: Education, Health, Skills Development, Women Empowerment"
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Active Geographies* :
              </label>
              <input
                type="text"
                name="activeGeographies"
                value={formData?.organizationProfile?.activeGeographies}
                required
                readOnly
                placeholder="States and Districts"
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>

          {/* Brief Description of the Organization */}
          <div className="w-full  flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Brief Description of the Organization * :
              </label>
              <textarea
                value={formData?.organizationProfile?.description}
                name="description"
                id=""
                readOnly
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none">
              </textarea>
            </div>
          </div>

          {
            formData?.projectDetails?.map((item, index) => (
              <ProjectDetailView index={index} item={item} />
            ))
          }

          {/* Division heading */}
          <div className="text-start w-full font-semibold my-5">
            <h2>Financial and Payment Details</h2>
          </div>

          {/*payment option */}
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                is KMA Member?*
              </label>
              <select
                id=""
                name="isKmaMember"
                required
                value={formData?.isKmaMember}
                readOnly
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none
                   focus:outline-none focus:shadow-outline font-medium"
              >
                <option
                  value=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Select a category
                </option>
                <option
                  value={true}
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  KMA Member
                </option>
                <option
                  value={false}
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Non-KMA Member
                </option>
              </select>
            </div>
            <div
              className={`w-full space-y-2 ${formData?.isKmaMember ? "block" : "hidden"
                }`}
            >
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Membership Id*
              </label>
              <input
                type="text"
                name="membershipId"
                required
                readOnly
                placeholder=""
                value={formData?.membershipId}
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
          </div>
          <div className="w-full flex flex-col md:flex-row gap-2 py-1">
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Application Amount (with GST) :
              </label>
              <input
                type="text"
                name="amountWithGst"
                // required
                readOnly
                value={formData?.paymentDetails?.amountWithGst}
                placeholder=""
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none"
              />
            </div>
            <div className="w-full space-y-2">
              <label
                htmlFor=""
                className="text-[#777777] text-[12px] md:text-[13px] font-medium"
              >
                Payment Details :*
              </label>
              <select
                id=""
                name="mode"
                required
                value={formData?.paymentDetails?.mode}
                readOnly
                className="border border-[#771D32]/40 w-full md:full p-2 md:p-3 text-gray-700 text-[10px] md:text-xs rounded  placeholder:text-xs ps-2 outline-none
                   focus:outline-none focus:shadow-outline font-medium"
              >
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Select a category
                </option>
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Cheque
                </option>
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  DD No.
                </option>
                <option
                  name=""
                  className="text-xs ring-0 outline-none focus:outline-none"
                >
                  Online
                </option>
              </select>
            </div>


          </div>

        </form>
      </div>

      <div className="flex w-full">
        <div className="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 mt-10 gap-10 md:px-10">
          {attachedData?.map((attachment, index) => (
            <div className="w-full" key={index}>
              <h1 className="mb-3 text-lg text-center font-semibold font-sherif tracking-wider">
                Attachment {index + 1} 
              </h1>
              <div className="overflow-hidden w-full">
                <img
                  src={
                    attachment.key.endsWith(".jpg") ||
                      attachment.key.endsWith(".JPG") ||
                      attachment.key.endsWith(".jpeg") ||
                      attachment.key.endsWith(".JPEG") ||
                      attachment.key.endsWith(".avif") ||
                      attachment.key.endsWith(".png")
                      ? attachment.location
                      : image
                  }
                  alt={attachment?.key}
                  className="object-contain md:h-[250px]"
                />
              </div>
              <a
                href={attachment.location}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="border w-full bg-red-600 text-white text-sm p-1">
                  Download
                </button>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Ngo;
